<template>
  <div>
    <div class="ma-5">
      <v-card tile elevation="4">
        <!-- Card con el filtro de búsqueda y el botón de alta -->
        <v-card-title>
          <v-text-field
              v-model="search"
              class="mr-6"
              append-icon="mdi-magnify"
              :label="$t('lFilter')"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
              right
              hide-details
              color="primary"
              @click="addItem()"
          >{{ $t('bAdd') }}
          </v-btn>
        </v-card-title>
        <!-- Tabla -->
        <v-data-table
            :headers="headers"
            :items="homes"
            :loading="this.homes.length === 0 && (!finished)"
            :loading-text="$t('lLoading')"
            sort-by="id"
            :search="search"
            :footer-props="footerProps"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="getHomesPage()"
        >
        <!--         -->
        <!-- Carga de dialog para alta y edición, con texto título y botón personalizado según acción de carga -->
        <template v-slot:top>
          <v-dialog v-model="dialogSave" persistent max-width="3000px">
            <v-form ref="form">
              <v-card tile>
                <v-card-title>
                  <span class="headline">{{ itemDialogTitle }}</span></v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" lg="3" md="3" >
                        <p>{{$t('lName')}}:</p>
                        <v-text-field
                            v-model.trim="editedItem.homeName"
                            type="text"
                            :rules="[rules.maxLength50, rules.required]"
                            validate-on-blur
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" lg="3" md="3" class="justify-content-center">
                        <p>{{$t('lAdress')}}:</p>
                        <v-text-field
                            v-model.trim="editedItem.address"
                            type="text"
                            :rules="[rules.maxLength300]"
                            validate-on-blur
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" lg="3" md="3" >
                        <p>{{$t('lEnable')}}:</p>
                        <v-checkbox
                            v-model="editedItem.enable"
                            :label="editedItem.enable|formatBoolean"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" lg="3" md="3" >
                        <p>{{$t('lLocality')}}:</p>
                        <v-text-field
                            v-model.trim="editedItem.locality"
                            type="text"
                            :rules="[rules.maxLength100]"
                            validate-on-blur
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" lg="3" md="3" >
                        <p>{{$t('lProvince')}}:</p>
                        <v-text-field
                            v-model.trim="editedItem.province"
                            type="text"
                            :rules="[rules.maxLength100]"
                            validate-on-blur
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" lg="3" md="3" >
                        <p>{{$t('lPostalCode')}}:</p>
                        <v-text-field
                            v-model.trim="editedItem.postalCode"
                            type="text"
                            :rules="[rules.maxLength6]"
                            validate-on-blur
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" lg="3" md="3" >
                        <p>{{$t('lTelephone')}}:</p>
                        <v-text-field
                            v-model.trim="editedItem.telephone"
                            type="text"
                            :rules="[rules.maxLength12,rules.numericPlus]"
                            validate-on-blur
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="close">{{ $t('bCancel') }}</v-btn>
                  <v-btn color="primary" @click="saveItemConfirm">{{ $t('bSave') }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-form>

            <!-- Overlay and loading in save -->
            <v-overlay v-show="overlay" opacity="0.75">
              <v-container fluid fill-height >
                <v-layout justify-center align-center>
                  <v-progress-circular
                      indeterminate color="primary">
                  </v-progress-circular>
                </v-layout>
              </v-container>
            </v-overlay>

          </v-dialog>
        </template>
        <!--          -->
        <!-- Dialogo para listar los usuarios asignados a los Hogares-->
          <template v-slot:body.append>
          <UserList :dialogListUsers.sync="dialogListUsers"
                    :selectedHome.sync="editedItem.idHome"
                    @save="acceptUserList"
                    class="elevation-2" />
          </template>
        <!--          -->
        <!-- Personalización de columna de acciones con los botones de edición y eliminar -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="seeUserList(item)"
                >
                  mdi-account-group-outline
                </v-icon>
              </template>
              <span>{{ $t('ttSeeUsers') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="secondary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('ttModify') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-if="item.enable"
                    color="error"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="activateDeactivateConfirmDialog(item)">
                  mdi-toggle-switch-off
                </v-icon>
                <v-icon
                    v-else
                    color="error"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="activateDeactivateConfirmDialog(item)">
                  mdi-toggle-switch
                </v-icon>
              </template>
              <span>{{ $t('ttActivateDeactivate') }}</span>
            </v-tooltip>
          </template>
          <!--          -->
          <!-- Personalizacion de campo enable -->
          <template v-slot:[`item.enable`]="{ item }">
            <span>
              {{ item.enable | formatBoolean }}
              <br>
            </span>
          </template>
          <!--          -->
          <!-- Personalizacion del mensaje de no hay datos disponibles -->
          <template slot="no-data">
            <v-alert :value="true" color="" icon="warning">
              No existen datos disponibles
            </v-alert>
          </template>
          <!-- footer de la tabla-->
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} {{$t('lFooterOf')}} {{props.itemsLength}}
          </template>
          <!--          -->
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { backendService } from '@/services/backend.service'
import {i18n} from "@/plugins/i18n";
import validations from "@/mixins/validations";
import UserList from "@/components/UsersList";
import logger from '@/mixins/logger'
import {manageErrors} from "@/mixins/errorAlertManagement";

export default {
  name:'HomesManagment',
  components: {
    UserList,
  },
  data () {
    return {
      overlay:false,
      options: {
        page:1,
        itemsPerPage:20,
        totalItems:0,
        sortBy:['idHome'],
        sortDesc:[false],
        sorting: 'idHome' + ',' + 'asc'
      },
      finished: true,
      homes: [],
      dialogListUsers: false,
      selectedHome:'',
      search: '',
      awaitingSearch:false,
      footerProps: {
        'items-per-page-text': this.$t('lRowsPerPage'),
        'items-per-page-all-text':this.$t('lAll'),
        'items-per-page-options': [5, 10, 20,-1]
      },
      headers: [
        { text: this.$t('lCode'), value: 'idHome', align: 'center', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lName'), value: 'homeName', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lAdress'), value: 'address', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lLocality'), value: 'locality', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lProvince'), value: 'province', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lPostalCode'), value: 'postalCode', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lTelephone'), value: 'telephone', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lEnable'), value: 'enable', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lActions'), value: 'actions', align: 'center', sortable: false, filterable: false, width: '20%' }
      ],
      rules: validations,
      editedIndex: -1,
      dialogSave : false,
      editedItem:{
        idHome:-1,
        homeName:'',
        address:'',
        coordinates:'',
        locality:'',
        province:'',
        postalCode:null,
        telephone:null,
        enable:false
      },
      defaultItem: {
        idHome:-1,
        homeName:'',
        address:'',
        coordinates:'',
        locality:'',
        province:'',
        postalCode:null,
        telephone:null,
        enable:false
      },
    }
  },
  methods: {
    init () {
      // Obtener la lista de homes con paginacion (back)
      this.getHomesPage()
    },
    editItem(item) {
      this.editedIndex = this.homes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogSave = true
    },
    seeUserList(item){
      this.editedIndex = this.homes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogListUsers=true;
    },
    validate() {
      return this.$refs.form.validate()
    },
    clearValidations() {
      if (this.$refs.form) {
        return this.$refs.form.reset()
      }
    },
    close() {
      this.overlay= false;
      this.dialogSave = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.clearValidations()
    },
    activateDeactivateConfirmDialog(item){
      let title=''
      let text=''
      if(item.enable){
        title='Desactivar Hogar'
        text='Este proceso desactivará también los usuarios asociados a este hogar. ¿Esta seguro que desea desactivar este Hogar y sus Usuarios? '
      }
      else{
        title='Activar Hogar'
        text='¿Esta seguro que desea activar este hogar?'
      }
      this.$dialog.confirm({
        title: title,
        text: text ,
        persistent: true,
        actions: {
          false: 'No',
          true: {
            color: 'black',
            text: 'Si',
            handle: () => {
              this.activateDeactivateItem(item)
            }
          }
        }
      })
    },
    activateDeactivateItem(item) {
      this.editedIndex = this.homes.indexOf(item)
      this.editedItem = Object.assign({}, this.homes[this.editedIndex])
      // Peticion de desactivar
      if(item.enable){
        backendService.deactivateHome(item.idHome)
            .then((response) => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              this.clearValidations()
              this.init()
            })
            .catch((error) => {
              manageErrors(this,error).finally(() => {
                this.close()
                this.init()
              })
            })
      }
      // Peticion de activar
      else{
        backendService.activateHome(item.idHome)
            .then((response) => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              this.clearValidations()
              this.init()
            })
            .catch((error) => {
              manageErrors(this,error).finally(() => {
                this.close()
                this.init()
              })
            })
      }
    },
    // Crear o editar
    saveItemConfirm() {
      if (this.validate()) {
        this.overlay= true;

        if (this.editedItem.idHome === -1) { // Crear
          this.saveHome()
        }
        else{ // Editar
          this.updateHome()
        }
      }
    },
    saveHome(){
      backendService.addHome(this.editedItem)
      .then((response) => {
        logger.log('Añadido Con exito')
        this.init()
        this.close()
      })
      .catch((error) => {
        manageErrors(this,error).finally(() => {
          this.overlay= false;
        })
     })
    },
    updateHome(){
      backendService.updateHome(this.editedItem)
          .then((response) => {
            this.init()
            this.close()
            logger.log('Actualizado Con exito')
          })
          .catch((error) => {
            manageErrors(this,error).finally(() => {
              this.overlay= false;
            })
          })
    },
    addItem() {
      this.editItem(this.editedItem)
    },
    getHomesPage(){
      this.homes=[]
      this.finished=false
      this.sorting () // Info de ordenar columnas
      backendService.getHomesListPagination(this.search,this.options, false)
          .then((response) => {

            // Gestionamos los elementos totales
            let count = parseInt(response.headers["x-total-count"])
            this.totalItems= count

            // Obtener Informacion
            this.homes = response.data
            this.finished = true
          }).catch((error) => {
        logger.log(error)
        this.finished=true
      })
    },
    acceptUserList() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.dialogListUsers = false
    },
    sorting () { // Ordenar columnas para la paginacion y busqueda en back
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        this.options.sorting = this.options.sortBy[0] + ',' + (this.options.sortDesc[0] ? 'desc' : 'asc')
      } else {
        this.options.sorting = 'idHome' + ',' + 'asc'
      }
    }
  },
  computed: {
    itemDialogTitle () {
      return this.editedIndex === -1 ? this.$t('lNewHomes') : this.$t('lEditHomes')
    }
  },
  mounted () {
    this.init()
  },
  filters: {
    formatBoolean (boolean) {
      switch (boolean) {
        case true:
          return i18n.t('lYes')
        case false:
          return i18n.t('lNo')
        default:
          return i18n.t('lNo')
      }
    }
  },
  watch: {
    search:{ // Paginacion
      handler () {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getHomesPage()
            this.awaitingSearch = false;
          }, 1750); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    }
  },
}
</script>
