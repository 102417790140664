import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_TOKEN,
  REFRESH_TOKEN
} from '@/store/actions/auth'
import { authService } from '@/services/auth.service'
import router from '@/router'
import store from "@/store";
import logger from '@/mixins/logger'

/**
 * States for auth
 */
const state = {
  token: sessionStorage.getItem('token'),
  username: sessionStorage.getItem('token'),
  loginError: null,
}

/**
 * Getters for auth
 */
const getters = {
  token: state => state.token,
  username: state => state.username,
  loginError: state => state.loginError,
}

/**
 * Actions for auth
 */
const actions = {
  /**
   * Executes the login of an user
   */
  [AUTH_REQUEST] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit(AUTH_ERROR, null)
      authService.authenticate(data.username, data.password, data.tenant)
        .then(response => {
          sessionStorage.setItem('token', response.headers.authorization)
          // commit the success auth
          commit(AUTH_SUCCESS, response.data)
          resolve(response)
        })
        .catch(error => {
          commit(AUTH_ERROR, error.response.data)
          sessionStorage.removeItem('token')
          reject(error)
        })
    })
  },

  /**
   * Executes the logout action
   */
  [AUTH_LOGOUT] ({ dispatch, getters }, expired) {
    // invoke the user service to execute the logout
    authService.logout()
      .then(() => {
        // clear the tokens
        dispatch(CLEAR_TOKEN)

        // Logout XMPP
        store.dispatch('xmpp/XMPP_LOGOUT', {
        }).then(() => {
          logger.log("log out")
        }).catch(err => {
          logger.log(err)
        })
        // and move to the login view, marking the expired or not (the double !! is to force to be a boolean)
        router.push({ name: 'Login', params: { tenant : store.getters['backend/myself'].tenant.tenantName}}).then(() => {
          window.sessionStorage.clear()
          store.reset()
        })      
      })
      .catch(error => {
        logger.error('An error occurs during logout', error)
      })
  },

  /**
   * Removes the access token
   */
  [CLEAR_TOKEN] ({ commit }) {
    // remove the item from the sessionStorage
    sessionStorage.removeItem('token')
  },

  /**
   * Cleans the login errors
   */
  [CLEAR_ERRORS] ({ commit }) {
    commit(AUTH_ERROR, null)
  },
}

const mutations = {
  // updates the loginError state
  [AUTH_ERROR]: (state, errorType) => {
    state.loginError = errorType
  },
  // updates the token state
  [AUTH_SUCCESS]: (state, data) => {
    state.token = sessionStorage.getItem('token')
    state.username = data.username
  },
  // refreshes the token with a new one
  [REFRESH_TOKEN]: (state, token) => {
    // update the token stored in the state
    state.token = token
    // take the token from the local storage
    const tokenData = JSON.parse(sessionStorage.getItem('token') || '{}')
    // update the token in the local storage
    tokenData.token = token
    sessionStorage.setItem('token', JSON.stringify(tokenData))
  },
  // removes the token and the username states
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    state.username = ''
  }
}

export default {
  namespaced: true,   
  state,
  getters,
  actions,
  mutations
}
