import { 
    MAKE_CALL,
    CALL_ATTEMPT,
    CANCEL_CALL,
    TIMEOUT_CALL,
    CALL_CANCELLED,
    REJECT_CALL,
    CALL_REJECTED,
    END_CALL,
    CALL_ENDED,
    REMOTE_CALL_ACCEPTED,
    REMOTE_CALL_ENDED,
    CALL_ACCEPTED,    
    HANDLE_MESSAGE,
    HANDLE_CARBON,
    SEND_CURRENT_CALL,
    ENDING_CURRENT_CALL,
    RESET_CURRENT_CALL,
    ACCEPT_CALL,
    SET_ACTIVE_CALL,
    SET_USER_STATUS,
    SET_CARDS_DISABLED,
    REMOTE_CALL_REJECTED,
    REMOTE_INVITE,
    REMOTE_CANCEL,
    CONNECTION_CLOSED
 } from '@/store/actions/videocall'
import { videocallService } from '@/services/videocall.service'
import logger from '@/mixins/logger'


const state = {
    initialize: false,
    currentStateMachine: 'idle',
    currentCall: null,
    activeCall: false,
    cardsDisabled: false,
    userStatus: 'available',
    machine: {
        name: 'Signaling',
        config: {
            id: 'signalling',
            initial: 'idle',
            strict: true,
            states: {
                idle: {
                    on: {
                        MAKE_CALL: {
                            target: 'connecting',
                            actions: ['MAKE_CALL']
                        },
                        CALL_ATTEMPT: {
                            target: 'ringing',
                            actions: ['CALL_ATTEMPT']
                        },
                        REMOTE_CALL_ACCEPTED: {
                            target: 'pasive',
                            actions: ['REMOTE_CALL_ACCEPTED']
                        },
                        REMOTE_INVITE: {
                            target: 'pasive',
                            actions: ['REMOTE_INVITE']
                        }
                    }
                },
                pasive: {
                    on: {
                        REMOTE_CALL_ENDED: {
                            target:'idle',
                            actions: ['REMOTE_CALL_ENDED']
                        },
                        REMOTE_CANCEL: {
                            target:'idle',
                            actions: ['REMOTE_CANCEL']
                        },
                        CONNECTION_CLOSED: {
                            target:'idle',
                            actions: ['CONNECTION_CLOSED']
                        },
                        REMOTE_CALL_REJECTED: {
                            target: 'idle',
                            actions: ['REMOTE_CALL_REJECTED']
                        },
                    }
                },
                connecting: {
                    on: {
                        CALL_ACCEPTED: {
                            target: 'conversation',
                            actions: ['CALL_ACCEPTED']
                        },
                        CALL_REJECTED: {
                            target: 'idle',
                            actions: ['CALL_REJECTED']
                        },
                        CANCEL_CALL: {
                            target: 'idle',
                            actions: ['CANCEL_CALL']
                        },
                        TIMEOUT_CALL: {
                            target: 'idle',
                            actions: ['TIMEOUT_CALL']
                        },
                        CONNECTION_CLOSED: {
                            target:'idle',
                            actions: ['CONNECTION_CLOSED']
                        }
                    }
                },
                ringing: {
                    on: {
                        REJECT_CALL: {
                            target:'idle',
                            actions: ['REJECT_CALL']
                        },
                        CALL_CANCELLED: {
                            target: 'idle',
                            actions: ['CALL_CANCELLED']
                        },
                        ACCEPT_CALL: {
                            target: 'conversation',
                            actions: ['ACCEPT_CALL']
                        },
                        REMOTE_CALL_ACCEPTED: {
                            target: 'pasive',
                            actions: ['REMOTE_CALL_ACCEPTED']
                        },
                        REMOTE_CALL_REJECTED: {
                            target: 'idle',
                            actions: ['REMOTE_CALL_REJECTED']
                        },
                        CONNECTION_CLOSED: {
                            target:'idle',
                            actions: ['CONNECTION_CLOSED']
                        }
                    }
                },
                conversation: {
                    on: {
                        CALL_ENDED: {
                            target: 'idle',
                            actions: ['CALL_ENDED']
                        },
                        END_CALL: {
                            target: 'idle',
                            actions: ['END_CALL']
                        },
                        CONNECTION_CLOSED: {
                            target:'idle',
                            actions: ['CONNECTION_CLOSED']
                        }
                    }
                }
            }
        }
    }
} 

const getters = {
    getMachine: state => state.machine,
    getCurrentState: state => state.currentStateMachine,
    getInitialize:state => state.initialize,
    getCurrentCall: state => state.currentCall,
    getActiveCall: state => state.activeCall,
    getCardsDisabled: state => state.cardsDisabled,
    getUserStatus: state => state.userStatus,
}

const mutations = {
    initMachine(state){
        videocallService.initMachine()
    },

    setCurrentState(state, data) {
        state.currentStateMachine = data
    },

    transition(state,data) {
        videocallService.transition(data)
    },
    handleSignal(state,data) {

    },
    
    [SEND_CURRENT_CALL]: (state, data) => {
        let call = {
            jid: data.jid,
            roomId: data.roomId,
            initTime: new Date().toLocaleString('es-ES'),
            endTime: null
        }
        state.currentCall = call
    },

    [ENDING_CURRENT_CALL]: (state, data) => {
        let call = new Date().toLocaleString('es-ES')
        state.currentCall.endTime = call
    },
    
    [HANDLE_MESSAGE]: (state, data) => {
        videocallService.handleMessage(data)
    },

    [HANDLE_CARBON]: (state,data) => {
        videocallService.handleCarbon(data)
    },

    setInitialize(state,data) {
        state.initialize = data
    },

    [RESET_CURRENT_CALL]: (state, data) => {
        state.currentCall =  null
    },

    [SET_ACTIVE_CALL]: (state, data) => {
        state.activeCall = data.activeCall
    },

    [SET_USER_STATUS]: (state, data) => {
        state.userStatus = data.userStatus
    },

    [SET_CARDS_DISABLED]: (state, data) => {
         logger.log('TRAZA', data)
        state.cardsDisabled = data.cardsDisabled
    }
}

const actions = {
    [MAKE_CALL] ({commit,state}, data) {
        videocallService.makeCall(data)
    },

    [CALL_ATTEMPT] ({commit,state}, data) {
        videocallService.callAttempt(data)
    },
    
    [REMOTE_CALL_ACCEPTED] ({commit,state}, data) {
        videocallService.remoteCallAccepted()
    },
    
    [REMOTE_CALL_ENDED] ({commit,state}, data) {
        videocallService.remoteCallEnded()
    },
    
    [CALL_REJECTED] ({commit,state}, data) {
        videocallService.callRejected(data)
    },
    
    [REJECT_CALL] ({commit,state}, data) {
        videocallService.rejectCall()
    },

    [CANCEL_CALL] ({commit,state}, data) {
        videocallService.cancelCall()
    },
    
    [END_CALL] ({commit,state}, data) {
        videocallService.endCall()
    },

    [CALL_ENDED] ({commit,state}, data) {
        videocallService.callEnded()
    },

    [CALL_CANCELLED] ({commit, state}, data) {
        videocallService.callCancelled()
    },

    [CALL_ACCEPTED] ({commit,state}, data) {
        logger.log('CALL_ACCEPT')
        videocallService.callAccepted()
    },

    [ACCEPT_CALL] ({commit,state}, data) {
        videocallService.acceptCall()
    },

    [REMOTE_CALL_REJECTED] ({commit,state}, data) {
        videocallService.remoteCallRejected()
    },

    [CONNECTION_CLOSED] ({commit,state}, data) {
        videocallService.connectionClosed(data)
    },

    [TIMEOUT_CALL] ({commit,state}, data) {
        videocallService.timeoutCall()
    },

    [REMOTE_INVITE] ({commit,state}, data) {
        videocallService.remoteInvite()
    },

    [REMOTE_CANCEL] ({commit,state}, data) {
        videocallService.remoteCancel()
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}