<template>
  <v-app style="background:#063442">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created () {
    document.title = process.env.VUE_APP_TITLE;
  },
  data: () => ({
  })
}
</script>
