import { AXIOS } from '@/plugins/axios'
import { API_URL } from '@/plugins/axios'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export const backendService = {
    getMySelf,
    getJWTToken,
    getUserTypesList,
    getHomesList,
    getUserList,
    getUserListAdmin,
    sendNotification,
    getNotificationList,
    getRolesList,
    getUserListAdminPagination,
    getHomesListPagination,
    addUser,
    updateUser,
    getUserTypesListWithID,
    deleteUser,
    activateUser,
    addHome,
    updateHome,
    activateHome,
    deactivateHome,
    getUserListByHomeId,
    sendEmailPassword,
    sendEmailResetPassword,
    permanentDeleteUser,
    thingsboardLogin,
    getThingsboardDashboards,
    registerCall,
    registerXmppStatus
}

function getMySelf () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/userInfo',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getJWTToken () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/videocall/generateJWTToken',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },    
    })
}

function getUserTypesList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/userInfo/getUserTypesList',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getUserList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getUserListAdmin () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/list/admin',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getHomesList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/homes/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function sendNotification (jabberIdTo,title,body,roomId,priority,saveNotification) {
    let dataMessage ={
        'jabberIdTo':jabberIdTo,
        'title':title,
        'body':body,
        'roomId': roomId,
        'priority':priority,
        'saveNotification':saveNotification
    }
    return AXIOS({
        method: 'post',
        data: dataMessage,
        url: API_URL + '/api/v1/notification/notification',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getNotificationList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/notification/own/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getUserListAdminPagination (search,pagination,selectedFilterUserType,activeUsers) {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/list/admin/Page',
        params: {page:(pagination.page-1),size:pagination.itemsPerPage,sort:pagination.sorting,userTextFilter:search, userType:selectedFilterUserType, activeUsers:activeUsers},
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getHomesListPagination (search,pagination,activeHomes) {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/homes/list/page',
        params: {page:(pagination.page-1),size:pagination.itemsPerPage,sort:pagination.sorting,homeTextFilter:search, activeHomes:activeHomes},
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getRolesList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/roles/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}
function getUserTypesListWithID () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/users/userInfo/getUserTypesListWithID',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

// Create, update and edit User
function addUser (user, backendUserVcard) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    let SaveBackendUserDTO = {
     backendUser:user,
     backendUserVcard:backendUserVcard,
     adminPass:pass
    }
    return AXIOS({
        method: 'post',
        url: API_URL +'/api/v1/users/create/',
        data: SaveBackendUserDTO,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}


function updateUser (user,backendUserVcard) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    let SaveBackendUserDTO = {
        backendUser:user,
        backendUserVcard:backendUserVcard,
        adminPass:pass
    }
    return AXIOS({
        method: 'put',
        url: API_URL +'/api/v1/users/update',
        data: SaveBackendUserDTO,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}


function deleteUser (username) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    return AXIOS({
        method: 'delete',
        url: API_URL +'/api/v1/users/deactivate',
        params: {username:username, adminPass:pass},
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}


function permanentDeleteUser (username) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    return AXIOS({
        method: 'delete',
        url: API_URL +'/api/v1/users/deletePermanent',
        params: {username:username,adminPass:pass },
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

function activateUser (username) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    return AXIOS({
        method: 'put',
        url: API_URL +'/api/v1/users/activate',
        params: {username:username,adminPass:pass},
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}



//////////////////////////////////////

// Create, update and edit homes
function addHome (home) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    let SaveHomeDTO = {
        home:home,
        adminPass:pass
    }
    return AXIOS({
        method: 'post',
        url: API_URL +'/api/v1/homes/create',
        data: SaveHomeDTO,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}


function updateHome (home) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    let SaveHomeDTO = {
        home:home,
        adminPass:pass
    }
    return AXIOS({
        method: 'put',
        url: API_URL +'/api/v1/homes/update',
        data: SaveHomeDTO,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}


function deactivateHome (idHome) {
    let pass= store.getters['xmpp/getXmppCredentials'].password
    if (pass === null || pass === "") pass=null
    return AXIOS({
        method: 'delete',
        url: API_URL +'/api/v1/homes/deactivate',
        params: {idHome:idHome, adminPass:pass },
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

function activateHome (idHome) {
    return AXIOS({
        method: 'put',
        url: API_URL +'/api/v1/homes/activate',
        params: {idHome:idHome},
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

function getUserListByHomeId (idHome) {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/homes/users/list',
        params: {idHome:idHome},
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

///////////////////////////////////////////////////////////////////////////////

function sendEmailPassword (data) {
    return AXIOS({
        method: 'post',
        url: API_URL +'/api/v1/email/sendpassword',
        data: data,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

function sendEmailResetPassword (data) {
    return AXIOS({
        method: 'post',
        url: API_URL +'/api/v1/email/resetpassword',
        data: data,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

///////////////////////////////////////////////////////////////////////////////
function thingsboardLogin (data) {
    return AXIOS({
        method: 'post',
        url: API_URL +'/api/v1/iot/login',
        data: data,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}
function getThingsboardDashboards (jwt,customerUserId,pageSize,page,textSearch) {
    let body ={
        jwt:jwt,
        customerUserId:customerUserId,
        pageSize:pageSize,
        page:page,
        textSearch:textSearch
    }
    return AXIOS({
        method: 'post',
        url: API_URL +'/api/v1/iot/getUsersDashboards',
        data: body,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

///////////////////////////////////////////////////////////////////////////////
function registerCall (data) {
    console.log(data)
    let dataMessage = {
        'roomId': data.roomId,
        'action': data.action
    }
    console.log(dataMessage)
    return AXIOS({
        method: 'post',
        url: API_URL + '/api/v1/videocall/registerCall',
        data: dataMessage,
                headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

function registerXmppStatus (data) {
    let dataMessage = {
        'userStatus': i18n.t(data)
    }
    console.log(dataMessage)
    return AXIOS({
        method: 'post',
        url: API_URL + '/api/v1/videocall/xmppStatus',
        data: dataMessage,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}