<template>
  <v-main class="lighten-3" style="background:#063442">
    <v-container fluid>
      <!-- Filtros -->
      <v-row>
        <v-col cols="12" lg="3" md="3" sm="12">
          <p style="color:white">{{$t('lHomeFilterLabel')}}:</p>
          <v-combobox
            :label="$t('lHomeFilterLabel')"
            v-model="selectedHome"
            :items="homeItemList"
            background-color="white"
            solo
          >
          </v-combobox>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="12">
          <p style="color:white">{{$t('lUserTypeFilterLabel')}}:</p>
          <v-combobox
            :label="$t('lUserTypeFilterLabel')"
            v-model="selectedUserType"
            :items="userTypeItemList"
            background-color="white"
            solo
          >
          </v-combobox>
        </v-col>
      </v-row>
      <!-- Lista de contactos y videollamada -->
      <v-row class="mr-1">
        <v-col v-if="filterContacts.length===0" class="pr-2" cols="3">
          <p v-if="!this.hasJWT" v-show="filterContacts.length===0" class="sticky" style="color:white; text-align: center">{{$t('eNotJWT')}}</p>
          <p v-else v-show="filterContacts.length===0" class="sticky" style="color:white; text-align: center">{{$t('eNotFilteredResults')}}</p>
        </v-col>
        <!-- Lista de contactos-->
        <v-col class="pr-2" width="460px" height="180px" max-width="460px" max-height="180px"  cols="auto">
          <v-sheet
            rounded="lg"
            style="background:#063442;"
            class="overflow-y-auto"
            max-height="70vh"
            v-show="filterContacts.length!==0"
          >
            <!-- -->
            <v-col v-for="(contact, i) in sortedContacts(filterContacts)" :key="i" cols="auto" width="460px" height="180px" max-width="460px" max-height="180px">
              <v-row style=" border-radius: 0.3em; padding-top: 2px; padding-bottom: 2px;">
                <v-card class="event-card flexcard" @click="sendCall(contact)" style="margin-right: 7px" width="460px" height="180px" max-width="460px" max-height="180px" :disabled="cardsDisabled">
                  <v-row>
                    <v-col cols="4" lg="4" md="4" sm="4" class="ma-0 pa-0">
                        <v-avatar
                            tile
                            height="100%"
                            width="100%"
                            :color="getAvatarColor(contact.nickname)">
                          <v-img v-if="contact.url !== 'NA'" :src="contact.url"  class="ma-0 pa-0"></v-img>
                          <h1 v-else class="white--text" style="font-size: 8em;font-weight:normal">{{contact.nickname.substring(0,1)}}</h1>
                        </v-avatar>
                    </v-col>
                    <v-col cols="8" lg="8" md="8" sm="8" class="ma-0 pa-0">
                      <v-card-actions class="justify-end" style="margin-top: 5px; margin-right: 5px;">
                        <span class="subheading mr-2">{{$t(contact.presence)}}</span>
                        <v-icon v-if="contact.presence === 'available'" small style="color: green;">mdi-checkbox-blank-circle</v-icon>
                        <v-icon v-else-if="contact.presence === 'unavailable'" small style="color: gray;">mdi-checkbox-blank-circle</v-icon>
                        <v-icon v-else small style="color: red;">mdi-checkbox-blank-circle</v-icon>
                      </v-card-actions>
                      <v-card-text>
                        <v-card-subtitle
                            :style="textSize"
                            class="indigo--text"
                            v-text="contact.nickname"
                            style="padding-top: 5px;padding-bottom: 5px">
                        </v-card-subtitle>
                        <v-card-subtitle
                            :style="textSize"
                            v-text="contact.fullName"
                            style="padding-top: 5px;"
                        ></v-card-subtitle>
                      </v-card-text>
                      <v-card-actions class="justify-end" style="margin-bottom: 5px;">
                        <span class="subheading mr-2">{{contact.role}}</span>
                        <span v-if="contact.role!=='Cuidador'" class="subheading mr-2">
                        <tr v-for="(home, index) in contact.homes" :key="index">
                          <td style="margin-right:5px;">{{ home }}</td>
                        </tr>
                      </span>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </v-col>
            <!-- -->
          </v-sheet>
        </v-col>
        <!-- Pantalla videollamada  -->
        <v-col style="background-color:#0c4454; border-radius:5px">
          <div v-show="this.isCalling" id="jaas-container" style="height: 100%; width: 100%; min-height:60vh; min-width:60vh; "></div>
          <v-icon v-show="!this.isCalling" style="height: 100%; width: 100%; min-height:60vh; min-width:60vh;" color="rgb(50,90, 120)" size="480">mdi-account</v-icon>
        </v-col>
      </v-row>
      <!-- overlay and loading -->
      <v-overlay v-if="(contacts.length===0 || userTypeItemList.length===0 || homeItemList.length===0) && (this.jwt)"  opacity="1.0" absolute color="#063442">
        <v-container fluid fill-height >
          <v-layout justify-center align-center>
            <v-progress-circular
                indeterminate color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-container>
  </v-main>
</template>

<script>
import {mapState} from 'vuex'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import {EventBus} from '@/bus/bus'
import router from '@/router'
import logger from '@/mixins/logger'

export default {
  name: "Videollamada",
  data() {
    return {
      isCalling:false,
      homeItemList: [], // Filtro por home
      selectedHome: "Todos",
      userTypeItemList: [],     // Filtro por tipo de usuario
      selectedUserType: "Todos",
      //Jitsi Vars
      hasJWT: false, //Flag for my user has JWT
      jwt: '',
      api: {}

    };
  },
  methods: {
    //Launcher
    loadJitsiApi (room, jwt) {
        store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: true})
        const options = {
            roomName: room,
            parentNode: document.querySelector('#jaas-container'),
            jwt: jwt,
            width: "100%",
            height: "100%",
            configOverwrite: {prejoinPageEnabled: false, defaultLanguage:"es", resolution: 360},
            interfaceConfigOverwrite: { 
              HIDE_INVITE_MORE_HEADER: true, 
              TOOLBAR_BUTTONS: ['microphone','camera','desktop','embedmeeting','fullscreen','fodeviceselection','hangup','profile','recording','livestreaming','etherpad','sharedvideo','settings','videoquality','filmstrip','invite','shortcuts','tileview','videobackgroundblur','mute-everyone'],
            }
          // interfaceConfigOverwrite: {prejoinPageEnabled: false,}
        };
        const api = new window.JitsiMeetExternalAPI("8x8.vc", options);
        api.addEventListener('videoConferenceLeft', this.endOrCancelCallAndLeaveRoom);
        this.api = api;
    },

    //Leave and close
    endOrCancelCallAndLeaveRoom() {
      store.commit('videocall/transition', 'END_CALL')
      store.commit('videocall/transition', 'CANCEL_CALL')
      try {
        this.leaveRoom()
      } catch (err) {
        logger.log('Room left')
      }
    },

    callEndedAndLeaveRoom() {
      //store.commit('videocall/transition', 'CALL_ENDED')
      this.leaveRoom()
    },

    callRejectedAndLeaveRoom() {
      //store.commit('videocall/transition', 'CALL_REJECTED')
      this.leaveRoom()
    },

    callCancelledAndLeaveRoom() {
      //store.commit('videocall/transition', 'CALL_CANCELLED')
      this.leaveRoom()
    },
    
    connectionClosedAndLeaveRoom() {
      this.leaveRoom()
    },

    hangupCallAndLeaveRoom(params) {
      this.$store.commit('videocall/transition', 'END_CALL')
      this.$store.commit('videocall/transition', 'CANCEL_CALL')
      try {
        //store.commit('videocall/RESET_CURRENT_CALL')
        this.leaveRoom()
      } catch (err) {
        logger.log('Room left')
      }
      router.push(params.params.menu)
    },

    leaveRoom () {
        const el = document.getElementById('jitsiConferenceFrame0');
        if (el != null) el.parentNode.removeChild(el);
        const api = this.api
        api.removeEventListener(['videoConferenceLeft']);
        api.dispose();
        this.api={};
        store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})
        store.commit('videocall/RESET_CURRENT_CALL')
        this.isCalling=false
    },

    //Send call
    sendCall (contact) {
      if (contact.presence === 'available' && this.hasJWT) {
        let prefix = store.getters['backend/myself'].tenant.tenantName
        
        // call logic
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (dt + Math.random()*16)%16 | 0
            dt = Math.floor(dt/16)
            return (c=='x' ? r :(r&0x3|0x8)).toString(16)
        });
        this.makeCall (contact, prefix + "-" + uuid)
      }
      else if (contact.presence !== 'available'){
        this.launchContactUnavailableDialog(contact)
      }
    },

    //Make call
    makeCall (contact, roomId) {
      // Show jitsi screen
      this.isCalling=true

      // Show notification
      this.launchCallingNotification(contact)
      let fullroomId = store.getters['backend/myself'].tenant.tenant8x8+"/"+roomId
      let jwt = store.getters['backend/jwt']
      this.loadJitsiApi(fullroomId,jwt)
      store.commit('videocall/transition', { type:'MAKE_CALL', contact: contact.jid, roomId: roomId})
      //this.$vsm.transition('MAKE_CALL')
    },

    //Get JWT Token
    getJwtToken(){
      if(store.state.backend.jwt){ this.hasJWT = true}
      else{
        store.dispatch('backend/BACKEND_JWTTOKEN')
            .then((jwt) => {
              this.hasJWT = true
              store.state.backend.jwt = jwt
              console.log("JWT", jwt);
       }).catch((error) => {
          this.hasJWT = false
        })
      }
    },

    // ====== Contact Methods ======

    //Listing User Types
    getUserTypeList(){
      store.dispatch('backend/BACKEND_USERTYPESLIST')
          .then((data) => {
            let aux=[];
            aux.push(i18n.t('All'))
            for( let j = 0; j < data.length; j++) {
              switch (data[j]) {
                case "FAMILY":
                  aux.push(i18n.t('Family'));
                  break
                case "PATIENT":
                  aux.push(i18n.t('Patient'));
                  break
                case "CARER":
                  aux.push(i18n.t('Carer'));
                  break
              }
            }
            this.userTypeItemList = aux;
      })
    },

    //List User Homes
    getHomesTypeList(){
      this.homeItemList=[]
      let mySelfHomes = store.getters['backend/myself'].backendUserHomes
      if(mySelfHomes){
        this.homeItemList = mySelfHomes.map(a => a.homeName)
        this.homeItemList = [...new Set(this.homeItemList)]
      }
      this.homeItemList.push(i18n.t('All'))
    },

    //Filter Contacts By Role
    filterContacsByRole: function(item, value){
      let aux = []
      for( let j = 0; j < item.length; j++) {
        if(item[j].role === value) aux.push(item[j])
      }
      return aux
    },

    //Filter Contacts By Home
    filterContacsByHome: function(item, valueIn){
      let aux = []
      for( let j = 0; j <item.length; j++) {
        Object.entries(item[j].homes).forEach(([key, value]) => {
          let contains = aux.includes(valueIn)
          if (!contains) {
            if(value===valueIn){
              aux.push(item[j])
            }
          }
        });
      }
      return aux
    },

    //Get Avatar Color
    getAvatarColor(nickName){
      let avatarBackgroundPalette=["#3E7690","#8064A7","#49198D","#083545"]
      if (nickName){
        let nickNameSize = nickName.length
        return avatarBackgroundPalette[nickNameSize % avatarBackgroundPalette.length]
      }
      else{
        return 'indigo'
      }
    },

    //Sort Contacts by Nickname
    sortedContacts(contacts){
      return contacts
        .sort(function (a, b) {
          if (a.nickname > b.nickname) return 1
          if (a.nickname < b.nickname) return -1
          return 0;
        });
    },

    //Notificacion: llamando a contacto X
    launchCallingNotification(contact){
      var params={}
      params.type='calling'
      params.contact=contact
      EventBus.$emit('notification', {params});
    },

    // Modal: Contacto no disponible
    launchContactUnavailableDialog(contact){
      var params={}
      params.type='unavailable'
      params.contact=contact
      EventBus.$emit('dialog', {params});
    }
  },
  mounted() {
    this.getHomesTypeList();
    this.getJwtToken();
    this.getUserTypeList();
  },
  computed: {
    ...mapState({
       contacts: state => state.xmpp.contacts,
       cardsDisabled: state => state.videocall.cardsDisabled,
    }),
    // Filtrado de contactos
    filterContacts: function(){
      let filtrado=store.state.xmpp.contacts.slice(0)
      if(filtrado.length!=0){
        if(filtrado[filtrado.length-1].isResolved){
            if(this.selectedUserType !== i18n.t('All')){
              filtrado=this.filterContacsByRole(filtrado,this.selectedUserType)
            }
            if(this.selectedHome !== i18n.t('All')){
              filtrado=this.filterContacsByHome(filtrado,this.selectedHome)
          }
        }
      }
      return filtrado
    },
    textSize: function(){
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'font-size: 20px;'
        case 'sm': return 'font-size: 20px;'
        case 'md':return 'font-size: 20px;'
        case 'lg': return 'font-size: 20px;'
        case 'xl': return 'font-size: 20px;'
        default: return 'font-size: 20px;'
      }
    },
  },
  created() {
    EventBus.$on('incomingCall',() => {
      if (store.getters['videocall/getCurrentCall']) {
        this.loadJitsiApi(store.getters['backend/myself'].tenant.tenant8x8+"/"+store.getters['videocall/getCurrentCall'].roomId, store.getters['backend/jwt'])
        this.isCalling = true
      }
    }),
    EventBus.$on('callRejectedAndLeaveRoom',() => {
      if (store.getters['videocall/getCurrentCall']) this.callRejectedAndLeaveRoom()
    }),
    EventBus.$on('callEndedAndLeaveRoom',() => {
      if (store.getters['videocall/getCurrentCall']) this.callEndedAndLeaveRoom()
    }),
    EventBus.$on('callCancelledAndLeaveRoom',() => {
      this.callCancelledAndLeaveRoom()
    })
    EventBus.$on('connectionClosedAndLeaveRoom',() => {
      this.connectionClosedAndLeaveRoom()      
    })
    EventBus.$on('hangupCall',(params) => {
      if (store.getters['videocall/getCurrentCall']) this.hangupCallAndLeaveRoom(params)
    })
    EventBus.$on('makeCall',(params) => {
      this.makeCall(params.contact, params.roomId)
    })
    EventBus.$on('timeoutCallAndLeaveRoom',(params) => {
      this.endOrCancelCallAndLeaveRoom()
    })
  },
  beforeDestroy() {
    EventBus.$off('incomingCall')
    EventBus.$off('callRejectedAndLeaveRoom')
    EventBus.$off('callEndedAndLeaveRoom')
    EventBus.$off('callCancelledAndLeaveRoom')
    EventBus.$off('timeoutCallAndLeaveRoom')
    EventBus.$off('connectionClosedAndLeaveRoom')
    EventBus.$off('makeCall')
  }
};
</script>
<style>
.event-card {
  overflow: hidden;
  width: 630px;
  border-radius: 0.3em;
}
.flexcard {
  display: flex;
  flex-direction: column;
}
.flexcard .v-toolbar {
  flex: 0;
}
.sticky{
  position: absolute;
  left: 15%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
