<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {
  }
}
</script>
