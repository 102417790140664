/* eslint-disable no-console */
import * as XMPP from 'stanza'
import store from '@/store'
import {i18n} from "@/plugins/i18n"
import {backendService} from "@/services/backend.service"
import logger from '@/mixins/logger'

export const VUE_APP_CONTENTS_TOPIC = process.env.VUE_APP_CONTENTS_TOPIC
export const VUE_APP_UPDATEAPP_TOPIC = process.env.VUE_APP_UPDATEAPP_TOPIC
export const VUE_APP_PUBUSUB_PREFIX = process.env.VUE_APP_PUBUSUB_PREFIX
export const VUE_APP_IOT_URL = process.env.VUE_APP_IOT_URL

export const xmppService = {
  jid,
  fullJid,
  context,
  xmppClient,
  create,
  connect,
  listen,
  disconnect,
  sendMessage,
  sendPresence,
  getVcard,
  subscribeToTopic,
  createTopic,
  publishMessageToTopic,
  updateTopicAffiliations
}

let jid =  null
var fullJid = null
let context = null
var xmppClient = null

// create XMPP client with credentials and context
function create(jid, password, transportsUser, context) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    this.jid = jid
    this.context = context
    let transports = {
      websocket: transportsUser,
    }
    this.xmppClient = XMPP.createClient({
      jid,
      password,
      resource: Date.now(),
      transports: transports,
      autoReconnect: true,
      allowResumption:true,
      timeout: 20
    })

    // debug stanza on dev mode
    this.xmppClient.on('*', (name, data) => {
      switch (name) {
        case 'raw:incoming':
        // case 'raw:outgoing':
          return
      }
       logger.log(name, data)
    })
    xmppClient = this.xmppClient
    resolve()
  })
}

// connect client to XMPP server
function connect() {
  let that = this
  return new Promise((resolve, reject) => {
    // listen for authentication failure
    xmppClient.on('auth:failed', () => {
      reject('Check your credentials')
    })

    // listen for authentication success
    xmppClient.on('auth:success', () => {
      // remove websocket failure listener
      if(that){
        if(that.jid) localStorage.setItem('jid', that.jid)
      }
      localStorage.setItem('auth', true)
      // resolve when listen is resolved
      xmppClient.getCredentials().then((credentials) => {
        that.context.$store.commit('xmpp/setXmppCredentials', credentials)
      })
      that.listen()
      .then(() => resolve())
    })

    try {
      xmppClient.connect()
    } catch (error) {
      reject('Error during login')
    }
  })
}

// logic post connection (listeners)
function listen() {
  return new Promise((resolve) => {

    // handle reconnection
    xmppClient.on('stream:management:resumed', () => {
      //this.context.$store.commit('xmpp/setOnline', true)
      store.commit('xmpp/setOnline', true)
    })

    // handle session start
    xmppClient.on('session:started', () => {
      // store full Jid from server
      fullJid = XMPP.JID.parse(xmppClient.jid)
      //fullJid = this.fullJid
      store.commit('xmpp/setOnline', true)
      resolve()

      xmppClient.on('disconnected', () => {
        store.commit('xmpp/setOnline', false)
      })

      // get contacts (rfc6121)
      getRoster()

      // Manage topics (Check subscribed)
      manageTopicsForUser()

      // Obtener la propia Vcard
      xmppClient.getVCard(this.jid)
          .then((data) => {
            let vCard = {}
            if (data.records) {
              vCard.fullName= data.fullName
              for( let j = 0; j < data.records.length; j++) {
                switch (data.records[j].type) {
                  case "jid":
                    vCard.jid=data.records[j].value
                    break
                  case "nickname":
                    vCard.nickname=data.records[j].value
                    break
                  case "role":
                    vCard.role=data.records[j].value
                    break
                  case "url":
                    vCard.url=data.records[j].value
                    break
                  case "tel":
                    if (data.records[j].types[0] === "home") vCard.phone = data.records[j].value
                    if (data.records[j].types[0] === "voice") vCard.mainContact = data.records[j].value
                    break
                }
              }
            }
            this.context.$store.commit('xmpp/setMyVcard', vCard)
            sendPresence()
            //xmppClient.sendPresence()
          }).catch((er) => {
          logger.log(er)
          })

      // enable carbons (XEP-0280: Message Carbons)
      xmppClient.enableCarbons()
      .catch((error) => logger.error('carbon', error))
    })

    // listen for contact messages
    xmppClient.on('chat', (receivedMessage) => {
      handleReceivedMessage(receivedMessage, 'direct')
    })

    // listen for carbon
    xmppClient.on('carbon:sent', (receivedMessage) => {
      handleReceivedMessage(receivedMessage, 'carbon')
    })

    xmppClient.on('message:error', receivedMessage => {
      handleReceivedMessage(receivedMessage, 'error')
    })

    // listen for message sent by user (direct or carbon)
    /*xmppClient.on('message:sent', (message) => {
     console.log('message sent', message.to+"\n"+message.body)
     if (!message.body) {
        no body in message (probably a chat state)
       return
     }
      storeMessage(this, null, message)
    })*/

    // listen for contact chat state (writing, pause, ...)
    xmppClient.on('chat:state', message => {
      this.context.$bus.$emit('chatState', {
        jid: XMPP.JID.parse(message.from).bare,
        chatState: message.chatState,
      })
    })

    // listen for all presences
    xmppClient.on('presence', presence => {
      let fullJid = XMPP.JID.parse(presence.from)

      //si presence.show = chat && presence.type = null-> comunicando
      //si presence.show = null && presence.type = null-> disponible
      //si presence.show = null && presence.type = unavailable -> desconectado
      //si presence.show = xa, away, dnd && presence.type = null-> no disponible
      let _presence = ""
      if(presence.type && presence.type === "unavailable") {
        _presence = "unavailable"
      } else {
        switch (presence.show) {
          case 'chat':
            _presence = "busy"
            break
          case 'xa':
            _presence = "away"
            break
          case 'away':
            _presence = "away"
            break
          case 'dnd':
            _presence = "away"
            break
          default:
            _presence = "available"
        }
      }
      store.commit('xmpp/setContactPresence', {jid: fullJid.bare, presence: _presence})
    })

    // listen for roster updates
    xmppClient.on('roster:update', roster => {
      logger.log('roster:update', roster);
      getRoster()
    })

    // Listen for pubsub content
    xmppClient.on('pubsub:published', msg => {
      logger.log('pubsub: ',msg)
    });
  })
}


/*
  Method for get the subrcribed topics (pub-sub)
 */
function getSubscribedTopics(pubSubServer){
  pubSubServer='pubsub.cuenca.ejabberd.ddnsfree.com'
  xmppClient.getSubscriptions(pubSubServer)
      .then((response) => {
        logger.log(response)
      })
      .catch((error) => {
        logger.log(error)
      })
}

function manageTopicsForUser(){

  let pubSubServer = VUE_APP_PUBUSUB_PREFIX+ '.' + store.getters['backend/myself'].tenant.xmppDomain
  
  let contentTopic = VUE_APP_CONTENTS_TOPIC
  subscribeToTopic(pubSubServer,contentTopic)
      .then((response) => {
        logger.log("SUBSCRIBED TO CONTENTS")
      }).catch((error) => {
        logger.log("NOT SUBSCRIBED TO CONTENTS - TRYING TO CREATE NODE")
        createTopic(pubSubServer, contentTopic).then((response) => {
          logger.log("NODE CONTENTS CREATED - TRYING TO RESUBSCRIBE")
          subscribeToTopic(pubSubServer, contentTopic);
        }).catch((error) => {
          logger.log("ERROR CREATING NODE CONTENTS");
        })
        if(error.error.condition === "item-not-found"){
          this.$dialog.confirm({
            title: 'Error',
            text:'Error al comprobar actualizacion de contenidos. ' +
                'Re-logee en la aplicacion y si sigue viendo este mensaje, reporte la incidencia. Gracias' ,
            persistent: true,
            actions: {
              true: {
                color: 'black',
                text: 'Aceptar',
              }
            }
          })
        }
      })
  
  
  let updateTopic = VUE_APP_UPDATEAPP_TOPIC
  subscribeToTopic(pubSubServer,updateTopic)
      .then((response) => {
        logger.log("SUBSCRIBED TO UPDATEAPP")
      }).catch((error) => {
        logger.log("NOT SUBSCRIBED TO UPDATEAPP - TRYING TO CREATE NODE")
        createTopic(pubSubServer, updateTopic).then((response) => {
          logger.log("NODE UPDATEAPP CREATED - TRYING TO RESUBSCRIBE")
          subscribeToTopic(pubSubServer, updateTopic);
        }).catch((error) => {
          logger.log("ERROR CREATING NODE UPDATEAPP");
        })
        if(error.error.condition === "item-not-found"){
        this.$dialog.confirm({
          title: 'Error',
          text:'Error al comprobar actualizacion de contenidos. ' +
              'Re-logee en la aplicacion y si sigue viendo este mensaje, reporte la incidencia. Gracias' ,
          persistent: true,
          actions: {
            true: {
              color: 'black',
              text: 'Aceptar',
            }
          }
        })
      }
    })
}

/*
  Method for subscribed to a topic (pub-sub)
 */
function subscribeToTopic(pubSubServer,topic){
  return new Promise((resolve, reject) => {
    xmppClient.subscribeToNode(pubSubServer, topic)
        .then((response) => {
          logger.log('Subscribe ' + topic + ':',response)
          resolve (response)
        })
        .catch((error) => {
          logger.log('Subscribe ' + topic + ':', error)
          reject (error)
        })
  })
}

/*
  Method for subscribed to a topic to another user (pub-sub). Owner privileges required
 */
function updateTopicAffiliations(pubSubServer,topic, jid, topicRole){
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async(resolve, reject) => {
      xmppClient.updateNodeAffiliations(
          pubSubServer,topic, [ {
              node: topic,
              affiliation: topicRole,
              jid: jid
            }]
          )
          .then((response) => {
            logger.log('updateNodeAffiliations ' + topic + ':',response)
            resolve (response)
          })
          .catch((error) => {
            logger.log('updateNodeAffiliations ' + topic + ':', error)
            reject (error)
          })
  })
}

/*
  Method for create a topic (pub-sub)
 */
function createTopic(pubSubServer,topic){
  return new Promise((resolve, reject) => {
    xmppClient.createNode(pubSubServer, topic,
        /*{ type: 'flat', fields: [
            {name: 'pubsub#publish_model', value:'open', type:'list-single'}, // People that can publish
            {name: 'pubsub#max_items', value:'0', type:'text-single'}, // Items buffer Number (send in reconnection)
            {name: 'pubsub#persist_items', rawValues: ["0"], value: false, type: "boolean"}]
        }*/
        ) // No persist items in the buffer
        .then((response) => {
          logger.log(response)
          resolve(response)
        })
        .catch((error) => {
          logger.log(error)
          reject(error)
        })
  })
}

/*
  Method for publish in a topic (pub-sub)
 */
function publishMessageToTopic(pubSubServer,topic, messageBody){
  return new Promise((resolve, reject) => {
/*  topic = 'contents'
    pubSubServer='pubsub.cuenca.ejabberd.ddnsfree.com'
    messageBody="{action: 'refresh', id: -1, timestamp: -1}"*/
    xmppClient.publish(pubSubServer, topic,
        { 'itemType': 'http://jabber.org/protocol/geoloc',
          'country': JSON.stringify(messageBody)})
        .then((response) => {
          logger.log(response)
          resolve(response)
        })
        .catch((error) => {
          logger.log(error)
          reject(error)
        })
  })
}


/*
  Method used for get the roster from a contact
 */
function getRoster() {
  logger.log('roster');
  xmppClient.getRoster()
  .then((data) => {
    let contacts = []
    if (data.items.length != 0) {
      for (var i = 0; i < data.items.length ; i++) {
        let contact = {}

        // Get Vcard
        contact.isResolved=false

        // Get Homes
        contact.homes = data.items[i].groups
        let hasFullName = false;
        let hasNickName = false;

        xmppClient.getVCard(data.items[i].jid)
        .then((data) => {
          if (data.records) {
            hasFullName = true;
            contact.fullName= data.fullName
            for( let j = 0; j < data.records.length; j++) {
              switch (data.records[j].type) {
                case "jid":
                  contact.jid=data.records[j].value
                  break
                case "nickname":
                  contact.nickname=data.records[j].value
                  hasNickName = true;
                  break
                case "role":
                  // Parse the role value
                  switch (data.records[j].value) {
                      case "FAMILY":
                        contact.role=(i18n.t('Family'));
                        break
                      case "PATIENT":
                        contact.role=(i18n.t('Patient'));
                        break
                      case "CARER":
                        contact.role=(i18n.t('Carer'));
                        break
                    case "FRIEND":
                      contact.role=(i18n.t('Friend'));
                      break
                    }
                  break
                case "url":
                  contact.url=data.records[j].value
                  break
                case "tel":
                  if (data.records[j].types[0] === "home") contact.phone = data.records[j].value
                  if (data.records[j].types[0] === "voice") contact.mainContact = data.records[j].value
                  break
              } 
            }
            contact.presence='unavailable'
            contact.isResolved=true
            if (hasFullName & hasNickName) contacts.push(contact);
          }
        })
        .catch((er) => {
          logger.log(er)
        })
      }
      store.commit('xmpp/setRoster', contacts)
    }
    // send presence to contacts (rfc6121)
  })
  .catch((rosterError) => logger.error('getRoster', rosterError))
}

/*
  Disconnect from the app
 */
function disconnect() {
  store.dispatch('backend/REGISTER_XMPP_STATUS', "unavailable")
  xmppClient.disconnect()
}

/*
  Method used for send a message to a contact
 */
function sendMessage(to, body) {
  xmppClient.sendMessage({
    from: fullJid.full,
    to,
    body: JSON.stringify(body),
    type: 'chat',
  })
  store.dispatch('backend/REGISTER_CALL', body)
  handleSentMessage(to,body)
}

function sendPresence(presence) {
  let _presence
  if (presence) {
    if(presence.type && presence.type === "unavailable") {
      _presence = "unavailable"
    } else {
      switch (presence.show) {
        case 'chat':
          _presence = "busy"
          break
        case 'xa':
          _presence = "away"
          break
        case 'away':
          _presence = "away"
          break
        case 'dnd':
          _presence = "away"
          break
        default:
          _presence = "available"
      }
    }
    store.dispatch('backend/REGISTER_XMPP_STATUS', _presence)
    xmppClient.sendPresence(presence)
  } else {
    store.dispatch('backend/REGISTER_XMPP_STATUS', "available")
    xmppClient.sendPresence()
  }
}

/*
  This Method is used for handle the recieved messages (direct or carbons)
 */
function handleReceivedMessage (message, type) {
  if (type === 'direct') {
    if (message.body){
      let body = JSON.parse(message.body)
      logger.log('TRAZA - Mensaje',body)
      let jid = message.from ? XMPP.JID.parse(message.from) : null
      store.commit('videocall/HANDLE_MESSAGE', {jid: jid.bare, message: body, type: type})
      body.timestamp = new Date().toLocaleString('es-ES')
      store.commit('xmpp/setLastReceivedMessage', {jid: jid.bare, message: body, type: type})
    }
  } 
  else if (type === 'carbon'){
    let _message = message.carbon.forward.message
    if (!store.getters['videocall/getActiveCall']){
      logger.log('TRAZA - Carbon',_message)
      let bodyCarbon = JSON.parse(_message.body)
      store.commit('videocall/HANDLE_CARBON', {body: bodyCarbon})
    }
  }
  else if (type === 'error') {
    if (message.body){
      let _body = JSON.parse(message.body)
      if(_body) {
        if (_body.action === 'INVITE') {
          let jabberIdTo = message.from.split('@')[0];
          let title = i18n.t('nMissedCallTitle');
          let notifBody = i18n.t('nMissedCallBody');
          backendService.sendNotification(jabberIdTo,title,notifBody,"high",true)
        }
      } 
    }
  }
}

/*
  Method used for handle send mesagges (store the message, etc)
 */
function handleSentMessage (jid,message) {
  if (message){
    message.timestamp = new Date().toLocaleString('es-ES')
    store.commit('xmpp/setLastSentMessage', {jid: jid, message: message})
  }
}

/*
  This method is used for get the VCard from a user
*/
function getVcard(jid) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    xmppClient.getVCard(jid)
        .then(data => {
          resolve(data)
        })
        .catch((er) => {
          logger.log(er)
        })
  })

}