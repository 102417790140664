import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import vuetify from './plugins/vuetify'
import {i18n} from "@/plugins/i18n"
import {AXIOS} from "@/plugins/axios"
import VuetifyDialog from "vuetify-dialog"
import "vuetify-dialog/dist/vuetify-dialog.min.css"
//import './registerServiceWorker'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueGapi from 'vue-gapi'

Vue.config.productionTip = false
Vue.config.silent = true
Vue.prototype.$bus = new Vue()

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

Vue.use(VueGapi, {
  //apiKey: 'AIzaSyD37ULvJGTMQBQQEc2CHs5fxaoqn3HMRc0',
  //clientId: '1038884057065-s0lp65sl37bfkdopb5rscte6ensduiv6.apps.googleusercontent.com',
  apiKey: 'AIzaSyBAOGYwAe2NcKxFXjDfB1HpzpwGocEJC7Y',
  clientId: '982869937768-861vukd0d3ahdh7hqdlimm146fh9s61s.apps.googleusercontent.com',
  //discoveryDocs: ['https://youtube.googleapis.com/$discovery/rest?version=v3'],
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'],
  scope: 'https://www.googleapis.com/auth/youtube',
});

let app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  AXIOS,
  render: h => h(App)
}).$mount('#app')

store.$app = app
