const messages = {
    // ====== LABELS ======
    lAppTitle: 'Senior Care',
    lCode:'Código',
    lVideollamadaTitle: 'Videollamada',
    lContenidosTitle: 'Contenidos',
    lContenidosAdd: 'Dar de Alta',
    lContenidosDelete: 'Eliminar Recurso',
    lContenidosEdit: 'Editar Recurso',
    lHomeFilterLabel: 'Filtrar por hogar',
    lUserTypeFilterLabel: 'Filtrar por tipo de usuario',
    lUser: 'Usuario',
    lUsersAssigned: 'Usuarios Asignados',
    lUserType: 'Tipo Usuario',
    lUserRole: 'Roles del usuario',
    lUserRoleAssign: 'Asignar Roles del usuario',
    lPassword: 'Contraseña',
    lRequired: 'Campo obligatorio',
    lEmailRequired:'Tipo Email Requerido',
    lSelectDomain: 'Selecciona el dominio',
    lLoginTittle: 'Acceso a la plataforma',
    lName:'Nombre',
    lHome:'Hogares',
    lImageURL:'Seleccionar Imagen de contenido',
    lImageURLExternal:'URL de Imagen externa',
    lUploadImage:'Subir Imagen',
    lImage:'Imagen',
    lResourceURL:'URL del Recurso',
    lDescription:'Descripción',
    lCategory:'Categoría',
    lSubcategory:'Subcategoría',
    lState:'Estado',
    lCreationUser:'Usuario Creación',
    lAssignHomes:'Asignar Hogares',
    lAssignUsersMailPassword:'Enviar credenciales a usuario',
    lSelect:'Selecionar',
    lSelectHome:'Añadir hogares',
    lSelectAllHomes:'Añadir todos los hogares',
    lSelectUser:'Seleccionar usuarios',
    lSeeUser:'Ver usuarios',
    lSelectStartDate:'Seleccionar fecha Inicio',
    lSelectendDate:'Seleccionar fecha fin',
    lSelectImagen:'Seleccionar Imagen',
    lStartDate:'Fecha Inicio',
    lendDate:'Fecha Fin',
    lTenantName:'Tenant',
    lEnable:'Activado',
    lModerator:'Puede Moderar',
    lRecorder:'Puede grabar',
    lLiveStreaming:'Puede emitir en directo',
    lFooterOf: 'de',
    lRowsPerPage: 'Filas por página',
    lAll: 'Todas',
    lLoading: 'Cargando... Por favor, espere',
    lActions: 'Acciones',
    lFilter: 'Filtrar',
    lMaxLength:'Tamaño máximo superado',
    llAphanumericPlus:'Tipo: Alpha numérico o símbolos requerido',
    lNumericPlus:'Tipo: Números o símbolos requerido',
    lHomesManagement:'Gestionar Hogares',
    lNewHomes:'Alta Hogar',
    lEditHomes:'Editar Hogar',
    lUsersManagement:'Gestionar Usuarios',
    lNewUser:'Alta Usuario',
    lEditUser:'Editar Usuario',
    lThingsboardManagement:'Gestionar IOT',
    lYes:'Si',
    lNo:'No',
    lFAMILY:'Familiar',
    lPATIENT:'Usuario',
    lCARER:'Profesional',
    lFriend:'Amigo',
    lAdmin:'Administrador',
    lNumeric:'Valor numérico requerido',
    // ====== TOOLTIPS ======
    ttDelete: 'Eliminar',
    ttPermanentDelete: 'Eliminar de forma definitiva',
    ttDeactivate: 'Desactivar',
    ttActivateDeactivate: 'Activar/Desactivar',
    ttEdit: 'Editar',
    ttModify: 'Modificar',
    ttPlay:'Comenzar',
    ttSeeUsers:'Ver usuarios',
    ttOpenDashboard:'Abrir Dashboard',
    ttOpenDashboardInNewWindows:'Abrir Dashboard en una nueva ventana',
    // ====== BUTTONS ======
    bLogin: 'ACCEDER',
    // ====== Error Messages ======
    eLoginCredentialsInvalid:'Los datos de acceso no son correctos',
    eLoginNotActivatedUser:'El usuario está desactivado',
    eLoginSesionExpired:'sesión expirada',
    eLoginUnauthorizedROLE:'Acceso no autorizado',
    eNotFilteredResults:'No hay resultados para esta búsqueda',
    eNotJWT:'Lista de contactos no disponible',
    // ====== Others ======
    Family:'Familiar',
    Carer:'Profesional',
    Patient:'Usuario',
    Friend:'Amigo',
    All:'Todos',
    // ====== Presence ======
    available:'Disponible',
    unavailable:'Desconectado',
    busy:'Comunicando',
    away:'No disponible',
    // ====== BUTTONS ======
    bAdd: 'Alta',
    bCancel:'Cancelar',
    bClose:'Cerrar',
    bAccept:'Aceptar',
    bSave:'Guardar',
    bUploadImage:'Subir Imagen',
    nWakeUpButton: 'Mandar notificación Push',
    nSendNotification: 'Mandar notificación',
    nReSendNotification: 'Reenviar notificación',
    // ====== NOTIFICATIONS ======
    nMissedCallTitle: 'Llamada perdida',
    nMissedCallBody: 'Tiene una llamada perdida de ',
    nWakeUpTitle: 'INVITE',
    nWakeUpBody: 'Solicitud de despertar la aplicación',
    // ====== Ejjaberd ======
    lCompleteName:'Nombre Completo',
    lNickName:'Nombre Corto',
    lPhoto:'Foto',
    lPrincipalContact:'Contacto Principal',
    lContactPhone:'teléfono de contacto',
    lAge:'Fecha de nacimiento',
    lAdress:'Dirección',
    lLocality:'Localidad',
    lProvince:'Provincia',
    lPostalCode:'Código Postal',
    lTelephone:'Teléfono',
    lEmail:'Email',
    lCoordinates:'Coordenadas',
    // ERROR MESSAGES
    '1001': 'Sesión caducada',
    '1002': 'Acceso denegado',
    '1003':'Rol no autorizado',
    '1004': 'Usuario no encontrado',
    '1005': 'Usuario nulo. ¿Esta logeado?',
    '1006': 'Campo nulo introducido',
    '1007': 'Tenant nulo. ¿Esta correctamente logeado?',
    '1008': 'VCard - Error al solicitar la V-Card',
    '1009': 'Rooster - Error al solicitar el Roster',
    '1010': 'Tipos de usuario - Error al solicitar',
    '1011': 'Token para notificaciones Invalido',
    '1012': 'Error al actualizar token para notificaciones',
    '1013': 'Error al subir un fichero de Imagen',
    '1014': 'Error al intentar obtener los videos del canal de youtube',
    '1015': 'Error al intentar eliminar los videos del canal de youtube',
    '1016': 'Categoría del contenido Nula',
    '1017': 'Contenidos - Error al realizar una operación',
    '1018': 'Notificación - Error al realizar una operación',
    '1019': 'El contacto no tiene token de firebase',
    '1020':'Hogares - Error al realizar una operación',
    '1021': 'Hogares - El hogar ya existe',
    '1022': 'Usuarios - El usuario ya existe',
    '1023':'Email - No se ha podido enviar el email',
    '1024': 'IOT - Error al crear usuario',
    '1025': 'IOT - Error al crear Hogar',
    '1026': 'IOT - Error al Borrar Usuario de un Hogar',
    '1027': 'Se ha producido un error al borrar un Usuario de manera permanente',
    '1028': 'Se ha producido un error al enviar la notificacion',
    '1029': 'Usuarios - Un usuario dado de alta no puede tener contraseña nula',
    '1030': 'IOT - Error al activar o desactivar usuarios',
    '1031': 'IOT - Error al logear en el servicio de IOT',
    '1032': 'IOT - Error al crear al usuario. Ya existe un usuario con ese mail',
    '1033': 'IOT - Error al Interacionar con la API',
    '1034': 'Error Contenido Nulo',
    '1035': 'Error Nombre Vacio',
    '1036': 'Error Descripcion Vacia',
    '1037': 'Fecha de inicio no seleccionada',
    '1038': 'La Fecha de inicio no puede ser posterior a la Fecha de Fin',
    '1039': 'IOT - Dirección URL del servidor vacía',
    '1040': 'Se ha introducido un JabberId nulo al realizar una operacion',
    '1041': 'Se ha introducido una contraseña nula de ejabberd al realizar una operacion',
    '1042': 'El campo host de xmpp asociado al tenant es nulo',
    '1043': 'Se ha recibido una respuesta de error de ejabberd',
    '1044': 'Se ha introducido un grupo nulo al realizar una operacion',
    '1045': 'Se ha introducido un nombre de un campo de la vcard nulo al realizar una operacion',
    '1046': 'Media Resource - Dirección URL del contenido vacía',
    '1047': 'Se ha producido un error al intercambiar información con el servidor de IoT',
    '1048': 'Error al intercambiar informacion con el servidor de IoT'
}

export default messages
