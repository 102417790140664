<template>
    <div class="fill-height login-bg">
        <v-row align="center" justify="center" class="fill-height">
            <v-col cols="12" sm="10" md="8" lg="5" xl="4">
                <v-form ref="form" v-model="formValid" @submit.prevent="executeLogin()">
                      
                    <v-img
                        style="border-radius: 50%; margin: auto;"
                        width="400px"
                        height="400px"
                        :src="require('@/assets/logo_fs.png')"
                        v-if="showLogo">
                    </v-img>
                    <v-card class="elevation-1 box"
                      v-else>
                        <v-card-subtitle class="text-center"  :style="{ padding: !showLockIcon ? '0' : '16' }">
                            <v-img v-if="!showLockIcon"
                            style="margin: auto; object-fit: cover;"
                            width="200px"
                            height="200px"
                            :src="imageSource"
                            @error="handleImageError"
                            >
                            </v-img>
                          <span v-if="showLockIcon" class="group pa-4 rounded-circle pink">
                              <v-icon large dark color="white">lock</v-icon>
                          </span>
                        </v-card-subtitle>
                        <v-card-title class="justify-center">
                            <span class="headline">{{ itemVcardTitle }}</span>
                        </v-card-title>
                        <v-card-text>
                            <!-- login alerts/errors -->
                            <v-alert :value="loginError === 'Unauthorized'" color="error" icon="warning" prominent
                                     class="transition-swing" transition="scale-transition">
                                <span v-html="$t('eLoginCredentialsInvalid')"></span>
                            </v-alert>
                             <v-alert :value="loginError === 'userNotEnabled'" color="error" icon="warning" prominent
                                     class="transition-swing" transition="scale-transition">
                             <span v-html="$t('eLoginNotActivatedUser')"></span>
                            </v-alert>
                            <v-alert :value="!loginError && expired" color="warning" icon="watch_later" prominent
                                     class="transition-swing" transition="scale-transition">
                                <span v-html="$t('eLoginSesionExpired')"></span>
                            </v-alert>
                          <v-alert :value="loginError === 'userNotTypeCarer'" color="error" icon="warning" prominent
                                   class="transition-swing" transition="scale-transition">
                            <span v-html="$t('eLoginUnauthorizedROLE')"></span>
                          </v-alert>
                            <!-- login form -->
                          <p>{{$t('lUser')}}:</p>
                            <v-text-field
                                    :placeholder="$t('lUser')"
                                    v-model="username"
                                    prepend-inner-icon="person"
                                    :disabled="loading"
                                    :rules="[rules.required]">
                            </v-text-field>
                          <p>{{$t('lPassword')}}:</p>
                            <v-text-field
                                    :placeholder="$t('lPassword')"
                                    v-model="password"
                                    prepend-inner-icon="vpn_key"
                                    :disabled="loading"
                                    :rules="[rules.required]"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword">
                            </v-text-field>
                            <v-combobox
                                v-if="tenantSelector"
                                    :label="$t('lSelectDomain')"
                                    hide-selected dense
                                    filled
                                    outlined
                                    solo  
                                    v-model="selectedTenant"
                                    :items="tenantList"
                                    :rules="[rules.required]">
                            </v-combobox>
                            </v-card-text>
                        <v-card-actions>
                            <!-- default button -->
                            <v-row justify="center">
                                <v-col cols="6">
                                    <v-btn class="primary" block dark type="submit" :loading="loading" :disabled="loading">
                                        {{ $t("bLogin") }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import validations from '@/mixins/validations'
    import {manageErrors} from "@/mixins/errorAlertManagement";
    import store from "@/store";
    import router from "@/router";
    import logger from '@/mixins/logger'

    export default {
        name: "Login",
        data () {
            return {
                // selected tenant in login
                selectedTenant:'',
                // Tenant list for combo box
                tenantList: [
                'cuenca',
                'asispa',
                ],
                // indicates where the session was expired
                expired: false,
                // indicates where the auth is taking place
                loading: false,
                // indicates whether the form is valid or not
                formValid: false,
                // the username (form field)
                username: null,
                // the password (form field)
                password: null,
                // rules for the form
                rules: validations,
                // indicates whether show the pass or not
                showPassword: false,
               // show tenant selector
                tenantSelector: false,
                // show Background Logo
                showLogo: false,
                // show Login Logo
                showLockIcon: true,
                imageSource: null,
            }
        },
        computed: {
            ...mapState({
                loginError: state => state.auth.loginError,
                xmppLoginError: state => state.xmpp.xmppLoginError
            }),
            itemVcardTitle () {
                return this.$t('lLoginTittle')
            }
        },
        methods: {          
            handleImageError() {
                this.showLockIcon = true;
            },
            /**
             * Executes the login
             */
            executeLogin () {
                // validate the form
                if (!this.$refs.form.validate()) {
                    return
                }
                // update the expired flag
                this.expired = false
                // mark the flag as loading
                this.loading = true
                // execute the login
              this.$store.reset()
              this.$store.dispatch('auth/AUTH_REQUEST', {
                username: this.username,
                password: this.password,
                tenant: this.selectedTenant
              }).then(() => {

                // get backend myself
                this.$store.dispatch('backend/BACKEND_MYSELF',{})
                    .then((user) => {
                        // Check if the user is type carer
                        if(user.backendUserAuthorities.length === 0){
                          this.logout()
                          store.commit('auth/AUTH_ERROR', 'userNotTypeCarer')
                        }
                        else{
                          // Login XMPP
                          this.$store.dispatch('xmpp/XMPP_LOGIN', {
                            username: this.username,
                            password: this.password,
                            tenant: this.selectedTenant,
                            context: this,
                            user: user
                          }).then(() => {
                            logger.log("logged in")
                            if(user.tenant){
                              if(user.tenant.enableIoT){ // Loggin to iot services
                                if(user.customerUserId) this.thingsboardLogin(this.username,this.password)
                                else{
                                    this.$dialog.confirm({
                                      title:'Funcionalidad IoT desactivada para su usuario',
                                      text:'Funcionalidad IoT desactivada. Consulte con Administracion. Gracias' ,
                                      persistent: true,
                                      actions: {
                                        true: {
                                          color: 'black',
                                          text: 'ok',
                                          handle: () => {
                                            router.push('/SeniorCare/Videollamada')  // and move to the home view
                                          }
                                        }
                                      }
                                    })
                                }
                              }
                              else{
                                router.push('/SeniorCare/Videollamada')  // and move to the home view
                              }
                            }
                          }).catch(err => {
                            this.xmppLoginError = "XMPP Login Error: Unauthorized"
                            logger.log(err)
                          })
                        }
                    }).catch(() => {
                    logger.log("BACKEND_MYSELF Error")
                    })

              }).catch((data) => {
                logger.log(data)
                this.loading = false
              })
            },
          logout () {
            // Login XMPP
            this.$store.dispatch('xmpp/XMPP_LOGOUT', {
            }).then(() => {
              logger.log("log out")
            }).catch(err => {
              logger.log(err)
            })
            this.loading=false
          },
          thingsboardLogin(username,pass){
            this.$store.dispatch('backend/THINGSBOARD_JWT',{username:username,password:pass})
                .then((response) => {
                  logger.log(response)
                  router.push('/SeniorCare/Videollamada') // and move to the home view
                }).catch((error) => {
                  logger.log("IOT logging Error")
                  manageErrors(this,error)
                })
          }
        },
      created () {
            // when entering the login, remove previous
            this.$store.dispatch('auth/CLEAR_TOKEN')
                .then(() => {
                    // if the session was expired
                    if (this.$route.params.expired) {
                        // update the flag
                        this.expired = this.$route.params.expired
                    }
                })
                //Get tenant as param
                if(this.$route.params.tenant) {
                  this.selectedTenant = this.$route.params.tenant
                  this.imageSource= require('@/assets/logo_' + this.selectedTenant + '.png')
                  this.showLockIcon = false
                } else {
                  this.showLogo = true;
                }
        },
        mounted () {
            document.getElementsByTagName('html')[0].classList.add('no-scroll')
        },
        beforeDestroy () {
            // remove the overflow property to allow scroll on other views
            document.getElementsByTagName('html')[0].classList.remove('no-scroll')
        }
    }
</script>

<style>
    .no-scroll {
        overflow: hidden;
    }

    .login-bg {
        background-color:#063442;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3E1D6D', endColorstr='#092756', GradientType=1);
    }

    .box {
        position: relative;
        top: 0;
        opacity: 1;
        float: left;
        padding: 60px 50px 40px 50px;
        width: 100%;
        background: #fff;
        border-radius: 10px !important;
        transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        z-index: 5;
     }

    .box-title {
        line-height: 46px;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 2px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>
