import store from '@/store'
import { Machine, interpret } from 'xstate'
import {EventBus} from '@/bus/bus'
import logger from '@/mixins/logger'

export const videocallService = {
    initMachine,
    transition,
    makeCall,
    callAttempt,
    cancelCall,
    callCancelled,
    rejectCall,
    callRejected,
    endCall,
    callEnded,
    handleMessage,
    handleCarbon,
    callAccepted,
    acceptCall,
    remoteCallAccepted,
    remoteCallEnded,
    remoteCallRejected,
    connectionClosed,
    timeoutCall,
    remoteInvite,
    remoteCancel
}
var machine = null;
var stateActions = null
var service = null;
let isRunning = false

function initMachine() {
    let machineRaw = store.getters['videocall/getMachine']
    if(!machineRaw) {
        logger.log('Machine not configured')
    } else {
        machine = store.getters['videocall/getMachine'] ? Machine(machineRaw.config) : null
        service = interpret(machine).start()
        store.commit('videocall/setInitialize', true)
        isRunning = true
    }
}

function transition() {
    if (isRunning){
        let current = store.getters['videocall/getCurrentState']
        return transitionMachine(current, ...arguments)
    }
}

function transitionMachine(current) {

    let args = arguments

    let eventType = ''
    let params = {}

    if(args.length > 0)
      eventType = args[1]

    if(args.length > 1)
      params = args[2]

    const nextState = machine.transition(current, eventType);

    nextState.actions.forEach(actionKey => {
        store.dispatch('videocall/'+actionKey, {
            eventType,
            params,
            history: nextState.history
        });
    });

    store.commit('videocall/setCurrentState', nextState.value)
} 

//Emitimos un INVITE
function makeCall(data) {
    //Poner estado a Comunicando
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', { show:'chat',})

    //Enviar INVITE
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: data.eventType.contact,
        body: {
            action: 'INVITE',
            body: 'Llamada entrante',
            roomId: data.eventType.roomId
        }
    })

    //Guardar nueva llamada
    store.commit('videocall/SEND_CURRENT_CALL', {jid: data.eventType.contact, roomId: data.eventType.roomId})

    // Des-Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: true})

    //Timeout de 30 segundos
    /*
    setTimeout(function(){
        //store.commit('videocall/transition', "CANCEL_CALL");
        store.commit('videocall/transition', "TIMEOUT_CALL");
        EventBus.$emit('entryHangupDialogClose');
        store.dispatch('xmpp/XMPP_SEND_PRESENCE', null);
    }, process.env.VUE_APP_CALL_TIMEOUT);
    */
}

//Respondemos a un INVITE
function callAttempt(data) {

    //Poner estado a Comunicando
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', { show:'chat',})

    //Enviar RINGING
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: data.eventType.message.jid,
        body: {
            action: 'RINGING',
            body: 'Sonando',
            roomId: data.eventType.message.message.roomId
        }
    })

    //Guardar nueva llamada
    store.commit('videocall/SEND_CURRENT_CALL', {jid: data.eventType.message.jid, roomId: data.eventType.message.message.roomId})

    //Enviar evento al bus
    let contact = store.getters['xmpp/getContactByJid'](data.eventType.message.jid)
    logger.log('callAttempt')
    EventBus.$emit('dialog', {params: {type: 'receivedCall', contact: contact}});

    // Des-Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: true})
}

//Emitimos un CANCEL
function cancelCall() {

    let currentCall = store.getters['videocall/getCurrentCall']

    //Enviar CANCEL
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'CANCEL',
            body: 'Llamada cancelada',
            roomId: currentCall.roomId
        }
    })

    //Paramos audio
    EventBus.$emit('stopAudio');

    //EventBus.$emit('callCancelledAndLeaveRoom');
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', null);

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    // Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})
}

//Emitimos un CANCEL para el caso de que se cumpla un TIMEOUT
function timeoutCall() {
    EventBus.$emit('timeoutCallAndLeaveRoom');
}

//Respondemos a un CANCEL
function callCancelled() {
    let currentCall = store.getters['videocall/getCurrentCall']

    //Enviar OK
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'OK',
            body: 'OK',
            roomId: currentCall.roomId
        }
    })

    //Cambiamos estado a Disponible
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', null)

    //Paramos audio
    EventBus.$emit('stopAudio')

    //Cerramos modal
    EventBus.$emit('entryCallDialogClose')

    //Enviamos notificación de llamada cancelada
    let contact = store.getters['xmpp/getContactByJid'](currentCall.jid)
    var params= {
        type: 'cancel',
        contact: contact
    }

    EventBus.$emit('notification', {params});

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    //Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})
}

//Emitimos un REJECT
function rejectCall() {
    let currentCall = store.getters['videocall/getCurrentCall']

    //Enviar REJECT
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'REJECT',
            body: 'Llamada rechazada',
            roomId: currentCall.roomId
        }
    })

    //Enviar REMOTE_REJECT_CALL
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'REMOTE_REJECT_CALL',
            body: 'Llamada rechazada',
            roomId: currentCall.roomId
        }
    })

    //Paramos audio
    EventBus.$emit('stopAudio')

    // Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    //Presencia
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', null);
}

//Respondemos a un REJECT
function callRejected() {
    let currentCall = store.getters['videocall/getCurrentCall']

    //Enviar OK
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'OK',
            body: 'OK',
            roomId: currentCall.roomId
        }
    })

    //Cambiamos estado a Disponible
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', null)

    //Paramos audio
    EventBus.$emit('stopAudio')

    //Enviamos evento para modal al bus
    let contact = store.getters['xmpp/getContactByJid'](currentCall.jid)
    var params= {
        type: 'rejectedCall',
        contact: contact
    }
    EventBus.$emit('dialog', {params});

    //Cerramos pantalla de Jitsi
    EventBus.$emit('callRejectedAndLeaveRoom');

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    // Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})
}

//Emitimos un BYE
function endCall() {

    let currentCall = store.getters['videocall/getCurrentCall']

    //Enviar BYE
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'BYE',
            body: 'Llamada terminada',
            roomId: currentCall.roomId
        }
    })

    //Enviar REMOTE_BYE
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'REMOTE_BYE',
            body: 'Llamada terminada',
            roomId: currentCall.roomId
        }
    })

    // Disponible
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', null)

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    // Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})
}

//Recibimos un BYE
function callEnded() {

    let currentCall = store.getters['videocall/getCurrentCall']

    //Enviar OK
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'OK',
            body: 'OK',
            roomId: currentCall.roomId
        }
    })

    //Cambiamos estado a Disponible
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', null)

    //Emitimos al bus un llamada terminada
    let contact = store.getters['xmpp/getContactByJid'](currentCall.jid)
    var params= {
        type: 'bye',
        contact: contact
    }
    EventBus.$emit('notification', {params})

    //Cerramos ventana de Jitsi
    EventBus.$emit('callEndedAndLeaveRoom')

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    // Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})
}

//Emitimos un ACCEPT
function acceptCall() {
    let currentCall = store.getters['videocall/getCurrentCall']

    //Enviar ACCEPT
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'ACCEPT',
            body: 'Llamada aceptada',
            roomId: currentCall.roomId
        }
    })

    //Enviar REMOTE_ACCEPT_CALL
    store.dispatch('xmpp/XMPP_SEND_MESSAGE', {
        jid: currentCall.jid,
        body: {
            action: 'REMOTE_ACCEPT_CALL',
            body: 'Llamada terminada',
            roomId: currentCall.roomId
        }
    })

    //Paramos el audio
    EventBus.$emit('stopAudio')

    //Bloquea carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: true})

    // Des-Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: true})

    // Estado comunicando
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', { show:'chat',})
}

//Recibimos un ACCEPT
function callAccepted() {
    // paramos los audios
    EventBus.$emit('stopAudio')

    // Presencia
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', { show:'chat',})

    // Des-Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: true})
}

function remoteCallAccepted () {
    //Cerramos el Modal
    EventBus.$emit('entryCallDialogClose')

    // Des-Habilitamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: true})

    // Presencia
    store.dispatch('xmpp/XMPP_SEND_PRESENCE', { show:'chat',})

    //Paramos los audios
    EventBus.$emit('stopAudio')
}

function remoteCallEnded () {
    //Activamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    // Presencia
    store.dispatch('xmpp/XMPP_SEND_PRESENCE',null)
}

function remoteInvite () {
    //Desactivamos tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: true})
}

function remoteCancel () {
    //Desactivamos tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})
}

//Recibimos un REMOTE REJECT
function remoteCallRejected () {
    // Cerramos el modal
    EventBus.$emit('entryCallDialogClose')

    // Paramos el Audio
    EventBus.$emit('stopAudio')

    //Activamos las tarjetas
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})

    // Presencia
    store.dispatch('xmpp/XMPP_SEND_PRESENCE',null)
}

//Recibimos desconexión de la otra parte
function connectionClosed () {
    //Habilita Cards
    store.commit('videocall/SET_CARDS_DISABLED', {cardsDisabled: false})

    //Cierra ventana de Jitsi y para tono de llamada
    EventBus.$emit('connectionClosedAndLeaveRoom')

    // Paramos el audio
    EventBus.$emit('stopAudio')
    
    //Cierra modal
    EventBus.$emit('entryCallDialogClose')

    // Reseteamos la llamada actual
    store.commit('videocall/RESET_CURRENT_CALL')

    //Desbloqueamos los carbons
    store.commit('videocall/SET_ACTIVE_CALL', {activeCall: false})
}

function handleCarbon (data) {
    logger.log("handleCarbon", data)
    if(data.body.action === 'INVITE') store.commit('videocall/transition', 'REMOTE_INVITE')
    if(data.body.action === 'CANCEL') store.commit('videocall/transition', 'REMOTE_CANCEL')
    if(data.body.action === 'BYE') store.commit('videocall/transition', 'REMOTE_CALL_ENDED')
    if(data.body.action === 'ACCEPT') store.commit('videocall/transition', 'REMOTE_CALL_ACCEPTED')
    if(data.body.action === 'REJECT') store.commit('videocall/transition', 'REMOTE_CALL_REJECTED')
}


//Manejador de mensajes entrantes
function handleMessage (data) {
    //jid
    //message: { action, roomId, body}
    //type: direct/carbon
    logger.log("handleMessage", data)
    let room = store.getters['videoCall/getCurrentCall']
    if (!room || room.roomId === data.message.roomId) {    
        if(data.message && data.message.action && data.type === 'direct') {
            switch (data.message.action) {
                case 'INVITE':
                    store.commit('videocall/transition',{ type: 'CALL_ATTEMPT', message: data })
                    break
                case 'ACCEPT':
                    store.commit('videocall/transition','CALL_ACCEPTED')
                    break
                case 'CANCEL':
                    store.commit('videocall/transition','CALL_CANCELLED')
                    break
                case 'REJECT':
                    store.commit('videocall/transition','CALL_REJECTED')
                    store.commit('videocall/transition','REMOTE_CALL_REJECTED')
                    break
                case 'BYE':
                    logger.log('BYE', data.message)
                    store.commit('videocall/transition','CALL_ENDED')
                    store.commit('videocall/transition','REMOTE_CALL_ENDED')
                    break
                case 'OK':
                    store.dispatch('xmpp/XMPP_SEND_PRESENCE', null)
                    break
                case 'REMOTE_ACCEPT_CALL':
                    store.commit('videocall/transition','REMOTE_CALL_ACCEPTED')
                    break
            }
        } else {
            logger.log('Error: Estructura de mensaje no compatible', data.message)
        }
    } else {
        logger.log('Error: Mensaje descartado correspondiente a otra sesión', data.message)
    }
}
