import {
  BACKEND_MYSELF,
  BACKEND_JWTTOKEN,
  BACKEND_USERTYPESLIST,
  THINGSBOARD_JWT,
  REGISTER_CALL,
  REGISTER_XMPP_STATUS
} from '@/store/actions/backend'
import { backendService } from '@/services/backend.service'
import store from "@/store";
//import router from '@/router'

/**
 * States for auth
 */
const state = {
  myself: null,
  jwt: null,
  userTypesList:null,
  thingsboardJWT: null
}

/**
 * Getters for auth
 */
const getters = {
  myself: state => state.myself,
  jwt: state => state.jwt,
  userTypesList: state => state.userTypesList,
  thingsboardJWT: state => state.thingsboardJWT
}

/**
 * Actions for auth
 */
const actions = {
  /**
   * Executes the login of an user
   */
  [BACKEND_MYSELF] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      backendService.getMySelf()
        .then(response => {
            state.myself = response.data
            // Cambio de titulo de la pestaña y favicon dependiendo del tenant del usuario logeado
            if(store.getters['backend/myself'].tenant.tenantFriendlyName) document.title = store.getters['backend/myself'].tenant.tenantFriendlyName + " WebAdmin";
            if(store.getters['backend/myself'].tenant.tenantLogo){
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = store.getters['backend/myself'].tenant.tenantLogo;
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            //
            resolve(response.data)
        }).catch(err => {
            reject(err)
        })
    })
  },

  [BACKEND_JWTTOKEN] ({commit, state, dispatch}, data) {
      return new Promise((resolve, reject) => {
          backendService.getJWTToken()
            .then(response => {
                state.jwt = response.data
                resolve(response.data)
            })
            .catch(err => {reject(err)})
      })
  },

  [BACKEND_USERTYPESLIST] ({commit, state, dispatch}, data) {
    return new Promise((resolve, reject) => {
        backendService.getUserTypesList()
            .then(response => {
                state.userTypesList = response.data
                resolve(response.data)
            })
            .catch(err => {reject(err)})
    })
  },

  [THINGSBOARD_JWT] ({commit, state, dispatch}, data) {
    return new Promise((resolve, reject) => {
        backendService.thingsboardLogin({username:data.username,password:data.password})
            .then(response => {
                if(response.data){
                    if(response.data.token)  state.thingsboardJWT = response.data.token // Token principal
                }
                resolve(response.data)
            })
            .catch(err => {reject(err)})
    })
  },

  [REGISTER_CALL] ({commit,state}, data) {
    backendService.registerCall(data)
  },

  [REGISTER_XMPP_STATUS] ({commit,state}, data) {
    backendService.registerXmppStatus(data)
  }
}

const mutations = {
}

export default {
  namespaced: true,   
  state,
  getters,
  actions,
  mutations
}
