<template>
  <v-footer
    color="#063442"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-menu :close-on-content-click="true"
              :close-on-click="true"
              bottom offset-y
              auto
              v-for="menu in buildMenu"
              :key="menu.title">
        <template v-slot:activator="{ on }">
          <v-btn
              v-if="!menu.father"
              v-on="on"
              color="white"
              text
              rounded
              class="my-2"
              @click="gestionar(menu)">
            <v-icon size="24px">
              {{ menu.icon }}
            </v-icon>
            {{ menu.title }}
          </v-btn>
          <v-btn
              v-if="menu.father"
              v-on="on"
              color="white"
              text
              rounded
              class="my-2">
            <v-icon size="24px">
              {{ menu.icon }}
            </v-icon>
            {{ menu.title }}
          </v-btn>
        </template>
        <v-list  v-for="(subMenu) in menu.subMenu" :key="subMenu.title" style="background-color: #063442;" elevation="0">
          <v-list-item>
            <v-btn
                elevation="0"
                v-on="on"
                color="#063442"
                dense
                class="white--text"
                @click="gestionar(subMenu)">
              <v-icon size="24px">
                {{ subMenu.icon }}
              </v-icon>
              {{ subMenu.title }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
  </v-footer>
</template>


<script>
/*import { menuService } from '@/services/menu.service'*/
import store from "@/store"
import router from '@/router'
import {EventBus} from '@/bus/bus'


export default {
  name: 'TopMenu',
  data () {
    return {
      items: [
        {icon: 'mdi-video', title:'VIDEOLLAMADA', path:'/SeniorCare/Videollamada',father:false, subMenu:[]},
        {icon: 'mdi-laptop-mac', title:'CANALES Y CONTENIDOS', path:'/SeniorCare/Contenidos',father:false, subMenu:[]},
        {icon: 'mdi-hammer-screwdriver', title:'HOGARES y USUARIOS', path:'/SeniorCare/UsersManagment',father:true, subMenu:[
            {icon: 'mdi-home-group', title:'GESTION DE HOGARES', path:'/SeniorCare/HomesManagement'},
            {icon: 'mdi-account-group', title:'GESTION DE USUARIOS', path:'/SeniorCare/UsersManagement'}
          ]},
        {icon: 'mdi-cog', title:'CONFIGURACION', path:'',father:false, subMenu:[]}
      ]
    }
  },
  methods: {
    gestionar(menu) {
      if (router.history.current.path !== menu.path) {
        if (store.getters['videocall/getCurrentCall'] != null) {
          var params = []
          params.type = "hangup"
          params.title = "Llamada en curso"
          params.body = "No es posible cambiar de pestaña durante una llamada. ¿Desea terminar la llamada en curso?"
          params.menu = menu.path
          EventBus.$emit('dialog', {params})
        } else {
          router.push(menu.path)
        }
      }
    }
  },
  mounted () {
  },
   computed: {
     appTitle () {
      return this.$t('lAppTitle')
    },
   buildMenu(){
       let roles = []
       let menu  = []
      if(store.getters['backend/myself']){

        roles=store.getters['backend/myself'].backendUserAuthorities
        let enableIoT = store.getters['backend/myself'].tenant.enableIoT
        let customerUserId = store.getters['backend/myself'].customerUserId

        for (let i = 0; i < roles.length; i++) {
          if(roles[i].rol==='USUARIO') menu.push( {icon: 'mdi-video', title:'VIDEOLLAMADA', path:'/SeniorCare/Videollamada',father:false, subMenu:[]},)
          if(roles[i].rol==='PROFESIONAL'){
            if(menu.findIndex(i => i.title === "CANALES Y CONTENIDOS") === -1){
              menu.push({icon: 'mdi-laptop-mac', title:'CANALES Y CONTENIDOS', path:'/SeniorCare/Contenidos',father:false, subMenu:[]})
            }
            if(menu.findIndex(i => i.title === "CUADROS DE MANDO") === -1){
              if(enableIoT && customerUserId){
                menu.push({icon: 'mdi-monitor-dashboard', title:'CUADROS DE MANDO', path:'/SeniorCare/IOT',father:false, subMenu:[]})
              }
            }
          }
          if(roles[i].rol==='ADMINISTRADOR'){
            if(menu.findIndex(i => i.title === "CANALES Y CONTENIDOS") === -1){
              menu.push({icon: 'mdi-laptop-mac', title:'CANALES Y CONTENIDOS', path:'/SeniorCare/Contenidos',father:false, subMenu:[]})
            }
            menu.push(
                {icon: 'mdi-hammer-screwdriver', title:'HOGARES y USUARIOS', path:'/SeniorCare/UsersManagment',father:true, subMenu:[
                    {icon: 'mdi-home-group', title:'GESTION DE HOGARES', path:'/SeniorCare/HomesManagement'},
                    {icon: 'mdi-account-group', title:'GESTION DE USUARIOS', path:'/SeniorCare/UsersManagement'}
                  ]}
            )
            if(menu.findIndex(i => i.title === "CUADROS DE MANDO") === -1){
              if(enableIoT && customerUserId){
                menu.push({icon: 'mdi-monitor-dashboard', title:'CUADROS DE MANDO', path:'/SeniorCare/IOT',father:false, subMenu:[]})
              }
            }
          }
        }

      }
    return menu
   }
 }
}
</script>
<style>
</style>