<template>
  <v-dialog
      v-model="dialogListUsers"
      persistent
      max-width="1000px"
      hide-overlay
      transition="dialog-bottom-transition">
    <div>
      <v-card>
        <div class="mt-5 pl-5" v-if=this.pageTitle>
          <h1>{{this.pageTitle}}</h1>
        </div>
        <!-- Card con el filtro de búsqueda y el botón de alta -->
        <v-card-title>
          <v-text-field
              v-model="search"
              class="mr-6"
              append-icon="mdi-magnify"
              :label="$t('lFilter')"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <!-- Tabla -->
        <v-data-table
            :headers="headers"
            :items="usersTableData"
            :loading="this.users.length === 0 && (!finished)"
            :loading-text="$t('lLoading')"
            sort-by="idUser"
            :search="search"
        >
          <!-- Personalizacion de campo tipo de usuario -->
          <template v-slot:[`item.backendUserTypeId`]="{ item }">
            <span>
              {{ item.backendUserTypeId | formatTypes }}
              <br>
            </span>
          </template>
          <!-- Personalizacion del mensaje de no hay datos disponibles -->
          <template slot="no-data">
            <v-alert :value="true" color="" icon="warning">
              No existen datos disponibles
            </v-alert>
          </template>
          <!-- footer de la tabla-->
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} {{$t('lFooterOf')}} {{props.itemsLength}}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="save"
          >
            {{ $t('bAccept') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { backendService } from '@/services/backend.service'
import {i18n} from "@/plugins/i18n";
import logger from '@/mixins/logger'

export default {
  name:'UserList',
  props: {
    dialogListUsers: {
      type: Boolean,
      default: false
    },
    selectedHome:  {
      type: Number
    }
  },
  data () {
    return {
      users: [],
      finished:true,
      search: '',
      footerProps: {
        'items-per-page-text': this.$t('lRowsPerPage'),
        'items-per-page-all-text':this.$t('lAll'),
        'items-per-page-options': [5, 10, 20,-1]
      },
      selected:-1,
      defaultUser: [],
      headers: [
        { text: this.$t('lCode'), value: 'idUser', align: 'center', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lUser'), value: 'user', align: 'start', sortable: true, filterable: true, width: '25%' },
        { text: this.$t('lUserType'), value: 'backendUserTypeId', align: 'center', sortable: true, filterable: true, width: '15%' },
        { text: this.$t('lHome'), value: 'homes', align: 'center', sortable: true, filterable: true, width: '25%' }
      ],
    }
  },
  methods: {
    init () {
      if(this.selected){
        this.finished=false
        backendService.getUserListByHomeId(this.selected)
            .then((response) => {
              this.users = response.data
              this.finished=true
            })
            .catch((error) => {
              logger.log(error)
              this.finished=true
            })
      }
    },
    cancel(){
      this.$emit('cancel')
    },
    save(){
      this.$emit('save')
    },
    getHomesFromUser(homes){
      if(homes.length>1) return "Multiple"
      else{return homes[0].homeName}
    }
  },
  computed: {
    pageTitle () {
      return this.$t('lSeeUser')
    },
    usersTableData() {
      return this.users.map(e => {
        return {
          idUser: e.idUser,
          user: e.user,
          backendUserTypeId: e.backendUserTypeId,
          homes:this.getHomesFromUser(e.backendUserHomes),
        };
      });
    },
  },
  watch: {
    selectedHome: function(){
      this.selected = this.selectedHome
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  filters: {
    formatBoolean (boolean) {
      switch (boolean) {
        case true:
          return i18n.t('lYes')
        case false:
          return i18n.t('lNo')
        default:
          return i18n.t('lNo')
      }
    },
    formatTypes (type) {
      switch (type) {
        case 'FAMILY':
          return i18n.t('lFAMILY')
        case 'PATIENT':
          return i18n.t('lPATIENT')
        case 'CARER':
          return i18n.t('lCARER')
        case 'FRIEND':
          return i18n.t('lFriend')
        default:
          return type
      }
    }
  },
}
</script>
