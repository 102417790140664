<template>
  <v-container fluid class="pa-0" style="background:#063442">
    <v-app-bar fixed color="#063442" min-height="85px" class="pt-3">
      <div class="d-none d-sm-none d-md-flex">
        <v-img
            v-if="this.$store.getters['backend/myself'].tenant.tenantLogo"
            width="100px"
            height="100px"
            :src="this.$store.getters['backend/myself'].tenant.tenantLogo">
        </v-img>
        <v-img
            v-else
            style="border-radius: 50%;"
            width="40px"
            height="40px"
            :src="require('@/assets/logo.png')">
        </v-img>
      </div>
      <row>
        <v-toolbar-title
            v-if="this.$store.getters['backend/myself'].tenant.tenantFriendlyName"
            style="margin-left: 3px"
            class="white--text d-none d-sm-none d-md-flex"
        >
          {{this.$store.getters['backend/myself'].tenant.tenantFriendlyName}}
        </v-toolbar-title>
        <v-toolbar-title
            v-else
            style="margin-left: 3px"
            class="white--text d-none d-sm-none d-md-flex"
        >
          process.env.VUE_APP_TITLE
        </v-toolbar-title>
        <!--<h6 class="white--text ml-2">{{appVersion}}</h6>-->
      </row>
      <v-navigation-drawer v-model="drawer" app temporary absolute color="#063442">
        <LateralMenu></LateralMenu>
      </v-navigation-drawer>
      <v-app-bar-nav-icon class="hidden-sm-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <TopMenu class="hidden-md-and-down"></TopMenu>
      <v-spacer></v-spacer>
      <!-- Avatar     -->
      <v-avatar
          class="ma-0"
          width="100%" height="auto" max-height="50" max-width="50"
          :color="getAvatarColor(this.$store.state.xmpp.myVCard.nickname)">
        <v-img v-if="this.$store.state.xmpp.myVCard.url !== 'NA'"   width="100%" height="auto" max-height="50" max-width="50"  :src="this.$store.state.xmpp.myVCard.url"  class="ma-0 pa-0"></v-img>
        <h1 v-else class="white--text" style="font-size: 2em;font-weight:normal">{{this.$store.state.xmpp.myVCard.nickname.substring(0,1)}}</h1>
      </v-avatar>
      <!-- Menu Estado -->
      <div v-show="this.$store.state.xmpp.myVCard.nickname" class="text-center" >
        <v-menu
            open-on-hover
            bottom
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                outlined
                class="ma-0"
                color="#063442"
                v-bind="attrs"
                v-on="on"
            >
            <v-icon  :color=backgroundClassComputed >mdi-checkbox-blank-circle</v-icon>
            </v-btn>

          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
            >
              <v-list-item-title @click="setStatus(item.status)"><v-icon :color="item.iconBackground" class="ma-1">mdi-checkbox-blank-circle </v-icon>{{item.status | formatText}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- username     -->
      <h4 class="hidden-sm-and-down" style="color:white">{{ this.$store.state.xmpp.myVCard.nickname }}</h4>
      <v-btn icon @click="logout()" style="color:white">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar min-height="85px" class="pt-3" dark>
    </v-app-bar>
    <main>
      <!-- En este area se pintaran las redirecciones que lancemos-->
      <router-view></router-view>
    </main>
  </v-container>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import LateralMenu from '@/components/LateralMenu.vue'
import { i18n } from '@/plugins/i18n'
import {EventBus} from '@/bus/bus'
import callDialog from '@/components/CallDialog'
import hangupDialog from '@/components/HangupDialog'
import router from "@/router";
import store from "@/store";
import {mapState} from "vuex";
import {backendService} from "@/services/backend.service";
import logger from '@/mixins/logger'

let sendinCallAudio = require("@/assets/calling_tone.ogg");
let recievingCallAudio = require("@/assets/ringing.ogg");
let audioSendinCal = new Audio(sendinCallAudio);
audioSendinCal.loop = true;
let audioRecievingCall = new Audio(recievingCallAudio);
audioRecievingCall.loop = true;

export default {
  name: 'dashboard',
  components: {
    TopMenu, 
    LateralMenu
  },
  data () {
    return {
      drawer: false,
      user: this.$store.state.xmpp.myVCard.fullName,
      avatar: this.$store.state.xmpp.myVCard.url,
      status: this.userStatus,
      items: [
        { status:  'available', iconBackground:'green'},
        { status:  'away',iconBackground:'red'},
      ],
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    logout () {
      // Login XMPP
      this.$store.dispatch('xmpp/XMPP_LOGOUT', {
      }).then(() => {
        logger.log("log out")
      }).catch(err => {
        logger.log(err)
      })
      this.$store.dispatch('auth/AUTH_LOGOUT', true)
    },
    getAvatarColor(nickName){
      let avatarBackgroundPalette=["#3E7690","#8064A7","#49198D","#083545"]
      if (nickName){
        let nickNameSize = nickName.length
        return avatarBackgroundPalette[nickNameSize % avatarBackgroundPalette.length]
      }
      else{
        return 'indigo'
      }
    },
    setStatus(status) {
      if(!this.cardsDisabled){
        logger.log(status)
        // Setear el estado en el navbar
        store.commit('videocall/SET_USER_STATUS', {userStatus: status})
        switch (status) {
          case 'away':
            this.$store.dispatch('xmpp/XMPP_SEND_PRESENCE', {
              show:'away',
            }).then(() => {
              logger.log("Cambiado el estado")
            }).catch(err => {
              logger.log(err)
            })
            break
          default:
            this.$store.dispatch('xmpp/XMPP_SEND_PRESENCE', {
            }).then(() => {
              logger.log("Cambiado el estado")
            }).catch(err => {
              logger.log(err)
            })
        }
      }
    },
    // Mostrar notificacion
    showNotification: function (notificacion){
      switch (notificacion.type) {
        case 'cancel':
          this.$dialog.notify['warning']('Llamada entrante cancelada por el contacto ' + notificacion.contact.nickname, {
            position: 'top-right',
            timeout: 10000,
            color:'DBA901'
          });
          break
        case 'bye':
          this.$dialog.notify['warning']('Llamada finalizada por el contacto ' + notificacion.contact.nickname, {
            position: 'top-right',
            timeout: 10000,
            color:'084B8A'
          });
          break
        case 'rejectCall':
          this.$dialog.notify['warning'](notificacion.contact.nickname +': llamada rechazada', {
            position: 'top-right',
            timeout: 10000,
            color:'DBA901'
          });
          break
        case 'calling':
          this.$dialog.notify['warning'](('Llamando al contacto: ' + notificacion.contact.nickname ), {
            position: 'top-right',
            timeout: 10000,
            color:'#3E7690'
          });
          this.playOutgoingAudio()
          break
        case 'lostCall':
          this.$dialog.notify['warning'](('Llamada perdida de : ' + notificacion.contact.nickname ), {
            position: 'top-right',
            timeout: 10000,
            color:'#DF0101'
          });
          break
        default:
      }
    },
    // Mostrar modal
    showModal: function (message){
      logger.log('hangup', message)
      switch (message.type) {
        case 'receivedCall':
          this.playIncomingAudio()
          var params=[]
          params.persistent=true
          this.$dialog.showAndWait(callDialog,params)
          message.title='Llamada Entrante'
          EventBus.$emit('entryCallDialogOpen', {message});
          break
        case 'hangup':
          var param=[]
          param.persistent=true
          this.$dialog.showAndWait(hangupDialog,param)
          //message.title=params.title
          //message.body=params.body
          //message.menu=params.menu
          EventBus.$emit('entryHangupDialogOpen', {message});
          break
        case 'unavailable':
          this.$dialog.confirm({
            title: 'Contacto no disponible',
            text:'El contacto '+ message.contact.nickname + ', no esta disponible en este momento' ,
            persistent: true,
            actions: {
              true: {
                color: 'black',
                text: 'Aceptar',
              }
            }
          })
          break
        case 'rejectedCall':
          this.$dialog.confirm({
            title: 'Llamada Rechazada',
            text:'El contacto '+ message.contact.nickname + ', ha rechazado la llamada' ,
            persistent: true,
            actions: {
              true: {
                color: 'black',
                text: 'ok',
              }
            }
          })
          break
        default:
      }
    },
    // Reconnection XMPP
    connectXmpp(){
      if(this.credentials !== null){
        // get backend myself
        this.$store.dispatch('backend/BACKEND_MYSELF',{})
            .then((user) => {
              // Login XMPP
              this.$store.dispatch('xmpp/XMPP_LOGIN', {
                password: this.credentials.password,
                context: this,
                user: user
              }).then(() => {
                logger.log("videollamada: logged in xmpp")
                logger.log("logged in")
              }).catch(err => {
                this.xmppLoginError = "XMPP Login Error: Unauthorized"
                this.$store.reset()
                router.push({ name: 'Login'}).then()
                logger.log(err)
              })
            }).catch((data) => {
          logger.log(data)
          logger.log("BACKEND_MYSELF Error")
        })
      }
    },
    // State Machine
    initializeStateMachine(){
      let statusInitialize = store.getters['videocall/getInitialize']
      if(!statusInitialize){
        this.$store.commit('videocall/initMachine')
      }
    },
    rejectIncomingCall(){
      this.$store.commit('videocall/transition', 'REJECT_CALL')
    },
    acceptIncomingCall(){
      this.$store.commit('videocall/transition', 'ACCEPT_CALL')
      if(router.currentRoute.path === '/SeniorCare/Videollamada') {
        EventBus.$emit('incomingCall')
      } else {
        router.push('/SeniorCare/Videollamada').then(() => {
          EventBus.$emit('incomingCall')
        }) 
      }
    },
    hangupCall(received){
      var params = []
      params.menu = received.params.menu
      EventBus.$emit('hangupCall', {params})
    },
    // Audios
    playOutgoingAudio(){
      audioSendinCal.play();
    },
    playIncomingAudio(){
      audioRecievingCall.play();
    },
    stopOutgoingAudio(){
      audioSendinCal.pause();
      audioSendinCal.currentTime = 0;
    },
    stopIncomingAudio(){
      audioRecievingCall.pause();
      audioRecievingCall.currentTime = 0;
    },
    //Loading Jitsi Library
    loadScript (src, cb) {
      const scriptEl = document.createElement('script');
      scriptEl.src = src;
      scriptEl.async = 1;
      document.querySelector('head').appendChild(scriptEl);
      scriptEl.addEventListener('load', cb);
    },
    sendWakeUpNotification(contact){
      let jabberIdTo = contact.jid.split('@')[0];
      let title = i18n.t('nWakeUpTitle');
      let notifBody = i18n.t('nWakeUpBody');

      // call logic
      let dt = new Date().getTime();
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          let r = (dt + Math.random()*16)%16 | 0
          dt = Math.floor(dt/16)
          return (c=='x' ? r :(r&0x3|0x8)).toString(16)
      });

      backendService.sendNotification(jabberIdTo,title,notifBody,uuid,"high",false).then((response) => {
        logger.log("Notificacion enviada con exito", response)
        EventBus.$emit('makeCall', {contact: contact, roomId: uuid})
      }).catch(err => {
        logger.log(err)
        //
        let errorMessage= "Se ha producido un error"

        if(err.response){
          if(err.response.data.errorKey === '1019') errorMessage='No se ha podido enviar la notificación al usuario seleccionado. El usuario no tiene token.'
          if(err.response.data.errorKey === '1011') errorMessage='No se ha podido enviar la notificación al usuario seleccionado. El usuario tiene un token inválido.'
        }

        this.$dialog.confirm({
          title: '¡Atencion!',
          text: errorMessage ,
          persistent: true,
          actions: {
            true: {
              color: 'black',
              text: 'OK',
              handle: () => {
                logger.log('Se ha producido un error')
              }
            }
          }
        })
        //
      })
    }
  },
  filters: {
    formatText (status) {
      switch (status) {
        case 'available':
          return i18n.t('available')
        case 'away':
          return i18n.t('away')
        default:
          return i18n.t('away')
      }
    }
  },
  computed:{
    backgroundClassComputed () {
      switch (this.userStatus) {
        case 'available':
          return 'green'
        case 'away':
          return 'red'
        default:
          return 'red'
      }
    },
    ...mapState({
      credentials: state => state.xmpp.credentials,
      cardsDisabled: state => state.videocall.cardsDisabled,
      userStatus:state => state.videocall.userStatus,
    }),
    appVersion() {
      return 'v' + process.env.VUE_APP_VERSION;
    }
  },
  mounted() {
    this.connectXmpp();
    this.initializeStateMachine()
    this.loadScript('https://8x8.vc/external_api.js', () => {
      if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded')
      //this.loadJitsiApi()
    })
  },
  created() {
    // Listeners de eventos

    // Para notificaciones:
    EventBus.$on('notification',(received)=>{
      logger.log('Notificacion recibida')
      this.showNotification(received.params)
    })
    // Para modales
    EventBus.$on('dialog',(received)=>{
      this.showModal(received.params)
    })
    // Para los sonidos
    EventBus.$on('stopAudio', (received)=>{
      this.stopIncomingAudio()
      this.stopOutgoingAudio()
    })

    // Cuando se cierra el modal de recibiendo llamada entrante
    EventBus.$on('entryCallDialogSubmit',(received)=>{
      if(received.params.callStatus === 'reject') this.rejectIncomingCall()
      if(received.params.callStatus === 'accept') this.acceptIncomingCall()
    })

    // Cuando se cierra el modal de advertencia de llamada en curso
    EventBus.$on('entryHangupDialogSubmit', (received)=>{
      if(received.params.callStatus === 'hangup') this.hangupCall(received)
    })
  },
  beforeDestroy() {
    EventBus.$off('dialog')
    EventBus.$off('notification')
    EventBus.$off('entryCallDialogSubmit')
    EventBus.$off('entryHangupDialogSubmit')
    EventBus.$off('stopAudio')
  }
}
</script>
<style>
</style>
