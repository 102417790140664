<template>
  <div>
    <div class="ma-5" >
      <v-card tile elevation="4">
        <!-- Card con el filtro de búsqueda y el botón de alta -->
        <v-card-title>
          <v-text-field
              v-model="search"
              class="mr-6"
              append-icon="mdi-magnify"
              :label="$t('lFilter')"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
              right
              hide-details
              color="primary"
              @click="addItem()"
          >{{ $t('bAdd') }}
          </v-btn>
        </v-card-title>

        <!-- Data table con configuración de cabeceras, elementos, filtro de búsqueda, loading y texto de carga y de footer personalizados-->
        <v-data-table
            :headers="headers"
            :items="usersTableData"
            :search="search"
            :loading="this.userList.length === 0 && (!finished)"
            :loading-text="$t('lLoading')"
            :footer-props="footerProps"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="getBackendUsersPage()"
        >
          <!--         -->
          <!-- Carga de dialog para alta y edición, con texto título y botón personalizado según acción de carga -->
          <template v-slot:top>
            <v-dialog v-model="dialogSave" persistent max-width="3000px">
              <v-form ref="form">
                <v-card tile>
                  <v-card-title>
                    <span class="headline">{{ itemDialogTitle }}</span></v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>{{$t('lUser')}}:</p>
                          <v-text-field
                              :disabled="editedItem.backendUser.idUser!==-1"
                              v-model.trim="editedItem.backendUser.user"
                              type="text"
                              :rules="[rules.maxLength190, rules.email, rules.required]"
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" class="justify-content-center">
                          <p>{{$t('lPassword')}}:</p>
                          <v-row>
                          <v-text-field
                              v-model.trim="editedItem.backendUser.password"
                              type="text"
                              :rules="[rules.maxLength60]"
                              validate-on-blur
                          ></v-text-field>
                          <v-icon
                              color="secondary"
                              class="mr-2"
                              v-on="on"
                              @click="generatePassword"
                          >
                            mdi-lock-reset
                          </v-icon>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>{{$t('lUserType')}}:</p>
                          <v-combobox
                              v-model="editedItem.backendUser.backendUserType"
                              :items="userTypesList"
                              background-color="white"
                              solo
                              :rules="[rules.required, rules.required]"
                              return-object
                              item-text="type"
                              validate-on-blur
                              @change="onChangeUserType()"
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>{{$t('lUserRole')}}:</p>
                          <v-btn
                              rounded
                              style="margin-top: 5px;bottom: 5px;"
                              color="primary"
                              dense
                              @click="dialogSelectRole = !dialogSelectRole"
                          >
                            {{ $t('lUserRoleAssign') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" lg="2" md="2">
                          <p>{{$t('lLiveStreaming')}}:</p>
                          <v-checkbox
                              :disabled="editedItem.backendUser.authorities.findIndex(i => i.rol === 'PROFESIONAL')===-1"
                              @change="onChangeUserRol()"
                              v-model="editedItem.backendUser.liveStreaming"
                              :label="editedItem.backendUser.liveStreaming|formatBoolean"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" lg="2" md="2" >
                          <p>{{$t('lModerator')}}:</p>
                          <v-checkbox
                              :disabled="editedItem.backendUser.authorities.findIndex(i => i.rol === 'PROFESIONAL')===-1"
                              @change="onChangeUserRol()"
                              v-model="editedItem.backendUser.moderator"
                              :label="editedItem.backendUser.moderator|formatBoolean"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" lg="2" md="2" >
                          <p>{{$t('lRecorder')}}:</p>
                          <v-checkbox
                              :disabled="editedItem.backendUser.authorities.findIndex(i => i.rol === 'PROFESIONAL')===-1"
                              @change="onChangeUserRol()"
                              v-model="editedItem.backendUser.recorder"
                              :label="editedItem.backendUser.recorder|formatBoolean"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p> {{ $t('lAssignHomes') }}:</p>
                          <v-btn
                              rounded
                              style="margin-top: 5px;bottom: 5px;"
                              color="primary"
                              dense
                              @click="dialogSelectHomes = !dialogSelectHomes"
                          >
                            {{ $t('lSelectHome') }}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p> {{ $t('lAssignUsersMailPassword') }}:</p>
                          <v-btn
                              rounded
                              style="margin-top: 5px;bottom: 5px;"
                              color="primary"
                              dense
                              @click="dialogSelectUsers = !dialogSelectUsers"
                          >
                            {{ $t('lSelectUser') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <!-- Ejjaberd-->
                      <v-row>
                        <v-col cols="12" sm="12" lg="3" md="3">
                          <p>{{$t('lCompleteName')}}:</p>
                          <v-text-field
                              v-model.trim="editedItem.backendUserVcard.fn"
                              type="text"
                              :rules="[rules.maxLength300, rules.required]"
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3">
                          <p>{{$t('lNickName')}}</p>
                          <v-text-field
                              v-model.trim="editedItem.backendUserVcard.nickname"
                              type="text"
                              :rules="[rules.maxLength300, rules.required]"
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3">
                          <p>{{$t('lPhoto')}}</p>
                          <v-img v-if="editedItem.backendUserVcard.url && editedItem.backendUserVcard.url !== 'NA'" width="80px" height="80px"  :src="editedItem.backendUserVcard.url"  @click="dialogImagenes = !dialogImagenes" class="ma-0 pa-0"></v-img>
                          <v-btn v-else color="primary" @click="dialogImagenes = !dialogImagenes">{{ $t('lSelectImagen') }}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3">
                          <p>{{$t('lPrincipalContact')}}</p>
                          <v-checkbox
                              v-model="editedItem.backendUserVcard.home"
                              :label="editedItem.backendUserVcard.home|formatBoolean"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" lg="2" md="2">
                          <p>{{$t('lContactPhone')}}</p>
                          <v-text-field
                              v-model.trim="editedItem.backendUserVcard.voice"
                              type="text"
                              :rules="[rules.maxLength300]"
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3">
                          <p>{{$t('lAge')}}</p>
                          <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="editedItem.backendUserVcard.age"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  clearable
                                  @click:clear="editedItem.backendUserVcard.age = null"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="editedItem.backendUserVcard.age"
                                :max="new Date().toISOString().substr(0, 10)"
                                min="1900-01-01"
                                locale="es"
                                first-day-of-week="1"
                                @change="saveBirthday"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3">
                          <p>{{$t('lAdress')}}</p>
                          <v-text-field
                              v-model.trim="editedItem.backendUserVcard.address"
                              type="text"
                              :rules="[rules.maxLength300]"
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="2" md="2">
                          <p>{{$t('lLocality')}}</p>
                          <v-text-field
                              v-model.trim="editedItem.backendUserVcard.locality"
                              type="text"
                              :rules="[rules.maxLength60]"
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="2" md="2">
                          <p>{{$t('lPostalCode')}}</p>
                          <v-text-field
                              v-model.trim="editedItem.backendUserVcard.postalCode"
                              type="text"
                              :rules="[rules.maxLength6,rules.numeric]"
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close">{{ $t('bCancel') }}</v-btn>
                    <v-btn color="primary" @click="saveItemConfirm">{{ $t('bSave') }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-form>

              <!-- Overlay and loading in save -->
              <v-overlay v-show="overlay" opacity="0.75">
                <v-container fluid fill-height >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                        indeterminate color="primary">
                    </v-progress-circular>
                  </v-layout>
                </v-container>
              </v-overlay>

            </v-dialog>
            <!--          -->
            <!-- Dialogo para la asignacion de roles a usuarios-->
            <template>
              <v-dialog v-model="dialogSelectRole" persistent max-width="1000px">
                <v-card tile>
                  <v-card-title>
                    <span class="headline"> {{ $t('lUserRoleAssign') }}</span></v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-checkbox
                          v-for="(role) in rolesList"
                          multiple
                          v-model="roleSelection"
                          :key="role.idRol"
                          :value="role"
                          :label="role.rol"
                      />
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="closeRoleSelector">{{ $t('bCancel') }}</v-btn>
                    <v-btn color="primary" @click="acceptRoleSelector">{{ $t('bSave') }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!--          -->
            <!-- Dialogo para la asignacion de usuarios a Hogares-->
            <HomesSelector :dialogSelectHomes.sync="dialogSelectHomes"
                          :selectedHomes.sync="editedItem.backendUser.homes"
                          @cancel="closeHomesSelector"
                          @save="acceptHomesSelector"
                          class="elevation-2" />
          <!--            -->
          <!-- Dialogo para seleccionar usuarios a los que  mandar un correo con la contraseña -->
            <userSelector :dialogSelectUsers.sync="dialogSelectUsers"
                          :selectedUsers.sync="usersSendMail"
                          :selectedFilterUserType.sync="userTypeSelectorFilter"
                          @cancel="closeUserSelector"
                          @save="acceptUserSelector"
                          class="elevation-2" />
          <!--            -->
          <!-- Dialogo para Subir imagenes o añadir imagenes-->
          <template>
            <v-dialog v-model="dialogImagenes" persistent max-width="1000px">
              <v-card tile>
                <v-card-title>
                  <span class="headline"> {{ $t('lImageURL') }}</span></v-card-title>
                <v-card-text>
                  <v-container>
                    <v-flex>
                      <v-text-field
                          v-model.trim="editedItem.backendUserVcard.url"
                          :label="$t('lImageURLExternal')"
                          type="text"
                          :rules="[rules.maxLength300]"
                      ></v-text-field>
                      <v-file-input show-size
                                    counter
                                    chips
                                    accept="image/*"
                                    label="Selecionar Imagen del dispositivo"
                                    ref="myfile" v-model="imageFile">
                      </v-file-input>
                    </v-flex>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="closeImageSelector">{{ $t('bCancel') }}</v-btn>
                  <v-btn color="primary" @click="acceptImageSelector">{{ $t('bSave') }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!--            -->
          </template>
          <!--          -->
          <!-- Personalización de columna de acciones con los botones de edición y eliminar -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="secondary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('ttModify') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="error"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="activateDeactivateConfirmDialog(item)">
                  mdi-account-reactivate
                </v-icon>
              </template>
              <span>{{ $t('ttActivateDeactivate') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="error"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="permanentDeleteConfirmDialog(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t('ttPermanentDelete') }}</span>
            </v-tooltip>
          </template>
          <!-- Personalizacion de campo enable -->
          <template v-slot:[`item.backendUser.enable`]="{ item }">
            <span>
              {{ item.backendUser.enable | formatBoolean }}
              <br>
            </span>
          </template>
          <!-- Personalizacion de la lista de roles -->
          <template v-slot:[`item.backendUser.isAdmin`]="{ item }">
            <span v-if="item.backendUser.authorities.findIndex(i => i.rol === 'ADMINISTRADOR')!==-1">Si</span>
            <span v-else >No</span>
          </template>
          <!-- Personalizacion de campo tipo de usuario -->
          <template v-slot:[`item.backendUser.backendUserTypeId`]="{ item }">
            <span>
              {{ item.backendUser.backendUserType.type | formatTypes }}
              <br>
            </span>
          </template>
          <!-- Personalizacion del mensaje de no hay datos disponibles -->
          <template slot="no-data">
            <v-alert :value="true" color="" icon="warning">
              No existen datos disponibles
            </v-alert>
          </template>
          <!-- footer de la tabla-->
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} {{$t('lFooterOf')}} {{props.itemsLength}}
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { backendService } from '@/services/backend.service'
import validations from '@/mixins/validations'
import {i18n} from "@/plugins/i18n";
import HomesSelector from '@/components/HomesSelector'
import UserSelector from '@/components/UserSelector'
import store from "@/store";
import {mediaResourcesService} from "@/services/mediaResources.service";
import logger from '@/mixins/logger'
import {manageErrors} from "@/mixins/errorAlertManagement";
import {VUE_APP_CONTENTS_TOPIC,VUE_APP_UPDATEAPP_TOPIC,VUE_APP_PUBUSUB_PREFIX} from '@/services/xmpp.service.js'


export default {
  finished: true,
  name: "UsersManagment",
  components: {
    HomesSelector,
    UserSelector
  },
  data() {
    return {
      totalItems: 0,
      options: {
        page:1,
        itemsPerPage:20,
        totalItems:0,
        sortBy:['backendUser.idUser'],
        sortDesc:[false],
        sorting: 'backendUser.idUser' + ',' + 'asc'
      },
      finished: true,
      dialogSave:false,
      userList:[],
      homesList:[],
      rolesList:[],
      userTypesList:[],
      search: '',
      userTypeSelectorFilter:null,
      awaitingSearch: false,
      rules: validations,
      headers: [
        { text: this.$t('lCode'), value: 'backendUser.idUser', align: 'center', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lUser'), value: 'backendUser.user', align: 'start', sortable: true, filterable: true, width: '15%' },
        { text: this.$t('lCompleteName'), value: 'backendUserVcard.fn', align: 'start', sortable: true, filterable: true, width: '15%' },
        { text: this.$t('lNickName'), value: 'backendUserVcard.nickname', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lUserType'), value: 'backendUser.backendUserTypeId', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lHome'), value: 'backendUser.homes', align: 'start', sortable: false, filterable: true, width: '10%' },
        { text: this.$t('lEnable'), value: 'backendUser.enable', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lAdmin'), value: 'backendUser.isAdmin', align: 'start', sortable: false, filterable: false, width: '10%' },
        { text: this.$t('lActions'), value: 'actions', align: 'start', sortable: false, filterable: false, width: '15%' }
      ],
      footerProps: {
        'items-per-page-text': this.$t('lRowsPerPage'),
        'items-per-page-all-text':this.$t('lAll'),
        'items-per-page-options': [5, 10, 20,-1]
      },
      editedIndex: -1,
      editedItem:{
         backendUser:{
           idUser:-1,
           user:'',
           password:null,
           enable:true,
           backendUserType:{idTypes: 2, type: "Usuario"},
           moderator:false,
           recorder:false,
           liveStreaming:false,
           authorities:[{idRol: 1, rol: "USUARIO"}],
           homes:[],
         },
         backendUserVcard:{
           user:'',
           jabberId:'',
           fn:'',
           nickname:'',
           url:'',
           home:false,
           age:'',
           address:'',
           locality:'',
           postalCode:'',
           province:'',
           country:'',
           voice:''
         }
      },
      defaultItem: {
        backendUser:{
          jabberId:'',
          idUser:-1,
          user:'',
          password:null,
          enable:true,
          backendUserType:{idTypes: 2, type: "Usuario"},
          moderator:false,
          recorder:false,
          liveStreaming:false,
          authorities:[{idRol: 1, rol: "USUARIO"}],
          homes:[],
        },
        backendUserVcard:{
          user:'',
          fn:'',
          nickname:'',
          url:'',
          home:false,
          age:'',
          address:'',
          locality:'',
          postalCode:'',
          province:'',
          country:'',
          voice:''
        }
      },
      roleSelection : [],
      dialogSelectRole : false,
      dialogSelectHomes:false,
      dialogSelectUsers:false,
      selectedHomes:[],
      selection:[],
      tenantDomain:null,
      imageFile:null,
      dialogImagenes: false,
      overlay:false,
      usersSendMail:[]
    };
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.usersTableData.indexOf(item)
      this.roleSelection = item.backendUser.authorities

      if(this.editedIndex !== -1){
        this.editedItem = Object.assign({}, this.userList[this.editedIndex])
      }
      else{
        this.editedItem = Object.assign({}, this.defaultItem)
      }

      this.editedItem.backendUser.password=null
      if(this.editedItem.backendUser.idUser!==-1) this.editedItem.backendUser.backendUserType.type= this.translateTypeComboSelection(this.editedItem.backendUser.backendUserType.type)

      this.dialogSave = true
    },
    validate() {
      return this.$refs.form.validate()
    },
    clearValidations() {
      if (this.$refs.form) {
        return this.$refs.form.reset()
      }
    },
    close() {
      this.overlay= false;
      this.dialogSave = false
      this.roleSelection = []
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.clearValidations()
    },
    // Dialogo confirmacion eliminar
    activateDeactivateConfirmDialog(item){
      let title=''
      let text=''
      if(item.backendUser.enable){
        title='Desactivar Usuario'
        text='¿Esta seguro que desea desactivar este usuario?'
      }
      else{
        title='Activar Usuario'
        text='¿Esta seguro que desea activar este usuario?'
      }
      this.$dialog.confirm({
        title: title,
        text: text ,
        persistent: true,
        actions: {
          false: 'No',
          true: {
            color: 'black',
            text: 'Si',
            handle: () => {
              this.activateDeactivateItem(item.backendUser)
            }
          }
        }
      })
    },
    // Dialogo confirmacion eliminar
    permanentDeleteConfirmDialog(item){
      let title=''
      let text=''
      title='Eliminar definitivamente un usuario'
      text='¿Esta seguro que desea eliminar este usuario? si continua, esta accion no se podra deshacer.'
      this.$dialog.confirm({
        title: title,
        text: text ,
        persistent: true,
        actions: {
          false: 'No',
          true: {
            color: 'black',
            text: 'Si',
            handle: () => {
              this.deletePermanentItem(item.backendUser)
            }
          }
        }
      })
    },
    deletePermanentItem(item){
      backendService.permanentDeleteUser(item.user)
          .then((response) => {
            this.xmppSubscribeUserToNode(item.user.replace('@','.') + '@' + this.tenantDomain,'none')
            this.init()
            this.close()
          })
          .catch((error) => {
            manageErrors(this,error).finally(() => {
              this.close()
              this.init()
            })
          })
    },
    // desactivar
    activateDeactivateItem(item) {
      this.editedIndex = this.usersTableData.indexOf(item)
      this.editedItem = Object.assign({}, this.userList[this.editedIndex])
      ////////////////////////////////////////////////////////////////////////////////////////
      // Peticion de desactivar el usuario
      if(item.enable){
        backendService.deleteUser(item.user)
            .then((response) => {
              // Asignar el usuario a los nodos de xmpp dependiendo del role en la app
              this.xmppSubscribeUserToNode(item.user.replace('@','.') + '@' + this.tenantDomain,'none')
              //
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              this.clearValidations()
              this.init()
            })
            .catch((error) => {
              manageErrors(this,error).finally(() => {
                this.close()
                this.init()
              })
            })
      }
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Peticion de activar el usuario
      else{
        backendService.activateUser(item.user)
            .then((response) => {
              // Asignar el usuario a los nodos de xmpp dependiendo del role en la app
              let topicRole='member'
              let roles= item.authorities
              for (let i = 0; i < roles.length; i++) {
                if(roles[i].rol==='PROFESIONAL' && topicRole!=='owner') topicRole = 'publisher'
                if(roles[i].rol==='ADMINISTRADOR') topicRole = 'owner'
              }
              this.xmppSubscribeUserToNode(item.user.replace('@','.') + '@' + this.tenantDomain,topicRole)
              //
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              this.clearValidations()
              this.init()
            })
            .catch((error) => {
              manageErrors(this,error).finally(() => {
                this.close()
                this.init()
              })
            })
      }
    },
    // Crear o editar
    saveItemConfirm() {
      if (this.validate()) {
        //Comprobamos que las homes no esten vacias
        if(this.editedItem.backendUser.homes.length===0){
          this.$dialog.confirm({
            title: 'Por favor, rellene todos los campos.',
            text:'Es necesario asignar al usuario a un "hogar" para poder continuar.' ,
            persistent: true,
            actions: {
              true: { color: 'black',  text: 'OK',}
            }
          })
        }
        else if(this.editedItem.backendUser.authorities.length===0){
          this.$dialog.confirm({
            title: 'Por favor, rellene todos los campos.',
            text:'Es necesario asignar al usuario "roles" en el sistema para continuar.' ,
            persistent: true,
            actions: {
              true: { color: 'black',  text: 'OK',}
            }
          })
        }
        else{
          this.overlay= true;

          // Crear o editar
          if (this.editedItem.backendUser.idUser === -1) { // Crear
            this.saveUser()
          }
          else{ // Editar
            this.updateUser()
          }
        }
      }
    },
    xmppSubscribeUserToNode(jid,role){
      // publish to xmpp node for update contents in tv
      var topicContents = VUE_APP_CONTENTS_TOPIC
      var topicUpdateApp = VUE_APP_UPDATEAPP_TOPIC
      let pubSubServer = VUE_APP_PUBUSUB_PREFIX+ '.' + store.getters['backend/myself'].tenant.xmppDomain

      this.$store.dispatch('xmpp/XMPP_SUBSCRIBE_ANOTHER_USER_TO_TOPIC', {
        pubSubServer: pubSubServer,
        topic:topicContents,
        jid:jid,
        topicRole:role
      }).then((response) => {
        logger.log(response)
      }).catch(err => {
        logger.log(err)
      })

      this.$store.dispatch('xmpp/XMPP_SUBSCRIBE_ANOTHER_USER_TO_TOPIC', {
        pubSubServer: pubSubServer,
        topic:topicUpdateApp,
        jid:jid,
        topicRole:role
      }).then((response) => {
        logger.log(response)
      }).catch(err => {
        logger.log(err)
      })

    },
    saveUser(){
      this.editedItem.backendUser.user = this.editedItem.backendUser.user.toLocaleLowerCase()

      // ejabberd
      this.editedItem.backendUserVcard.jabberId = this.editedItem.backendUser.user.replace('@','.') + '@' + this.tenantDomain

      this.editedItem.backendUserVcard.user = this.editedItem.backendUser.user.replace('@','.')

      if(!this.editedItem.backendUserVcard.url) this.editedItem.backendUserVcard.url= 'NA'

      this.editedItem.backendUserVcard.role=this.translateTypeToBackend(this.editedItem.backendUser.backendUserType.type)

      if (this.editedItem.backendUserVcard.home ===null) this.editedItem.backendUserVcard.home = false
      //
      backendService.addUser(this.editedItem.backendUser,this.editedItem.backendUserVcard)
          .then((response) => {

            // Envio de correos
            backendService.sendEmailPassword({password: this.editedItem.backendUser.password, to: this.editedItem.backendUser.user, user:this.editedItem.backendUser.user})
            if(this.usersSendMail){
              Object.entries(this.usersSendMail).forEach(([key, value]) => {
                if(value){
                  backendService.sendEmailPassword({password: this.editedItem.backendUser.password, to: value.user, user:this.editedItem.backendUser.user})
                }
              });
              this.usersSendMail=[]
            }

            // Asignar el usuario a los nodos de xmpp dependiendo del role en la app
            let topicRole='member'
            let roles= response.data.authorities
            for (let i = 0; i < roles.length; i++) {
              if(roles[i].rol==='PROFESIONAL' && topicRole!=='owner') topicRole = 'publisher'
              if(roles[i].rol==='ADMINISTRADOR') topicRole = 'owner'
            }
            this.xmppSubscribeUserToNode(this.editedItem.backendUserVcard.jabberId,topicRole)
            this.init()
            this.close()
          })
          .catch((error) => {
            manageErrors(this,error).finally(() => {
              this.overlay= false;
            })
          })
    },
    updateUser(){
      this.editedItem.backendUser.user = this.editedItem.backendUser.user.toLocaleLowerCase()

      // ejabberd
      this.editedItem.backendUserVcard.jabberId = this.editedItem.backendUser.user.replace('@','.') + '@' + this.tenantDomain

      this.editedItem.backendUserVcard.user = this.editedItem.backendUser.user.replace('@','.')

      if(!this.editedItem.backendUserVcard.url) this.editedItem.backendUserVcard.url= 'NA'

      this.editedItem.backendUserVcard.role=this.translateTypeToBackend(this.editedItem.backendUser.backendUserType.type)

      if (this.editedItem.backendUserVcard.home ===null) this.editedItem.backendUserVcard.home = false
      //
      backendService.updateUser(this.editedItem.backendUser,this.editedItem.backendUserVcard)
          .then((response) => {
            // Correo de cambio de contraseña
            if(this.editedItem.backendUser.password){
              backendService.sendEmailResetPassword({password: this.editedItem.backendUser.password, to: this.editedItem.backendUser.user, user:this.editedItem.backendUser.user})
              if(this.usersSendMail){
                Object.entries(this.usersSendMail).forEach(([key, value]) => {
                  if(value){
                    backendService.sendEmailResetPassword({password: this.editedItem.backendUser.password, to: value.user, user:this.editedItem.backendUser.user})
                  }
                });
                this.usersSendMail=[]
              }
            }

            // Asignar el usuario a los nodos de xmpp dependiendo del role en la app
            let topicRole='member'
            let roles= response.data.authorities
            for (let i = 0; i < roles.length; i++) {
              if(roles[i].rol==='PROFESIONAL' && topicRole!=='owner') topicRole = 'publisher'
              if(roles[i].rol==='ADMINISTRADOR') topicRole = 'owner'
            }
            this.xmppSubscribeUserToNode(this.editedItem.backendUserVcard.jabberId,topicRole)

            this.init()
            this.close()
          })
          .catch((error) => {
              manageErrors(this,error).finally(() => {
                this.overlay= false;
              })
          })
    },
    generatePassword(){
      let curKey;
      const letters = [
        'bcdfghjklmnpqrstvwxz',
        'aeiouy'
      ];
      const length = 12;
      let secret = Math.floor(Math.random() * 9);
      curKey = Math.floor(Math.random() * letters.length);
      for (let i = 1; i < length; i++) {
        secret += this.randomItem(letters[curKey]);
        curKey = (curKey +1) % letters.length;
      }
      this.editedItem.backendUser.password = secret;
    },
    randomItem(array) {
      return array[Math.floor(Math.random() * array.length)];
    },
    addItem() {
      this.editItem(this.editedItem)
    },
    getBackendUsersPage(){
      this.userList=[]
      this.finished=false
      this.sorting () // Info de ordenar columnas
      backendService.getUserListAdminPagination(this.search,this.options,"",false)
          .then((response) => {

            // Gestionamos los elementos totales
            let count = parseInt(response.headers["x-total-count"])
            this.totalItems= count

            this.userList = response.data
            this.finished=true
          }).catch((error) => {
          logger.log(error)
          this.finished=true
          })
    },
    init() {
      // Obtenemos el host del tenant
      this.tenantDomain=store.getters['backend/myself'].tenant.xmppDomain

      // Obtener la lista de usuarios con paginacion (back)
      this.getBackendUsersPage()

      // Obtener la lista de hogares
      this.homesList=[]
      backendService.getHomesList()
          .then((response) => {
            this.homesList = response.data
          })
          .catch((error) => {
            manageErrors(this,error).finally(() => {
              this.close()
              this.init()
            })
          })
      // Obtener los tipos de usuarios
      this.userTypesList=[]
      backendService.getUserTypesListWithID()
          .then((response) => {
            this.userTypesList = response.data
            for( let j = 0; j <  this.userTypesList.length; j++) {
              this.userTypesList[j].type=this.translateTypeComboSelection(this.userTypesList[j].type)
            }
          })
          .catch((error) => {
            manageErrors(this,error).finally(() => {
              this.close()
              this.init()
            })
          })
      // Obtener la lista de Roles
      this.rolesList=[]
      backendService.getRolesList()
          .then((response) => {
            this.rolesList = response.data
            //Eliminar Role de Admin
            this.rolesList = this.rolesList.filter(function(value, index, arr){
              return value.idRol!==2;
            });
          })
          .catch((error) => {
            manageErrors(this,error).finally(() => {
              this.close()
              this.init()
            })
          })
    },
/////////////////////////////////////////////////
    closeRoleSelector() {
      this.dialogSelectRole = false
      this.onChangeUserRol()
    },
    acceptRoleSelector() {
      this.editedItem.backendUser.authorities = this.roleSelection
      this.dialogSelectRole = false
      this.onChangeUserRol()
    },
    closeHomesSelector() {
      this.dialogSelectHomes = false
    },
    acceptHomesSelector(selection) {
      this.editedItem.backendUser.homes = selection
      this.dialogSelectHomes = false
    },
    closeUserSelector() {
      this.usersSendMail=[]
      this.dialogSelectUsers = false
    },
    acceptUserSelector(selection) {
      var userlist =[]
      if(selection){
        if(selection.length >0){
          for(var i=0;i<selection.length;i++){
            if(selection[i].backendUser){
              userlist.push(selection[i].backendUser)
            }
          }
          // userlist=selection.map(a => a.backendUser)
        }
      }
      this.usersSendMail = userlist
      this.dialogSelectUsers = false
    },
    /////////////////////////////////
    translateTypeComboSelection(type){
        switch (type) {
          case 'FAMILY':
            return i18n.t('lFAMILY')
          case 'PATIENT':
            return i18n.t('lPATIENT')
          case 'CARER':
            return i18n.t('lCARER')
          case 'FRIEND':
            return i18n.t('lFriend')
          default:
            return type
      }
    },
    translateTypeToBackend(type){
      switch (type) {
        case 'FAMILY':
          return 'FAMILY'
        case i18n.t('lFAMILY'):
          return 'FAMILY'
        case 'PATIENT':
          return i18n.t('lPATIENT')
        case i18n.t('lPATIENT'):
          return 'PATIENT'
        case 'CARER':
          return 'CARER'
        case i18n.t('lCARER'):
          return 'CARER'
        case'FRIEND' :
          return 'FRIEND'
        case i18n.t('lFriend'):
          return 'FRIEND'
        default:
          return type
      }
    },
    /////////////////////////////////////////////////
    // Images
    submitImageFile() {
      if (this.imageFile) {
        logger.log(this.imageFile)
        mediaResourcesService.uploadImage(this.imageFile).then(response => {
          this.editedItem.backendUserVcard.url=''
          this.editedItem.backendUserVcard.url=response.data
        }).catch(error => {
          logger.log({error})
        })
      }
    },
    closeImageSelector() {
      this.dialogImagenes = false
      this.imageFile=null
    },
    acceptImageSelector() {
      this.submitImageFile()
      this.dialogImagenes = false
      this.imageFile=null
    },
    onChangeUserRol(){
      if(this.editedItem.backendUser.authorities.findIndex(i => i.rol === 'PROFESIONAL')===-1){
        this.editedItem.backendUser.liveStreaming=false;
        this.editedItem.backendUser.recorder=false;
        this.editedItem.backendUser.moderator=false;
      }
      this.onChangeUserType()
    },
    onChangeUserType(){
      if(this.editedItem.backendUser.backendUserType.idTypes){
        if((this.editedItem.backendUser.backendUserType.idTypes === 1)
            || (this.editedItem.backendUser.backendUserType.idTypes === 2)
            || (this.editedItem.backendUser.backendUserType.idTypes === 4)){

          this.editedItem.backendUser.authorities = this.editedItem.backendUser.authorities.filter(function(value, index, arr){
            return value.idRol!==3;
          });
          this.roleSelection = this.editedItem.backendUser.authorities
        }
        else if(this.editedItem.backendUser.backendUserType.idTypes === 3){
          if(this.editedItem.backendUser.authorities.findIndex(i => i.rol === 'PROFESIONAL')===-1){
            this.editedItem.backendUser.authorities.push({idRol: 3, rol: "PROFESIONAL"})
          }
          this.roleSelection = this.editedItem.backendUser.authorities
        }
      }
    },
    getHomesFromUser(homes){
     if(homes.length>1) return "Multiple"
     else if(homes[0]){return homes[0].homeName}
     else return []
    },
    saveBirthday (date) {
      this.$refs.menu.save(date)
    },
    sorting () { // Ordenar columnas para la paginacion y busqueda en back
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        this.options.sorting = this.options.sortBy[0] + ',' + (this.options.sortDesc[0] ? 'desc' : 'asc')
      } else {
        this.options.sorting = 'backendUser.idUser' + ',' + 'asc'
      }
    }
  },
  mounted() {
    this.init()
  },
  created() {
  },
  filters: {
    formatBoolean (boolean) {
      switch (boolean) {
        case true:
          return i18n.t('lYes')
        case false:
          return i18n.t('lNo')
        default:
          return i18n.t('lNo')
      }
    },
    formatTypes (type) {
      switch (type) {
        case 'FAMILY':
          return i18n.t('lFAMILY')
        case 'PATIENT':
          return i18n.t('lPATIENT')
        case 'CARER':
          return i18n.t('lCARER')
        case 'FRIEND':
          return i18n.t('lFriend')
        default:
          return type
      }
    }
  },
  computed: {
    itemDialogTitle () {
      return this.editedIndex === -1 ? this.$t('lNewUser') : this.$t('lEditUser')
    },
    usersTableData() {
      let userAux = []
      for(let i=0;i<this.userList.length;i++){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        var user = JSON.parse(JSON.stringify(this.userList[i]));
        user.backendUser.homes=this.getHomesFromUser(user.backendUser.homes)
        userAux.push(user)
      }
      return userAux
    },
  },
  watch: {
    search:{ // Paginacion
      handler () {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getBackendUsersPage()
            this.awaitingSearch = false;
          }, 1750); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    }
  },
};
</script>
