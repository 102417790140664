import { AUTH_LOGOUT, REFRESH_TOKEN } from '@/store/actions/auth'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import logger from '@/mixins/logger'
export const AXIOS = axios.create({})
export const API_URL = process.env.VUE_APP_API_URL


AXIOS.interceptors.request.use((config) => {
  // if access token exists
  if (store.getters['auth/token']) {
    // add it to the header
    // eslint-disable-next-line
    //config.headers['Authorization'] = store.getters.token
    config.headers['Authorization'] = store.getters['auth/token']
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

AXIOS.interceptors.response.use((response) => {
  // if a new token is given
  // eslint-disable-next-line
  if (response.headers['Authorization']) {
    // update the token
    // eslint-disable-next-line
    store.commit('auth/REFRESH_TOKEN', response.headers['Authorization'])
  }
  return response
}, (error) => {
  // if the error hasn't defined the response
  if (!error.response) {
    // trace the message and reject it
    // console.error('Something went wrong. Message: ', error.message)
    return Promise.reject(error)
  }

  logger.error('Something went wrong. Status: ', error.response.status)
  if (!error.config.url.includes('/login') && error.response.status === 401) {
    // execute the logout, marking it as expired
    store.dispatch('auth/AUTH_LOGOUT', true).then()
  } else if (error.response.status === 403) {
    // Logout XMPP
    store.dispatch('xmpp/XMPP_LOGOUT', {
    }).then(() => {
      logger.log("log out")
    }).catch(err => {
      logger.log(err)
    })
    // is status is forbidden, redirect to 403 view
    router.push({ name: 'Login'}).then()
  } else {
    // for other errors, reject the response
    return Promise.reject(error)
  }

})
