import logger from '@/mixins/logger'
import {i18n} from "@/plugins/i18n";

export const showErrorPromptCustom = function (that,title,text){
    console.log('TRAZA', 'Error: '+title+" - "+text)

    return new Promise((resolve, reject) => {
        that.$dialog.confirm({
            title: title,
            text:text ,
            persistent: true,
            actions: {
                true: {
                    color: 'black',
                    text: 'OK',
                    handle: () => {
                        resolve()
                    }
                }
            }
        })
    })

};

export const showErrorPromptGeneral = function (that)  {
    console.log('TRAZA', 'Error general')
    return new Promise((resolve, reject) => {
        that.$dialog.confirm({
            title: 'Se ha producido un error',
            text:'Se ha producido un error al realizar la operacion solicitada' ,
            persistent: true,
            actions: {
                true: {
                    color: 'black',
                    text: 'OK',
                    handle: () => {
                        resolve()
                    }
                }
            }
        })
    })
};

export const manageErrors = function(that , error){
    logger.error(error)
    return new Promise((resolve, reject) => {
        if(error.response.data){
            if(error.response.data.errorKey){
                // error.response.data.defaultMessage
             showErrorPromptCustom(that,'Operacion no admitida',manageMessages(error.response.data.errorKey))
             .then(() => {
                 resolve()
             })
            }
            else{
                showErrorPromptGeneral(that)
                .then(() => {
                    resolve()
                })
            }
        }
        else{
            showErrorPromptGeneral(that)
            .then(() => {
                resolve()
            })
        }
    })
};

export const manageYoutubeErrors = function(that , error){
    return new Promise((resolve, reject) => {
        if(error.details){
            showErrorPromptCustom(that,'Operacion no admitida',error.details)
            .then(() => {
                resolve()
            })    
        }

        if(error.status && error.status == 400){
            showErrorPromptCustom(that,'Operacion no admitida',error.result.error.message)
            .then(() => {
                resolve()
            })  
        }

        if(error.error && error.error == "popup_closed_by_user"){
            showErrorPromptCustom(that,'Operacion cancelada', 'Se ha cerrado la ventana de Oauth con Youtube')
            .then(() => {
                resolve()
            })  
        }
    })
};

const manageMessages = function (errorKey){
    if(!errorKey){return 'Se ha producido un error al realizar la operacion solicitada' }
    switch (errorKey){
        case errorKey:
        return i18n.t(errorKey)

        default:
        return 'Se ha producido un error al realizar la operacion solicitada'
    }
}