import {
  XMPP_LOGIN,
  XMPP_LOGIN_ERROR,
  XMPP_LOGOUT,
  XMPP_SEND_PRESENCE,
  XMPP_SEND_MESSAGE,
  XMPP_SUBSCRIBE_TOPIC,
  XMPP_CREATE_TOPIC,
  XMPP_PUBLISHTO_TOPIC,
  XMPP_SUBSCRIBE_ANOTHER_USER_TO_TOPIC
} from '@/store/actions/xmpp'
import store from '@/store'
import { xmppService } from '@/services/xmpp.service'


/**
 * States for xmpp
 */
const state = {
  xmppLoginError: null,
  messages: [],
  contacts: [],
  hasNetwork: null,
  isOnline: false,
  myData: {},
  myVCard: [],
  credentials: null
}

/**
 * Getters for xmpp
 */
const getters = {
  xmppLoginError: state => state.xmppLoginError,
  getRoster: state => state.contacts,
  getMyVcard: state => state.myVCard,
  getXmppCredentials: state => state.credentials,
  getContactByJid: (state) => jid => {
    let index = state.contacts.findIndex((contact) => contact.jid === jid)
    if (index !== -1) {
      return state.contacts[index]
    }
  }
}

/**
 * Actions for xmpp
 */
const actions = {
  [XMPP_LOGIN] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit(XMPP_LOGIN_ERROR, null)
      xmppService.create(
          data.user.jabberId+"@"+data.user.tenant.xmppDomain,
          data.password,
          data.user.tenant.xmppWebSocket,
          data.context
      )
      .then(()=> {
        xmppService.connect()
        resolve("ok")
      })
      .catch(error => {
        commit(XMPP_LOGIN_ERROR)
        reject(error)
      })
    })
  },
  [XMPP_LOGOUT] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      xmppService.disconnect();
    })
  },
  [XMPP_SEND_PRESENCE] ({ commit, state, dispatch }, data) {
    xmppService.sendPresence(data);
  },
  [XMPP_SEND_MESSAGE] ({ commit, state, dispatch }, data) {
    xmppService.sendMessage(data.jid, data.body)
  },
  [XMPP_SUBSCRIBE_TOPIC] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      xmppService.subscribeToTopic(data.pubSubServer, data.topic)
          .then((response)=> {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
    })
  },
  [XMPP_SUBSCRIBE_ANOTHER_USER_TO_TOPIC] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      xmppService.updateTopicAffiliations(data.pubSubServer, data.topic, data.jid, data.topicRole)
          .then((response)=> {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
    })
  },
  [XMPP_CREATE_TOPIC] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      xmppService.createTopic(data.pubSubServer, data.topic)
          .then((response)=> {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
    })
  },

  [XMPP_PUBLISHTO_TOPIC] ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      xmppService.publishMessageToTopic(data.pubSubServer, data.topic,data.messageBody)
          .then((response)=> {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
    })
  }
}

const mutations = {
  [XMPP_LOGIN_ERROR]: (state, errorType) => {
    state.xmppLoginError = errorType
  },

  // network status setter
  setNetworkStatus (state, hasNetwork) {
      state.hasNetwork = hasNetwork
  },

  // online client status setter
  setOnline (state, isOnline) {
      state.isOnline = isOnline
  },

  // roster setter
  setRoster (state, contacts) {
      state.contacts = contacts
  },

  // Own Vcard setter
  setMyVcard (state, vCard) {
     state.myVCard = vCard
  },

  setXmppCredentials(state, credentials) {
    state.credentials = credentials
  },

    // contact presence setter
  setContactPresence (state, contactPresence) {
    let contacts = state.contacts.slice(0)
    let index = contacts.findIndex((contact) => contact.jid === contactPresence.jid)
    if (index !== -1) {
        contacts[index].presence = contactPresence.presence
        state.contacts = contacts
    }
    let currentCall = store.getters['videocall/getCurrentCall']

    if (contactPresence.presence === 'unavailable' && currentCall && currentCall.jid === contactPresence.jid) store.commit('videocall/transition', 'CONNECTION_CLOSED')
  },

  setLastReceivedMessage (state, lastMessage) {
    let contacts = state.contacts.slice(0)
    let index = contacts.findIndex((contact) => contact.jid === lastMessage.jid)
    if (index !== -1) {
        contacts[index].lastMessageReceived = lastMessage.message
        state.contacts = contacts
    }
  },

  setLastSentMessage (state, lastMessage) {
    let contacts = state.contacts.slice(0)
    let index = contacts.findIndex((contact) => contact.jid === lastMessage.jid)
    if (index !== -1) {
        contacts[index].lastMessageSent = lastMessage.message
        state.contacts = contacts
    }
  }
}

export default {
  namespaced: true,   
  state,
  getters,
  actions,
  mutations
}
