import { AXIOS } from '@/plugins/axios'
import { API_URL } from '@/plugins/axios'

export const authService = {
    authenticate,
    logout
}

// executes the authentication with the given username and password
function authenticate (username, password,tenant) {
    const body = {'username': username,'password': password, 'tenant': tenant};
    return AXIOS({
        method: 'post',
        url: API_URL + '/login',
        data: JSON.stringify(body),
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
      })
}

// executes the logout for the given username
function logout () {
    return AXIOS.get(API_URL +'/logout')
}
