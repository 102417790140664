<template>
    <v-card>
      <h3 style="padding-top: 0.8em; padding-left: 0.8em; font-weight: normal;">{{title}}</h3>
      <v-layout>
        <v-flex xs12>
          <v-layout column justify-space-between>
            <v-card-subtitle
                v-text="body">
            </v-card-subtitle>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider light></v-divider>
      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
            icon
            color="gray lighten-2"
            text
            @click="hangupCall()"
           >
          <v-icon class="ma-1" color="red">mdi-square</v-icon>
          TERMINAR
          </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            icon
            color="gray lighten-2"
            text
            @click="continueCall()"
            >
          <v-icon class="ma-1" color="green">mdi-square</v-icon>
          CONTINUAR
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
</template>

<script>
import {EventBus} from "@/bus/bus";

export default {
  data() {
    return {
      title:'',
      body:'',
      menu: {}
    };
  },
  methods: {
    hangupCall(){
      var params={}
      params.callStatus='hangup'
      params.menu=this.menu
      EventBus.$emit('entryHangupDialogSubmit', {params});
      this.$emit('submit')
    },
    continueCall(){
      this.$emit('submit')
    }
  },
  mounted() {
  },
  created() {
    EventBus.$on('entryHangupDialogOpen',(received)=>{
      this.body=received.message.body
      this.title=received.message.title
      this.menu=received.message.menu
    })
    EventBus.$on('entryHangupDialogClose',(received)=>{
      this.$emit('submit')
    })
  },
  beforeDestroy() {
    EventBus.$off('entryHangupDialogOpen')
    EventBus.$off('entryHangupDialogClose')
  },
  computed: {
  },
};
</script>