import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n } from '@/plugins/i18n'
import Login from '@/components/layouts/Login.vue'
import Dashboard from '@/components/layouts/Dashboard.vue'
import Home from '@/views/Home.vue'
import Videollamada from '@/components/Videollamada.vue'
import CanalesContenidos from '@/components/CanalesContenidos.vue'
import UsersManagment from '@/components/UsersManagment.vue'
import HomesManagement from '@/components/HomesManagement'
import IoTManagement from '@/components/IoTManagement'
import store from "@/store";
import {EventBus} from "@/bus/bus";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login/:tenant?',
    name: 'Login',
    component: Login,
  },
  {
    path: '/SeniorCare',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: '/SeniorCare/Videollamada',
        name: 'Home',
        component: Videollamada,
        // meta: {title: i18n.t('lVideollamadaTitle') }
      },
      {
        path: '/SeniorCare/Contenidos',
        name: 'Contenidos',
        component: CanalesContenidos,
        // meta: {title: i18n.t('lHomesManagementTitle') }
      },
      {
        path: '/SeniorCare/UsersManagement',
        name: 'UsersManagment',
        component: UsersManagment,
        // meta: {title: i18n.t('lContenidosTitle') }
      },
      {
        path: '/SeniorCare/HomesManagement',
        name: 'HomesManagement',
        component: HomesManagement,
        // meta: {title: i18n.t('lContenidosTitle') }
      },
      {
        path: '/SeniorCare/IOT',
        name: 'IoTManagement',
        component: IoTManagement,
        // meta: {title: i18n.t('lContenidosTitle') }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (store.getters['videocall/getCurrentCall'] != null && from.path === '/SeniorCare/Videollamada') {
    var params = []
    params.type = "hangup"
    params.title = "Llamada en curso"
    params.body = "No es posible cambiar de pestaña durante una llamada. ¿Desea terminar la llamada en curso?"
    params.menu = "/login/" + store.getters['backend/myself'].tenant.tenantName
    EventBus.$emit('dialog', {params})
    next(false)
  } else {
    // Manage route auth
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!sessionStorage.getItem('token')) {
        next({
          path: '/',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next()// make sure to always call next()!
    }
  }
})



export default router
