import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import xmpp from '@/store/modules/xmpp'
import backend from '@/store/modules/backend'
import mediaResources from '@/store/modules/mediaResources'
import videocall from '@/store/modules/videocall'
import createPersistedState from "vuex-persistedstate"
import { createStore } from 'vuex-extensions'

Vue.use(Vuex)

// export the vuex store with the defined modules
export default createStore (Vuex.Store,{
    modules: {
        auth,
        backend,
        mediaResources,
        videocall,
        xmpp
    },
    plugins: [createPersistedState({
        paths : ['auth','backend','xmpp','mediaResources','videocall.state.machine']
    })],
})