import { i18n } from '@/plugins/i18n'

/**
 * Email W3C regex
 * @type {RegExp}
 */
// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/**
 * Alphanumeric regex
 * @type {RegExp}
 */
const alphanumericRegex = /^[a-zA-Z0-9]*$/
/**
 * Alphanumeric regex allowing hyphens, underscores and blank spaces
 * @type {RegExp}
 */
const alphanumericPlusRegex = /^[a-zA-Z0-9\-_+ ]*$/
/**
 * Numeric regex
 * @type {RegExp}
 */
const numericRegex = /^[0-9]*$/

/**
 * Numeric regex
 * @type {RegExp}
 */
const numericPlusRegex = /^\+?[0-9]*$/

/**
 * Probability Regex allowing decimals
 * @type {RegExp}
 */
const probabilityRegex = /^[0-9]+%$/

/**
 * Returns the strength of the given password
 * @param p the password to check its strength
 * @return {number} the strength of the password
 */
function checkStrength (p) {
    // modify p in case of undefined or null
    if (p === undefined || p === null) p = ''
    // define the variable to store the strength of the password
    let strength = 0

    // execute the regex to test the password's strength
    const lowerLetters = /[a-z]+/.test(p)
    const upperLetters = /[A-Z]+/.test(p)
    const numbers = /[0-9]+/.test(p)
    // eslint-disable-next-line
    const symbols = /[$-/:-?{-~!"^_`\[\]]/g.test(p)

    // check how many flags the password has matched
    const passedMatches = [lowerLetters, upperLetters, numbers, symbols].filter(el => el === true).length

    // calculate the strength
    strength += 2 * p.length + ((p.length >= 10) ? 1 : 0)
    strength += passedMatches * 10

    // penalty (short password)
    strength = (p.length < 8) ? Math.min(strength, 10) : strength

    // penalty (poor variety of characters)
    strength = (passedMatches === 1) ? Math.min(strength, 10) : strength
    strength = (passedMatches === 2) ? Math.min(strength, 20) : strength
    strength = (passedMatches === 3) ? Math.min(strength, 40) : strength

    return strength
}

/**
 * Return false if the current date without time is before the passed date in the param, true otherwise
 * @param date String of a date without time
 * @returns {boolean}
 */
function checkDate(date){
    let today = new Date()
    let todayDate = new Date(today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate())
    return (Date.parse(date) - todayDate) > 0

}

/**
 * Return false if the current time without date is before the passed time in the param, true otherwise.
 * Will return true if
 * @param date String of a time without date
 * @returns {boolean}
 */
function checkTime(time, date){
    let today = new Date()
    let todayTime = today.setSeconds(0,0)
    let passedTime = new Date( date + ' ' + time + ':00')
    return (passedTime - todayTime)  > 0
}


const validations = {
    required: value => !!value || i18n.t('lRequired'),
    maxLength2: value => {
        if (!value) return true
        else return (value.toString().length <= 2|| i18n.t('lMaxLength'))
    },
    maxLength6: value => {
        if (!value) return true
        else return (value.toString().length <= 6|| i18n.t('lMaxLength'))
    },
    maxLength100: value => {
        if (!value) return true
        else return (value.toString().length <= 100 || i18n.t('lMaxLength'))
    },
    maxLength45: value => {
        if (!value) return true
        else return (value.toString().length <= 45 || i18n.t('lMaxLength'))
    },
    maxLength60: value => {
        if (!value) return true
        else return (value.toString().length <= 60 || i18n.t('lMaxLength'))
    },
    maxLength300: value => {
        if (!value) return true
        else return (value.toString().length <= 300 || i18n.t('lMaxLength'))
    },
    maxLength190: value => {
        if (!value) return true
        else return (value.toString().length <= 190 || i18n.t('lMaxLength'))
    },
    maxLength12: value => {
        if (!value) return true
        else return (value.toString().length <= 12 || i18n.t('lMaxLength'))
    },
    maxLength50: value => {
        if (!value) return true
        else return (value.toString().length <= 50 || i18n.t('lMaxLength'))
    },
    notEmptyText: value => (value && !!value.trim()) || i18n.t('lNotEmpty'),
    passwordStrength: value => (!value || checkStrength(value) > 40) || i18n.t('validations.passwordStrength'),
    numeric:value => {
        if (!value) return true
        else return ( value => numericRegex.test(value) || i18n.t('lNumeric'))
    },
    numericPlus: value => {
        if (!value) return true
        else return numericPlusRegex.test(value) || i18n.t('lNumericPlus')
    },
    alphanumeric: value => alphanumericRegex.test(value) || i18n.t('lAlphaNumeric'),
    alphanumericPlus: value => alphanumericPlusRegex.test(value) || i18n.t('lAphanumericPlus'),
    email: value => emailRegex.test(value) || i18n.t('lEmailRequired'),
    fileSize: file => (file && file.size < 1048576) || i18n.t('validations.fileSize'),
    probability: value => (probabilityRegex.test(value) && (value.length <= 4) && (Number(value.substr(0,value.length - 1)) <= 100)) || i18n.t('lBadProbability'),
    notBeforeCurrentDate: value => checkDate(value) || i18n.t('lDateBeforeCurrent'),
    notBeforeCurrentTime: (time, date) => checkTime(time, date) || i18n.t('lTimeBeforeCurrent')
}

export default validations
