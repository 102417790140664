<template>
  <div>
    <div class="ma-5">
      <v-card tile elevation="4">
        <!-- Card con el filtro de búsqueda -->
        <v-card-title>
          <v-text-field
              v-model="search"
              class="mr-6"
              append-icon="mdi-magnify"
              :label="$t('lFilter')"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <!-- Tabla -->
        <v-data-table
            :headers="headers"
            :items="dashboards"
            :loading="this.dashboards.length === 0 && (!finished)"
            :loading-text="$t('lLoading')"
            sort-by="id.id"
            :search="search"
            :footer-props="footerProps"
            :options.sync="options"
            :server-items-length="totalElements"
            @update:options="getDashboards()"
        >
          <!-- Dialogo para el iframe -->
          <template v-slot:top>
            <v-dialog v-model="dialogDashboard" persistent fullscreen>
              <v-card tile>
                <iframe id="iframeID" src="" style="width:100%; height:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"  frameborder="0" height="100%" width="100%">
                </iframe>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="closeDashboard">{{ $t('bClose') }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <!-- Personalización de columna de acciones-->
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openDashboardInNewWindow(item)"
                >
                  mdi-open-in-new
                </v-icon>
              </template>
              <span>{{ $t('ttOpenDashboardInNewWindows') }}</span>
            </v-tooltip>-->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openDashboardInNewWindow(item)"
                >
                  mdi-desktop-mac-dashboard
                </v-icon>
              </template>
              <span>{{ $t('ttOpenDashboard') }}</span>
            </v-tooltip>
          </template>

          <!-- Personalizacion del mensaje de no hay datos disponibles -->
          <template slot="no-data">
            <v-alert :value="true" color="" icon="warning">
              No existen datos disponibles para su usuario
            </v-alert>
          </template>

          <!-- footer de la tabla-->
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} {{$t('lFooterOf')}} {{props.itemsLength}}
          </template>

        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { backendService } from '@/services/backend.service'
import logger from '@/mixins/logger'
import {manageErrors} from "@/mixins/errorAlertManagement";
import store from "@/store"
import {AXIOS} from "../plugins/axios";
import {mapState} from "vuex";

export default {
  name:'HomesManagment',
  data () {
    return {
      iFrameUrl:'',
      dialogDashboard:false,
      search:null,
      awaitingSearch:false,
      finished: false,
      itemIndex: -1,
      defaultItem:null,
      selectedDashboard:null,
      dashboards:[],
      totalElements:null,
      totalPages:null,
      footerProps: {
        'items-per-page-text': this.$t('lRowsPerPage'),
        'items-per-page-all-text':this.$t('lAll'),
        'items-per-page-options': [5, 10, 20]
      },
      headers: [
        /*{ text: this.$t('lCode'), value: 'id.id', align: 'center', sortable: false, filterable: true, width: '20%' },*/
        { text: this.$t('lName'), value: 'name', align: 'center', sortable: false, filterable: true, width: '50%' },
        { text: this.$t('lActions'), value: 'actions', align: 'center', sortable: false, filterable: false, width: '30%' }
      ],
      options: {
        page:1,
        itemsPerPage:20,
        totalItems:0,
        sortBy:['id.id'],
        sortDesc:[false],
        sorting: 'id.id' + ',' + 'asc'
      }
    }
  },
  methods: {
    init () {
      this.getDashboards()
    },
    openDashboardInNewWindow(item){
     logger.log('Dashboard abierto in new windows')
     let url = process.env.VUE_APP_IOT_URL + '/dashboard/'
     if(item.id){
      if (item.id.id){
        url = url + item.id.id + '?username=' + store.getters['backend/myself'].jabberId + '@' + store.getters['backend/myself'].tenant.xmppDomain + '&password=' + store.getters['xmpp/getXmppCredentials'].password;
        window.open(url, "Dashboard", "location=no,title=no");
      }
     }
    },/*
    openDashboard(item){
      logger.log('Dashboard abierto en iframe')
      let url = process.env.VUE_APP_IOT_URL + '/dashboard/'
      if(item.id){
        if (item.id.id){
          url = url + item.id.id + '?username=' + store.getters['backend/myself'].jabberId + '@' + store.getters['backend/myself'].tenant.xmppDomain + '&password=' + store.getters['xmpp/getXmppCredentials'].password;
          //url = url + item.id.id
          this.iFrameUrl=url
          this.dialogDashboard = true
          this.thingsboardCreateIframe(url)
        }
      }
    },*/
    closeDashboard() {
      this.dialogDashboard = false
      this.iFrameUrl = ''
      let iframe = document.getElementById('iframeID');
      iframe.src=''
    },
    getDashboards(){
      this.dashboards=[]
      let jwt = store.getters['backend/thingsboardJWT']
      let customerUserId = store.getters['backend/myself'].customerUserId
      this.finished=false
      backendService.getThingsboardDashboards(jwt,customerUserId,this.options.itemsPerPage,this.options.page,this.search)
          .then((response) => {
            logger.log(response)
            if(response.data.body) {
              this.dashboards = response.data.body.data
              this.totalElements = response.data.body.totalElements
              this.totalPages = response.data.body.totalPages
              this.finished = true
            }
          }).catch((error) => {
            logger.log(error)
            this.finished=true
            manageErrors(this,error)
          })
    },
    thingsboardCreateIframe(url){
      setTimeout(function(){
        let iframe = document.getElementById('iframeID');
        let jwt = store.getters['backend/thingsboardJWT']
        let header = {'X-Authorization':'Bearer '+jwt,'Content-Type': 'text/html'};

        AXIOS.get(url, {headers:header})
            .then((response) => {
              iframe.contentDocument.write(response.data);
              iframe.src=url
            })
            .catch((error) => {
              console.log(error);
            });

        // AXIOS({
        //   method: 'post',
        //   url: 'https://iot.silviaseniorcare.com/login',
        //   data: {username: "usuarioiot@test.es",password: "f00t00r3"},
        //   headers:header
        // }).then((response) => {
        //   AXIOS.get(url, {headers:header})
        //       .then((response) => {
        //         iframe.src=url
        //         // iframe.contentDocument.write(response.data);
        //       })
        //       .catch((error) => {
        //         console.log(error);
        //       });
        // })
        }, 1000);
    }
  },
  computed: {
  },
  watch: {
    search:{ // Paginacion
      handler () {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getDashboards()
            this.awaitingSearch = false;
          }, 1750); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
