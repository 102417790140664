export const MAKE_CALL = 'MAKE_CALL'
export const CALL_ATTEMPT = 'CALL_ATTEMPT'
export const CANCEL_CALL = 'CANCEL_CALL'
export const CALL_CANCELLED = 'CALL_CANCELLED'
export const REJECT_CALL = 'REJECT_CALL'
export const CALL_REJECTED = 'CALL_REJECTED'
export const END_CALL = 'END_CALL'
export const CALL_ENDED = 'CALL_ENDED'
export const REMOTE_CALL_ACCEPTED = 'REMOTE_CALL_ACCEPTED'
export const REMOTE_CALL_ENDED = 'REMOTE_CALL_ENDED'
export const CALL_ACCEPTED = 'CALL_ACCEPTED'   
export const HANDLE_MESSAGE = 'HANDLE_MESSAGE'
export const HANDLE_CARBON = 'HANDLE_CARBON'
export const SEND_CURRENT_CALL = 'SEND_CURRENT_CALL'
export const ENDING_CURRENT_CALL = 'ENDING_CURRENT_CALL'
export const RESET_CURRENT_CALL = 'RESET_CURRENT_CALL'
export const ACCEPT_CALL = 'ACCEPT_CALL'
export const SET_ACTIVE_CALL = 'SET_ACTIVE_CALL'
export const SET_USER_STATUS = 'SET_USER_STATUS'
export const SET_CARDS_DISABLED = 'SET_CARDS_DISABLED'
export const REMOTE_CALL_REJECTED = 'REMOTE_CALL_REJECTED'
export const CONNECTION_CLOSED = 'CONNECTION_CLOSED'
export const TIMEOUT_CALL = 'TIMEOUT_CALL'
export const REMOTE_INVITE = 'REMOTE_INVITE'
export const REMOTE_CANCEL = 'REMOTE_CANCEL'