import { AXIOS } from '@/plugins/axios'
import { API_URL } from '@/plugins/axios'

export const mediaResourcesService = {
    getMediaResourceList,
    getMediaResourceById,
    addMediaResource,
    updateMediaResource,
    deleteMediaResource,
    getCategoriesList,
    getSubCategoriesList,
    getStateList,
    uploadImage
}

function getMediaResourceList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/mediaResources/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

function getMediaResourceById (id) {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/mediaResources/'+id,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

// executes the request to add a new Contact
function addMediaResource (media,homes) {
    return AXIOS({
        method: 'post',
        url: API_URL +'/api/v1/mediaResources/create',
        data: media,
        params: homes.length !== 0 ? {'homesList': homes.toString()} : {},
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

// executes the request to delete an existing Contact
function deleteMediaResource (id) {
    return AXIOS({
        method: 'delete',
        url: API_URL +'/api/v1/mediaResources/delete',
        params: {id:id},
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}

// executes the request to update a Media Resource
function updateMediaResource (media, homes,sendNotification) {
    let homesList=[]
    if(homes.length!=0){homesList=homes.toString()}
    let params = {homesList:homesList,sendNotification:sendNotification}
    return AXIOS({
        method: 'put',
        url: API_URL + '/api/v1/mediaResources/update',
        params:params,
        data:media,
        headers: {
            Authorization: sessionStorage.getItem('token')
        }
    })
}



// Categories
function getCategoriesList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/mediaResources/categories/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

// SubCategories
function getSubCategoriesList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/mediaResources/subcategories/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

// State
function getStateList () {
    return AXIOS({
        method: 'get',
        url: API_URL + '/api/v1/mediaResources/state/list',
        headers: {
            Authorization: sessionStorage.getItem('token')
        },
    })
}

// State
function uploadImage (image) {
    let formData = new FormData();
    formData.append("file", image);
    return AXIOS({
        method: 'post',
        url: API_URL + '/api/v1/mediaResources/files/uploadImage',
        data:formData,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: sessionStorage.getItem('token')
        },
    })
}