<template >
  <v-list>
      <v-list-item @click="gestionar(menu)"
      v-for="menu in buildMenu" :key="menu.title" color="white">
        <v-list-item-content>
          <v-list-item-title  class="white--text">
            <v-icon class="text-center white--text" size="24px">{{ menu.icon }}</v-icon>
            {{ menu.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
   </v-list> 
</template>

<script>
import store from "@/store"
import router from '@/router'
import {EventBus} from '@/bus/bus'

export default {
  name: 'LateralMenu',
  data () {
    return {
      items: [
        {icon: 'mdi-video', title:'VIDEOLLAMADA', path:'/SeniorCare/Videollamada'},
        {icon: 'mdi-laptop-mac', title:'CANALES Y CONTENIDOS', path:'/SeniorCare/Contenidos'},
        {icon: 'mdi-home-group', title:'GESTION DE HOGARES', path:'/SeniorCare/HomesManagement'},
        {icon: 'mdi-account-group', title:'GESTION DE USUARIOS', path:'/SeniorCare/UsersManagement'},
        {icon: 'mdi-cog', title:'CONFIGURACION', path:''}
      ]
    }
  },
  mounted () {
  },
  methods: {
    gestionar(menu) {
      if (router.history.current.path !== menu.path) {
        if (store.getters['videocall/getCurrentCall'] != null) {
          var params = []
          params.type = "hangup"
          params.title = "Llamada en curso"
          params.body = "No es posible cambiar de pestaña durante una llamada. ¿Desea terminar la llamada en curso?"
          params.menu = menu.path
          EventBus.$emit('dialog', {params})
        } else {
          router.push(menu.path)
        }
      }
    },
    toHome () {
      this.$router.push('/SeniorCare/Videollamada')
    }
  },
  computed: {
    buildMenu(){
      let roles = []
      let menu  = []
      if(store.getters['backend/myself']){

        roles=store.getters['backend/myself'].backendUserAuthorities
        let enableIoT = store.getters['backend/myself'].tenant.enableIoT
        let customerUserId = store.getters['backend/myself'].customerUserId

        for (let i = 0; i < roles.length; i++) {
          if(roles[i].rol==='USUARIO') menu.push( {icon: 'mdi-video', title:'VIDEOLLAMADA', path:'/SeniorCare/Videollamada',father:false, subMenu:[]},)
          if(roles[i].rol==='PROFESIONAL'){
            if(menu.findIndex(i => i.title === "CANALES Y CONTENIDOS") === -1){
              menu.push({icon: 'mdi-laptop-mac', title:'CANALES Y CONTENIDOS', path:'/SeniorCare/Contenidos',father:false, subMenu:[]})
            }
            if(menu.findIndex(i => i.title === "CUADROS DE MANDO") === -1){
              if(enableIoT && customerUserId){
                menu.push({icon: 'mdi-monitor-dashboard', title:'CUADROS DE MANDO', path:'/SeniorCare/IOT',father:false, subMenu:[]})
              }
            }
          }
          if(roles[i].rol==='ADMINISTRADOR'){
            if(menu.findIndex(i => i.title === "CANALES Y CONTENIDOS") === -1){
              menu.push({icon: 'mdi-laptop-mac', title:'CANALES Y CONTENIDOS', path:'/SeniorCare/Contenidos',father:false, subMenu:[]})
            }
            if(menu.findIndex(i => i.title === "CUADROS DE MANDO") === -1){
              if(enableIoT && customerUserId){
                menu.push({icon: 'mdi-monitor-dashboard', title:'CUADROS DE MANDO', path:'/SeniorCare/IOT',father:false, subMenu:[]})
              }
            }
            menu.push(
                {icon: 'mdi-home-group', title:'GESTION DE HOGARES', path:'/SeniorCare/HomesManagement'},
                {icon: 'mdi-account-group', title:'GESTION DE USUARIOS', path:'/SeniorCare/UsersManagement'}
            )
          }
        }
      }
      return menu
    }
  }
}
</script>
<style>
</style>