<template>
  <v-dialog
      v-model="dialogSelectHomes"
      persistent
      max-width="2000px"
      hide-overlay
      transition="dialog-bottom-transition">
    <div>
      <v-card>
        <div class="mt-5 pl-5" v-if=this.pageTitle>
          <h1>{{this.pageTitle}}</h1>
        </div>
        <!-- Card con el filtro de búsqueda y el botón de alta -->
        <v-card-title>
          <v-text-field
              v-model="search"
              class="mr-6"
              append-icon="mdi-magnify"
              :label="$t('lFilter')"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <!-- Tabla -->
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="homes"
            :loading="this.homes.length === 0 && (!finished)"
            :loading-text="$t('lLoading')"
            :single-select="false"
            show-select
            class="elevation-1"
            :search="search"
            :footer-props="footerProps"
            item-key="idHome"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="getHomes()"
        >
          <!-- Personalizacion de campo enable -->
          <template v-slot:[`item.enable`]="{ item }">
            <span>
              {{ item.enable | formatBoolean }}
              <br>
            </span>
          </template>
          <!-- Personalizacion del mensaje de no hay datos disponibles -->
          <template slot="no-data">
            <v-alert :value="true" color="" icon="warning">
              No existen datos disponibles
            </v-alert>
          </template>
          <!-- footer de la tabla-->
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} {{$t('lFooterOf')}} {{props.itemsLength}}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              @click="cancel"
          >
            {{ $t('bCancel') }}
          </v-btn>
          <v-btn
              color="primary"
              @click="save"
          >
            {{ $t('bSave') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { backendService } from '@/services/backend.service'
import {i18n} from "@/plugins/i18n";
import logger from '@/mixins/logger'

export default {
  name:'HomesSelector',
  props: {
    dialogSelectHomes: {
      type: Boolean,
      default: false
    },
    selectedHomes:  {
      type: Array
    }
  },
  data () {
    return {
      finished: false,
      options: {
        page:1,
        itemsPerPage:20,
        totalItems:0,
        sortBy:['idHome'],
        sortDesc:[false],
        sorting: 'idHome' + ',' + 'asc'
      },
      homes: [],
      search: '',
      awaitingSearch:false,
      footerProps: {
        'items-per-page-text': this.$t('lRowsPerPage'),
        'items-per-page-all-text':this.$t('lAll'),
        'items-per-page-options': [5, 10, 20,-1]
      },
      selected:[],
      defaultHomes: [],
      headers: [
        { text: this.$t('lCode'), value: 'idHome', align: 'center', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lName'), value: 'homeName', align: 'start', sortable: true, filterable: true, width: '20%' },
        { text: this.$t('lAdress'), value: 'address', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lLocality'), value: 'locality', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lProvince'), value: 'province', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lPostalCode'), value: 'postalCode', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lTelephone'), value: 'telephone', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lEnable'), value: 'enable', align: 'start', sortable: true, filterable: true, width: '10%' },
      ],
    }
  },
  methods: {
    getHomes () {
      this.homes=[]
      this.finished=false
      this.sorting () // Info de ordenar columnas
      backendService.getHomesListPagination(this.search,this.options, true)
          .then((response) => {

            // Gestionamos los elementos totales
            let count = parseInt(response.headers["x-total-count"])
            this.totalItems= count

            // Obtener Informacion
            this.homes = response.data
            this.finished = true
          }).catch((error) => {
          logger.log(error)
          this.finished=true
         })
    },
    sorting () { // Ordenar columnas para la paginacion y busqueda en back
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        this.options.sorting = this.options.sortBy[0] + ',' + (this.options.sortDesc[0] ? 'desc' : 'asc')
      } else {
        this.options.sorting = 'idHome' + ',' + 'asc'
      }
    },
    cancel(){
      this.$emit('cancel')
    },
    save(){
      // let result = this.selected.map(a => a.idUser);
      this.$emit('save', this.selected)
      this.selected = this.defaultHomes
    }
  },
  computed: {
    pageTitle () {
      return this.$t('lSelectHome')
    }
  },
  watch: {
    selectedHomes: function(){
      this.selected = this.selectedHomes
    },
    search:{ // Paginacion
      handler () {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getHomes()
            this.awaitingSearch = false;
          }, 1750); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    }
  },
  mounted () {
    this.getHomes()
  },
  filters: {
    formatBoolean (boolean) {
      switch (boolean) {
        case true:
          return i18n.t('lYes')
        case false:
          return i18n.t('lNo')
        default:
          return i18n.t('lNo')
      }
    }
  },
}
</script>
