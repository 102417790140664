<template>
    <v-card>
      <h3 style="padding-top: 0.8em; padding-left: 0.8em; font-weight: normal;">{{title}}</h3>
      <v-layout>
        <v-flex xs5 style="padding: 0.8em;">
          <v-avatar
              size="80"
              tile
              height="90%"
              width="90%"
              :color="getAvatarColor(contact.nickname)"
          >
            <v-img v-if="contact.url !== 'NA'" width="80px" height="100%"  :src="contact.url"  class="ma-0 pa-0"></v-img>
            <h1 v-else class="white--text" style="font-size: 8em;font-weight:normal">{{contact.nickname.substring(0,1)}}</h1>
          </v-avatar>
        </v-flex>
        <v-flex xs7>
          <v-layout column justify-space-between style="padding: 0.8em 1.3em;">
            <v-card-title
                v-text="contact.nickname">
            </v-card-title>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider light></v-divider>
      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
            icon
            color="gray lighten-2"
            text
            @click="rejectCall()"
           >
          <v-icon class="ma-1" color="red">mdi-square</v-icon>
          RECHAZAR
          </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            icon
            color="gray lighten-2"
            text
            @click="acceptCall()"
            >
          <v-icon class="ma-1" color="green">mdi-square</v-icon>
          ACEPTAR
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
</template>

<script>
import {EventBus} from "@/bus/bus";

export default {
  data() {
    return {
      title:'',
      contact:{}
    };
  },
  methods: {
    //Get Avatar Color
    getAvatarColor(nickName){
      let avatarBackgroundPalette=["#3E7690","#8064A7","#49198D","#083545"]
      if (nickName){
        let nickNameSize = nickName.length
        return avatarBackgroundPalette[nickNameSize % avatarBackgroundPalette.length]
      }
      else{
        return 'indigo'
      }
    },
    acceptCall(){
      var params={}
      params.callStatus='accept'
      params.contact=this.contact
      EventBus.$emit('entryCallDialogSubmit', {params});
      this.$emit('submit')
    },
    rejectCall(){
      var params={}
      params.callStatus='reject'
      params.contact=this.contact
      EventBus.$emit('entryCallDialogSubmit', {params});
      this.$emit('submit')
    }
  },
  mounted() {
  },
  created() {
    EventBus.$on('entryCallDialogOpen',(received)=>{
      this.contact=received.message.contact
      this.title=received.message.title
    })
    EventBus.$on('entryCallDialogClose',(received)=>{
      this.$emit('submit')
    })
  },
  beforeDestroy() {
    EventBus.$off('entryCallDialogOpen')
    EventBus.$off('entryCallDialogClose')
  },
  computed: {
  },
};
</script>