<template>
  <div>
    <div class="ma-5" >
      <v-card tile elevation="4">
        <!-- Card con el filtro de búsqueda y el botón de alta -->
        <v-card-title>
          <v-text-field
              v-model="search"
              class="mr-6"
              append-icon="mdi-magnify"
              :label="$t('lFilter')"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
              right
              hide-details
              color="primary"
              @click="addItem()"
          >{{ $t('bAdd') }}
          </v-btn>
        </v-card-title>

        <!-- Data table con configuración de cabeceras, elementos, filtro de búsqueda, loading y texto de carga y de footer personalizados-->
        <v-data-table
            :headers="headers"
            :items="resourcesList"
            :search="search"
            :loading="this.resourcesList.length === 0 && (!finished)"
            :loading-text="$t('lLoading')"
            sort-by="id"
            :footer-props="footerProps"
        >
          <!-- Carga de dialog para alta y edición, con texto título y botón personalizado según acción de carga -->
          <template v-slot:top>
            <v-dialog v-model="dialogSave" persistent max-width="3000px">
              <v-form ref="form">
                <v-card tile>
                  <v-card-title>
                    <span class="headline">{{ itemDialogTitle }}</span></v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>{{$t('lName')}}:</p>
                          <v-text-field
                              v-model.trim="editedItem.name"
                              type="text"
                              :rules="[rules.maxLength300,rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" class="justify-content-center">
                          <p>{{$t('lImageURL')}}:</p>
                          <v-img v-if="editedItem.imageURL" width="80px" height="80px"  :src="editedItem.imageURL"  @click="dialogImagenes = !dialogImagenes" class="ma-0 pa-0"></v-img>
                          <v-btn v-else color="primary" @click="dialogImagenes = !dialogImagenes">{{ $t('lSelectImagen') }}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>{{$t('lResourceURL')}}:</p>
                          <v-text-field
                              v-model.trim="editedItem.resourceURL"
                              type="text"
                              :rules="[rules.maxLength300]"
                              :disabled="disableResourceURLField"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>{{$t('lCategory')}}:</p>
                          <v-combobox
                              :label="$t('lCategory')"
                              v-model="editedItem.mediaResourcesCategories"
                              :items="categories"
                              background-color="white"
                              solo
                              :rules="[rules.required]"
                              return-object
                              item-text="categoryName"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>{{$t('lSubcategory')}}:</p>
                          <v-combobox
                              :label="$t('lSubcategory')"
                              v-model="editedItem.mediaResourcesSubcategories"
                              :items="subCategories"
                              background-color="white"
                              solo
                              :rules="[rules.required]"
                              return-object
                              item-text="subcategoryName"
                          >
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" lg="2" md="2">
                          <p>{{$t('lState')}}:</p>
                          <v-combobox
                              :label="$t('lState')"
                              v-model="editedItem.mediaResourcesStates"
                              :items="states"
                              background-color="white"
                              solo
                              :rules="[rules.required]"
                              return-object
                              item-text="stateName"
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" lg="2" md="2" >
                          <p>{{$t('lDescription')}}:</p>
                          <v-text-field
                              v-model.trim="editedItem.description"
                              :rules="[rules.maxLength300,rules.required]"
                              type="text"
                          ></v-text-field>
                        </v-col>                                                
                        <v-col cols="12" sm="12" lg="2" md="2">
                          <p>{{$t('lCreationUser')}}:</p>
                          <v-combobox
                              :label="$t('lCreationUser')"
                              v-model="editedItem.creationUser"
                              :items="userListAdmin"
                              background-color="white"
                              solo
                              :rules="[rules.required]"
                              return-object
                              item-text="user"
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" lg="3" md="3" >
                          <p>Asignar usuarios:</p>
                          <v-btn-toggle style="flex-direction: column">
                            <v-btn
                                rounded
                                style="margin-top: 5px;bottom: 5px;"
                                color="success"
                                dense
                                @click="selectAllHomes()"
                            >
                              {{ $t('lSelectAllHomes') }}
                            </v-btn>
                            <v-btn
                                rounded
                                style="margin-top: 5px;bottom: 5px;"
                                color="primary"
                                dense
                                @click="dialogSelectHogar = !dialogSelectHogar"
                            >
                              {{ $t('lSelectHome') }}
                            </v-btn>
                            <v-btn
                                rounded
                                style="margin-top: 5px;bottom: 5px;"
                                color="secondary"
                                dense
                                @click="dialogSelectUsers = !dialogSelectUsers"
                            >
                              {{ $t('lSelectUser') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col cols="12" sm="12" lg="2" md="2">
                          <p v-if="editedItem.id!==-1 && editedItem.mediaResourcesCategories.idCategory === 4" >{{$t('nReSendNotification')}}</p>
                          <v-checkbox
                              v-if="editedItem.id!==-1 && editedItem.mediaResourcesCategories.idCategory === 4"
                              v-model="sendNotification"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" lg="3" md="3"  style="min-height: 200px">
                          <p>{{$t('lSelectStartDate')}}:</p>
                          <CtkDateTimePicker
                              v-model="editedItem.startDate"
                              color="blue"
                              locale="es"
                              :label="$t('lStartDate')"
                              :format="'YYYY-MM-DD HH:mm'"
                              :min-date="getActualDate().toISOString()"
                              :max-date="'2030-01-01 00:00:00'"
                          />
                          <p></p>
                          <p>{{$t('lSelectendDate')}}:</p>
                          <CtkDateTimePicker
                              v-model="editedItem.endDate"
                              color="blue"
                              locale="es"
                              :label="$t('lendDate')"
                              :format="'YYYY-MM-DD HH:mm'"
                              :min-date="editedItem.startDate"
                              :max-date="'2030-01-01 00:00:00'"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close">{{ $t('bCancel') }}</v-btn>
                    <v-btn color="primary" @click="saveItemConfirm">{{ $t('bSave') }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-form>
              <!-- Overlay and loading in save -->
              <v-overlay v-show="overlay" opacity="0.75">
                <v-container fluid fill-height >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                        indeterminate color="primary">
                    </v-progress-circular>
                  </v-layout>
                </v-container>
              </v-overlay>
              <!--              -->
            </v-dialog>
            <!---->
            <!-- Dialogo para Subir imagenes o añadir imagenes-->
            <template>
              <v-dialog v-model="dialogImagenes" persistent max-width="1000px">
                <v-card tile>
                  <v-card-title>
                    <span class="headline"> {{ $t('lImageURL') }}</span></v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-flex>
                        <v-text-field
                            v-model.trim="editedItem.imageURL"
                            :label="$t('lImageURLExternal')"
                            type="text"
                            :rules="[rules.maxLength300]"
                        ></v-text-field>
                        <v-file-input show-size
                                      counter
                                      chips
                                      accept="image/*"
                                      label="Selecionar Imagen del dispositivo"
                                      ref="myfile" v-model="imageFile">
                        </v-file-input>
                      </v-flex>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="closeImageSelector">{{ $t('bCancel') }}</v-btn>
                    <v-btn color="primary" @click="acceptImageSelector">{{ $t('bSave') }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!---->
            <!-- Dialogo para la asignacion de homes a recursos-->
            <template>
            <v-dialog v-model="dialogSelectHogar" persistent max-width="1000px">
                <v-card tile>
                  <v-card-title>
                    <span class="headline"> {{ $t('lSelectHome') }}</span></v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-checkbox
                          v-for="(home) in homesList"
                          multiple
                          v-model="selectedHomes"
                          :key="home.idHome"
                          :value="home.idHome"
                          :label="home.homeName"/>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="closeHomeSelector">{{ $t('bCancel') }}</v-btn>
                    <v-btn color="primary" @click="acceptHomeSelector">{{ $t('bSave') }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </template>
            <!-- Dialogo para Emisión -->
            <template>
              <v-dialog fullscreen v-model="dialogEmision">
                <v-row>
                <v-col cols="12" sm="12" ma-0 pa-0>
                  <div id="jaas-container" style="height: 100%; width: 100%; min-height:98vh; min-width:100vw;"></div>
                </v-col>
                </v-row>
              </v-dialog>
            </template>

            <!-- Dialogo para la asignacion de usuarios a recursos-->
            <userSelector :dialogSelectUsers.sync="dialogSelectUsers"
                          :selectedUsers.sync="editedItem.backendUsersAsigned"
                          :selectedFilterUserType.sync="userTypeSelectorFilter"
                             @cancel="closeUserSelector"
                             @save="acceptUserSelector"
                             class="elevation-2" />
            <!--          -->
          </template>
          <!--          -->
          <!-- Personalización de columna de acciones con los botones de edición y eliminar -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.mediaResourcesCategories.categoryName === enableRoom" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="green"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="startRoom(item)"
                >
                  mdi-play
                </v-icon>
              </template>
              <span>{{ $t('ttPlay') }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.mediaResourcesCategories.categoryName === livestreaming" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="red"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="empezarEmisionEnDirecto(item)"
                >
                  mdi-youtube
                </v-icon>
              </template>
              <span>{{ $t('ttPlay') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="secondary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('ttModify') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="error"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteConfirmDialog(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t('ttDelete') }}</span>
            </v-tooltip>
          </template>
          <!--  Personalizacion del icono de la imagen -->
          <template v-slot:item.image="{ item }">
            <div class="p-2">
              <v-img :src="item.imageURL" :alt="item.imageURL" height="50px" width="50px"></v-img>
            </div>
          </template>
          <!-- Personalizacion de la lista de usuarios asignados -->
          <template v-slot:[`item.users`]="{ item }">
            <span v-for="user in item.backendUsersAsigned" :key="user.jabberId">
              {{ user.jabberId }}
              <br>
            </span>
          </template>
          <!-- Personalizacion de url del recurso -->
          <template v-slot:[`item.resourceURLMinified`]="{item}">
            <div style="overflow: hidden;text-overflow: ellipsis;max-width: 10vw;">{{ item.resourceURL }}</div>
          </template>
          <!-- Personalizacion del mensaje de no hay datos disponibles -->
          <template slot="no-data">
            <v-alert :value="true" color="" icon="warning">
              No existen datos disponibles
            </v-alert>
          </template>
          <!-- footer de la tabla-->
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} {{$t('lFooterOf')}} {{props.itemsLength}}
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mediaResourcesService } from '@/services/mediaResources.service'
import { backendService } from '@/services/backend.service'
import validations from '@/mixins/validations'
import userSelector from '@/components/UserSelector'
import CtkDateTimePicker from 'vue-ctk-date-time-picker'
import store from '@/store'
import logger from '@/mixins/logger'
import {manageErrors,manageYoutubeErrors} from "@/mixins/errorAlertManagement";
import {VUE_APP_CONTENTS_TOPIC,VUE_APP_UPDATEAPP_TOPIC,VUE_APP_PUBUSUB_PREFIX} from '@/services/xmpp.service.js'

export default {
  name: "CanalesContenidos",
  components: {
    userSelector,
    CtkDateTimePicker
  },
  data() {
    return {
      finished: true,
      resourcesList:[],
      homesList:[],
      userList:[],
      search: '',
      userTypeSelectorFilter:'PATIENT',
      api: {},
      dialogSave: false,
      dialogSelectHogar: false,
      dialogEmision: false,
      dialogImagenes: false,
      selectedHomes:[],
      dialogSelectUsers: false,
      selectedUsers:[],
      rules: validations,
      headers: [
        { text: this.$t('lName'), value: 'name', align: 'start',sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lImage'), value: 'image', align: 'start', sortable: false, filterable: false, width: '10%' },
        { text: this.$t('lResourceURL'), value: 'resourceURLMinified', align: 'left', sortable: true, filterable: true, width: '5%' },
        { text: this.$t('lDescription'), value: 'description', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lCategory'), value: 'mediaResourcesCategories.categoryName', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lSubcategory'), value: 'mediaResourcesSubcategories.subcategoryName', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lState'), value: 'mediaResourcesStates.stateName', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lCreationUser'), value: 'creationUser.user', align: 'start', sortable: true, filterable: true, width: '5%' },
        { text: this.$t('lStartDate'), value: 'startDate', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lendDate'), value: 'endDate', align: 'start', sortable: true, filterable: true, width: '10%' },
        { text: this.$t('lActions'), value: 'actions', align: 'right', sortable: false, filterable: true, width: '15%' }
      ],
      footerProps: {
        'items-per-page-text': this.$t('lRowsPerPage'),
        'items-per-page-all-text':this.$t('lAll'),
        'items-per-page-options': [5, 10, 20,-1]
      },
      editedIndex: -1,
      editedItem: {
        id:-1,
        imageURL:'',
        resourceURL: '',
        description: '',
        mediaResourcesdditionals: [],
        mediaResourcesCategories: {
          idCategory:7,
          categoryName:"SIN_ASIGNAR"
        },
        mediaResourcesSubcategories: {
          idSubcategory:4,
          subcategoryName:"SIN ASIGNAR"
        },
        mediaResourcesStates: {
          idState:2,
          stateName: 'ACTIVADO',
        },
        creationUser: {
          idUser: store.getters['backend/myself'].idUser,
          user: store.getters['backend/myself'].user,
        }, 
        backendUsersAsigned:[],
        startDate:this.getActualDate().toISOString(),
        endDate:null
      },
      defaultItem: {
        id:-1,
        imageURL:'',
        resourceURL: '',
        description: '',
        mediaResourcesCategories: {
          idCategory:7,
          categoryName:"SIN_ASIGNAR"
        },
        mediaResourcesSubcategories: {
          idSubcategory:4,
          subcategoryName:"SIN ASIGNAR"
        },
        mediaResourcesStates: {
          idState:2,
          stateName: 'ACTIVADO',
        },
        creationUser: {
          idUser: store.getters['backend/myself'].idUser,
          user: store.getters['backend/myself'].user,
        }, 
        backendUsersAsigned:[],
        startDate:this.getActualDate().toISOString(),
        endDate:null
      },
      oldItem:{},
      selection:[],
      enableRoom: 'ACTIVIDADES_GRUPO',
      livestreaming: 'LIVE_YOUTUBE',
      streamingInitiated: false,
      stream: {},
      categories:[],
      subCategories:[],
      states:[],
      imageFile:null,
      overlay:false,
      sendNotification:false,
      participantsNumber: 0,
      lastParticipantsNumber: 0,
      beforeLastParticipantNumber: 0
    };
  },
  methods: {
    selectAllHomes() {
      this.selection = this.homesList.map(a => a.idHome)
      this.selectedHomes = this.homesList.map(a => a.idHome)
    },
    editItem(item) {
      this.clearValidations()
      this.editedIndex = this.resourcesList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if(this.editedIndex === -1)this.editedItem = Object.assign({}, this.defaultItem)
      if(this.editedIndex !== -1) this.oldItem = Object.assign({}, this.editedItem)
      this.dialogSave = true
    },
    validate() {
      return this.$refs.form.validate()
    },
    clearValidations() {
      if (this.$refs.form) {
        return this.$refs.form.reset()
      }
    },
    close() {
      this.dialogSave = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.clearValidations()
    },
    closeHomeSelector() {
      this.selection = []
      this.dialogSelectHogar = false
    },
    acceptHomeSelector() {
      this.selection = this.selectedHomes
      this.dialogSelectHogar = false
    },
    closeUserSelector() {
      this.dialogSelectUsers = false
    },
    acceptUserSelector(selection) {
      var userlist =[]
      if(selection){
        if(selection.length >0){
          for(var i=0;i<selection.length;i++){
            if(selection[i].backendUser){
              userlist.push(selection[i].backendUser)
            }
          }
          // userlist=selection.map(a => a.backendUser)
        }
      }
      this.editedItem.backendUsersAsigned = userlist
      this.dialogSelectUsers = false
    },
    // Dialogo confirmacion eliminar
    deleteConfirmDialog(item){
      this.$dialog.confirm({
        title: 'Eliminar Recurso',
        text:'¿Esta seguro que desea eliminar este recurso?' ,
        persistent: true,
        actions: {
          false: 'No',
          true: {
            color: 'black',
            text: 'Si',
            handle: () => {
              this.deleteItem(item)
            }
          }
        }
      })
    },
    // Eliminar
    deleteItem(item) {
      this.editedIndex = this.resourcesList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      mediaResourcesService.deleteMediaResource(item.id)
        .then((response) => {
            // pubsub
            let topic = process.env.VUE_APP_CONTENTS_TOPIC;
            let pubSubServer = process.env.VUE_APP_PUBUSUB_PREFIX+'.'+store.getters['backend/myself'].tenant.xmppDomain;
            let messageBody = {action: 'delete', id: this.editedItem.id, timestamp:new Date().getTime()}
            store.dispatch('xmpp/XMPP_PUBLISHTO_TOPIC', {pubSubServer, topic, messageBody}).then(response => {
              if(this.editedItem.mediaResourcesCategories.categoryName === this.livestreaming){
                this.eliminarEmisionEnDirecto()
              }
              this.init()
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              this.clearValidations()
          })
        })
        .catch((error) => {
          manageErrors(this,error).finally(() => {
            this.init()
          })
        }
      )
    },
    // Crear o editar
    saveItemConfirm() {
      if (this.validate()) {
        if(this.validarCampos()){
          this.overlay= true;
          if (this.editedItem.id === -1) { // Crear
            //Si es emision -> Hacer un encodeURI del nombre
            if (this.editedItem.mediaResourcesCategories.categoryName === this.enableRoom) {
              //
              let dt = new Date().getTime();
              let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = (dt + Math.random()*16)%16 | 0
                dt = Math.floor(dt/16)
                return (c=='x' ? r :(r&0x3|0x8)).toString(16)
              });
              //
              this.editedItem.resourceURL = "https://8x8.vc/" + store.getters['backend/myself'].tenant.tenant8x8 + "/" + uuid
              this.saveMediaResource()
            }
            else if(this.editedItem.mediaResourcesCategories.categoryName === this.livestreaming){
              this.submitYoutubeLiveStreaming()
            }
            else{
              this.saveMediaResource()
            }
          }
          else{ // Editar
            //Si es emision -> Hacer un encodeURI del nombre
            if (this.editedItem.mediaResourcesCategories.categoryName === this.enableRoom) {
              if(this.editedItem.resourceURL){
                //
                let dt = new Date().getTime();
                let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                  let r = (dt + Math.random()*16)%16 | 0
                  dt = Math.floor(dt/16)
                  return (c=='x' ? r :(r&0x3|0x8)).toString(16)
                });
                //
                this.editedItem.resourceURL = "https://8x8.vc/" + store.getters['backend/myself'].tenant.tenant8x8 + "/" + uuid
              }
              this.updateMediaResource()
            }
            else if(this.editedItem.mediaResourcesCategories.categoryName === this.livestreaming){
              this.editarEmisionEnDirecto()
            }
            else{
              this.updateMediaResource()
            }
          }
        }
      }
    },
    validarCampos(){
      if(!this.editedItem.startDate){
        this.$dialog.confirm({
          title: 'Complete los campos',
          text:'La fecha de inicio no puede ser nula' ,
          persistent: true,
          actions: {
            true: {
              color: 'black',
              text: 'Ok'
            }
          }
        })
        return false;
      }
      if(this.editedItem.resourceURL === ""){
        if(!(this.editedItem.mediaResourcesCategories.categoryName === this.livestreaming
          || this.editedItem.mediaResourcesCategories.categoryName === this.enableRoom)){
            this.$dialog.confirm({
              title: 'Complete los campos',
              text:'La url del recurso no puede ser nula' ,
              persistent: true,
              actions: {
                true: {
                  color: 'black',
                  text: 'Ok'
                }
              }
            })
            return false;
        }
        else{
          return true
        }
      }
      if(this.editedItem.mediaResourcesCategories.idCategory === 7){
        this.$dialog.confirm({
          title: 'Complete los campos',
          text:'Seleecione una categoria para el recurso' ,
          persistent: true,
          actions: {
            true: {
              color: 'black',
              text: 'Ok'
            }
          }
        })
        return false;
      }
      else{
        return true
      }
    },
    saveMediaResource(){
    mediaResourcesService.addMediaResource(this.editedItem, this.selection)
      .then((response) => {
        this.xmppPublishRefreshAllItems()
        this.overlay=false;
        this.init()
        this.close()
      })
      .catch((error) => {
        manageErrors(this,error).finally(() => {
          this.init()
          this.overlay=false;
          this.close()
        })
      })
    },
    xmppPublishRefreshAllItems(){
      // publish to xmpp node for update contents in tv
      var topic = VUE_APP_CONTENTS_TOPIC
      let pubSubServer = VUE_APP_PUBUSUB_PREFIX+ '.' + store.getters['backend/myself'].tenant.xmppDomain
      var messageBody = {action: 'refreshall', id: -1, timestamp:new Date().getTime()}
      this.$store.dispatch('xmpp/XMPP_PUBLISHTO_TOPIC', {
        pubSubServer: pubSubServer,
        topic:topic,
        messageBody: messageBody
      }).then((response) => {
        logger.log(response)
      }).catch(err => {
        logger.log(err)
      })
    },
    xmppPublishRefreshItemStateChange(item){
      // publish to xmpp node for update contents in tv
      var topic = VUE_APP_CONTENTS_TOPIC
      let pubSubServer = VUE_APP_PUBUSUB_PREFIX+ '.' + store.getters['backend/myself'].tenant.xmppDomain
      var messageBody = {action: 'statechange', id: item.id, timestamp:new Date().getTime(), state:item.mediaResourcesStates.stateName}
      this.$store.dispatch('xmpp/XMPP_PUBLISHTO_TOPIC', {
        pubSubServer: pubSubServer,
        topic:topic,
        messageBody: messageBody
      }).then((response) => {
        logger.log(response)
      }).catch(err => {
        logger.log(err)
      })
    },
    updateMediaResource(){
      mediaResourcesService.updateMediaResource(this.editedItem, this.selection,this.sendNotification)
      .then((response) => {
        /*
        if(this.editedItem.mediaResourcesStates.stateName !== this.oldItem.mediaResourcesStates.stateName){
          this.xmppPublishRefreshItemStateChange(this.editedItem)
        }
        else{
          this.xmppPublishRefreshAllItems()
        }
        */

        this.xmppPublishRefreshAllItems()
        this.overlay=false;
        this.init()
        this.close()
      })
      .catch((error) => {
        manageErrors(this,error).finally(() => {
          this.init()
          this.overlay=false;
          this.close()
        })
      })
    },
    updateMediaResourceState(){
      mediaResourcesService.updateMediaResource(this.editedItem, this.selection,this.sendNotification)
      .then((response) => {
        this.xmppPublishRefreshItemStateChange(this.editedItem)
        this.overlay=false;
      })
      .catch((error) => {
        manageErrors(this,error).finally(() => {
          this.overlay=false;
        })
      })
      console.log('TRAZA', 'Broadcast!')
    },
    addItem() {
      this.editItem(this.editedItem)
    },
    init() {
      this.resourcesList = []
      this.homesList = []
      this.userList = []
      this.userListAdmin = []
      this.selectedHomes = []
      this.selectedUsers = []
      this.selection = []
      // Rellenamos la tabla de recursos
      this.finished=false
      this.streamingInitiated=false;
      mediaResourcesService.getMediaResourceList().then((response) => {
        this.resourcesList = response.data
        this.finished=true
      })
      // Rellenamos la lista de homes
      backendService.getHomesList().then((response) => {
        this.homesList = response.data
      })
      // Rellenamos la lista de usuarios de la app
      backendService.getUserList().then((response) => {
        this.userList = response.data
      })
      backendService.getUserListAdmin().then((response) => {
        this.userListAdmin = response.data
      })

      this.categories = []
      this.subCategories = []
      this.states = []
      // Rellenamos las categorias
      mediaResourcesService.getCategoriesList().then((response) => {
        //Parcheamos canales relacionados con Youtube 
        let _list = []
        for(let i = 0; i < response.data.length; i++) {
          if(response.data[i].categoryName !== "CANAL_YOUTUBE") _list.push(response.data[i])
        }
        this.categories = _list
        //this.categories = response.data
      })
      // Rellenamos las subcategorias
      mediaResourcesService.getSubCategoriesList().then((response) => {
        this.subCategories = response.data
      })
      // Rellenamos los estados
      mediaResourcesService.getStateList().then((response) => {
        let _list = []
        for(let i = 0; i < response.data.length; i++) {
          if (response.data[i].stateName !== "EN DIRECTO") _list.push(response.data[i])
        }
        this.states = _list
      })
      // Limpiamos las validaciones
      this.clearValidations()
    },
    /////////////////////////////////////////////////
    //Emision and Jitsi Meeting
    loadJitsiApi(room, jwt) {
      //https://8x8.vc/[magic_cookie]/[room]
      const options = {
        roomName: store.getters['backend/myself'].tenant.tenant8x8 + "/" + room,
        parentNode: document.querySelector('#jaas-container'),
        jwt: jwt,
        width: "100%",
        height: "100%",
        configOverwrite: {prejoinPageEnabled: false, defaultLanguage: "es"},
        interfaceConfigOverwrite: {
          HIDE_INVITE_MORE_HEADER: true,
          TOOLBAR_BUTTONS: ['microphone', 'camera', 'desktop', 'embedmeeting', 'fullscreen', 'fodeviceselection', 'hangup', 'profile', 'recording', 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'videoquality', 'filmstrip', 'invite', 'shortcuts', 'tileview', 'videobackgroundblur', 'mute-everyone'],
        }
        // interfaceConfigOverwrite: {prejoinPageEnabled: false,}
      };
      const api = new window.JitsiMeetExternalAPI("8x8.vc", options);
      this.api = api;
      api.addEventListener('videoConferenceLeft', this.leaveRoom);
      api.addEventListener('participantLeft', this.getParticipants);
      api.addEventListener('participantJoined', this.getParticipants);
      api.addEventListener('videoConferenceJoined', this.initStreaming);
    },

    initStreaming() {
      let streamKey = null;
      let broadcastId = null;
      this.getParticipants();
      if(this.editedItem.mediaResourcesAdditionals){
        for (let i = 0; i < this.editedItem.mediaResourcesAdditionals.length; i++) {
          if (this.editedItem.mediaResourcesAdditionals[i].key == 'streamKey') streamKey = this.editedItem.mediaResourcesAdditionals[i].value;
          if (this.editedItem.mediaResourcesAdditionals[i].key == 'broadcastId') broadcastId = this.editedItem.mediaResourcesAdditionals[i].value;
        }
      }
      if (streamKey && broadcastId && !this.streamingInitiated) {
        logger.log('Stream', this.editedItem);
        logger.log('streamKey', streamKey);
        logger.log('broadcastId', broadcastId);
        this.api.startRecording({
          mode: "stream",
          youtubeStreamKey: streamKey, //the youtube stream key.
          youtubeBroadcastID: broadcastId//the youtube broacast ID.
        });
      }
    },

    startRoom(item) {
      this.editedItem = Object.assign({}, item)
      console.log('TRAZA', item)
      mediaResourcesService.getMediaResourceById(item.id).then(response => {
        if (response.data.mediaResourcesStates.idState != 1) {
          this.editedItem.mediaResourcesStates={idState: 1, stateName: "EN DIRECTO"}
          this.updateMediaResourceState()
        }
        this.dialogEmision = true
        setTimeout(() => {
          this.load()
        }, 1000);
      }).catch (e => {
        manageErrors(this,e).finally(() => {
          this.overlay=false;
        })
      }) 
    },

    getParticipants() {
        this.beforeLastParticipantNumber = this.lastParticipantsNumber;
        this.lastParticipantsNumber = this.participantsNumber;
        this.participantsNumber = this.api.getParticipantsInfo().length;
        console.log('TRAZA', 'b: '+this.beforeLastParticipantNumber+' l: '+this.lastParticipantsNumber+' p: '+this.participantsNumber);
    },

    leaveRoom() {
        const close = this.participantsNumber == 1 && this.lastParticipantsNumber != this.participantsNumber

        this.dialogEmision = false;
        if ((this.editedItem.mediaResourcesCategories.categoryName === this.enableRoom || this.editedItem.mediaResourcesCategories.categoryName === this.livestreaming) && close && this.editedItem.mediaResourcesStates.idState == 1) {
          this.editedItem.mediaResourcesStates={idState: 2, stateName: "ACTIVADO"}
          this.updateMediaResourceState()
        } else {
          this.init()
        }
        const api = this.api
        api.removeEventListener(['videoConferenceLeft']);
        api.removeEventListener(['videoConferenceJoined']);
        api.removeEventListener(['participantLeft']);
        api.removeEventListener(['participantJoined']);
        api.stopRecording('stream');
        api.dispose();
        this.api = {};
        const el = document.getElementById('jitsiConferenceFrame0');
        if (el != null) el.parentNode.removeChild(el);
      
    },
    load() {
      let jwt = store.getters['backend/jwt']
      let room;
      if (this.editedItem.mediaResourcesCategories.categoryName === this.livestreaming) {
        //let dt = new Date().getTime();
        //let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        //  let r = (dt + Math.random()*16)%16 | 0
        //  dt = Math.floor(dt/16)
        //  return (c=='x' ? r :(r&0x3|0x8)).toString(16)
        //});
        //room = uuid
        for (let i = 0; i < this.editedItem.mediaResourcesAdditionals.length; i++) {
          if (this.editedItem.mediaResourcesAdditionals[i].key == 'jitsiRoom') room = this.editedItem.mediaResourcesAdditionals[i].value;
        }
      } else {
        room = this.editedItem.resourceURL.split('8x8.vc/')[1].split('/')[1]
      }
      logger.log('room', room)
      logger.log('jwt', jwt)
      this.loadJitsiApi(room, jwt)
    },
/////////////////////////////////////////////////
    // Images
    submitImageFile() {
      if (this.imageFile) {
        logger.log(this.imageFile)
        mediaResourcesService.uploadImage(this.imageFile).then(response => {
          this.editedItem.imageURL=''
          this.editedItem.imageURL=response.data
        }).catch(error => {
          logger.log({error})
        })
      }
    },
    closeImageSelector() {
      this.dialogImagenes = false
      this.imageFile=null
    },
    acceptImageSelector() {
      this.submitImageFile()
      this.dialogImagenes = false
      this.imageFile=null
    },
/////////////////////////////////////////////////
    // Youtube livestreaming
    submitYoutubeLiveStreaming(){
      // Api Instance
      this.$gapi.getGapiClient().then((gapi) => {
        logger.log('Youtube Api instance', gapi)
        // Login
        this.$gapi.login().then((response) => {
          logger.log('Youtube Login ', gapi)
          this.programarEmisionEnDirecto()
        }).catch(error => {
          manageYoutubeErrors(this,error).finally(() => {
            this.overlay=false;
          })
          logger.log('gapi error login',error)
        })
        //
      }).catch(error => {
        manageYoutubeErrors(this,error).finally(() => {
          this.overlay=false;
        })
        logger.log('gapi error instance',error)
      })
    },

    programarEmisionEnDirecto(){
      let youtubeStartDate = new Date(this.editedItem.startDate).toISOString()
      let youtubeEndDate = new Date(this.editedItem.endDate).toISOString()
      let youtubeTittle = this.editedItem.name
      let youtubeDescription = this.editedItem.description
      this.$gapi.getGapiClient().then(gapi => {
        let that = this;
        gapi.client.youtube.liveBroadcasts.insert({
          "part": [
            "snippet,contentDetails,status"
          ],
          "resource": {
            "snippet": {
              "title": youtubeTittle,
              "description":youtubeDescription,
              "scheduledStartTime": youtubeStartDate,
              "scheduledEndTime": youtubeEndDate
            },
            "contentDetails": {
              "enableClosedCaptions": true,
              "enableContentEncryption": true,
              "enableDvr": true,
              "enableEmbed": false,
              "recordFromStart": true,
              "startWithSlate": true,
              "enableAutoStart": true,
              "enableAutoStop": true
            },
            "status": {
              "privacyStatus": "unlisted",
              "selfDeclaredMadeForKids":true
            }
          }
        })
        .then(response => {
          logger.log('liveBroadcasts.insert response ok', response)
          that.stream.broadcastId = response.result.id
          gapi.client.youtube.liveStreams.insert({
            "part": [
            "snippet,cdn,contentDetails,status"
            ],
            "resource": {
              "snippet": {
                "title": "Your new video stream's name",
                "description": "A description of your video stream. This field is optional."
              },
              "cdn": {
                "frameRate": "60fps",
                "ingestionType": "rtmp",
                "resolution": "1080p"
              },
              "contentDetails": {
                "isReusable": true
              }
            }          
          })
          .then(response => {
            logger.log('liveStreams.insert response ok', response)
            that.stream.streamId= response.result.id
            that.stream.streamKey = response.result.cdn.ingestionInfo.streamName;
            logger.log('that.stream', that.stream)
            gapi.client.youtube.liveBroadcasts.bind({
              "id": that.stream.broadcastId,
              "part": [
                "snippet"
              ],
              "streamId": that.stream.streamId
            })
            .then(response => {
              logger.log('Youtube Livestream Schedule Done', response)
              store.commit('mediaResources/SET_YOUTUBE_LIVESTREAM', that.stream)
              that.editedItem.resourceURL = "https://youtu.be/" + that.stream.broadcastId
              that.editedItem.mediaResourcesAdditionals = []
              that.editedItem.mediaResourcesAdditionals.push({
                "key": "streamKey",
                "value": that.stream.streamKey
              })
              that.editedItem.mediaResourcesAdditionals.push({
                "key": "broadcastId",
                "value": that.stream.broadcastId
              })
              that.editedItem.mediaResourcesAdditionals.push({
                "key": "streamId",
                "value": that.stream.streamId
              })
              let dt = new Date().getTime();
              let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = (dt + Math.random()*16)%16 | 0
                dt = Math.floor(dt/16)
                return (c=='x' ? r :(r&0x3|0x8)).toString(16)
              });
              that.editedItem.mediaResourcesAdditionals.push({
                "key": "jitsiRoom",
                "value": uuid
              })
              logger.log(that.stream)
              this.saveMediaResource()
            })
            .catch(err => { 
              logger.error("liveBroadcasts.bind error", err);
              manageYoutubeErrors(this,err).finally(() => {
                this.overlay=false;
              })              
              logger.log(err)
            });
          })
          .catch(err => {
            logger.log('liveStreams.insert error 1', err)
            manageYoutubeErrors(this,err).finally(() => {
              this.overlay=false;
            })
            logger.log(err)
          })
        })
        .catch(error => {
          logger.log('liveBroadcasts.insert error 2', error)
          manageYoutubeErrors(this,error).finally(() => {
            this.overlay=false;
          })
          logger.log(error)
        })
        //
      }).catch(error => {
        manageYoutubeErrors(this,error).finally(() => {
          this.overlay=false;
        })
        logger.log('gapi.client error', error)
      })
    },
    editarEmisionEnDirecto(){
      let broadcastId = null;
      for (let i = 0; i < this.editedItem.mediaResourcesAdditionals.length; i++) {
        if (this.editedItem.mediaResourcesAdditionals[i].key == 'broadcastId') broadcastId = this.editedItem.mediaResourcesAdditionals[i].value;
      }
      let youtubeStartDate = new Date(this.editedItem.startDate).toISOString()
      let youtubeEndDate = new Date(this.editedItem.endDate).toISOString()
      let youtubeTittle = this.editedItem.name
      let youtubeDescription = this.editedItem.description

      this.$gapi.getGapiClient().then(gapi => {
        //
        // Login
        this.$gapi.login().then((response) => {
          logger.log('Youtube Login ', gapi)
          //
          gapi.client.youtube.liveBroadcasts.update({
            "part": [
              "snippet"
            ],
            "resource": {
              "id": broadcastId,
              "snippet": {
                "title": youtubeTittle,
                "description":youtubeDescription,
                "scheduledStartTime": youtubeStartDate,
                "scheduledEndTime": youtubeEndDate
              }
            }
          }).then(response => {
            logger.log('liveBroadcasts.update response', response)
            this.updateMediaResource()
          }).catch(err => {
            logger.log('liveBroadcasts.update error', err)
            manageYoutubeErrors(this,err).finally(() => {
              this.overlay=false;
            })
            logger.log(err)
          })
          //
        }).catch(error => {
          manageYoutubeErrors(this,error).finally(() => {
            this.overlay=false;
          })
          logger.log(error)
        })
        //
      }).catch(error => {
        logger.log('gapi.client error', error)
        manageYoutubeErrors(this,error).finally(() => {
          this.overlay=false;
        })
      })
    },
   eliminarEmisionEnDirecto(){
       let broadcastId = null;
       for (let i = 0; i < this.editedItem.mediaResourcesAdditionals.length; i++) {
         if (this.editedItem.mediaResourcesAdditionals[i].key == 'broadcastId') broadcastId = this.editedItem.mediaResourcesAdditionals[i].value;
       }
      this.$gapi.getGapiClient().then(gapi => {
        //
          this.$gapi.login().then((response) => {
            logger.log('Youtube Login ', gapi)
            //
            gapi.client.youtube.liveBroadcasts.delete({
              "id": broadcastId
            }).then(response => {
              logger.log('liveBroadcasts.delete response', response)
            }).catch(err => {
              logger.log('liveBroadcasts.delete error', err)
              manageYoutubeErrors(this,err).finally(() => {
                this.close()
                this.init()
              })
            })
            //
          }).catch(error => {
            logger.log({error})
            this.overlay=false
            manageYoutubeErrors(this,error).finally(() => {
              this.close()
              this.init()
            })
          })
        //
      }).catch(error => {
        logger.log('gapi.client error', error)
        manageYoutubeErrors(this,error).finally(() => {
          this.overlay=false;
        })
      })
    },
    empezarEmisionEnDirecto(item){
      //this.stream = store.getters["mediaResources/getliveStream"]
      this.editedItem = Object.assign({}, item)
      console.log('TRAZA', item)
      mediaResourcesService.getMediaResourceById(item.id).then ((response) => {
        console.log('TRAZA', response)
        if (response.data.mediaResourcesStates.idState != 1) {
          this.editedItem.mediaResourcesStates={idState: 1, stateName: "EN DIRECTO"}
          this.updateMediaResourceState()
          this.dialogEmision = true
          setTimeout(() => {
            // Api Instance
            this.$gapi.getGapiClient().then((gapi) => {
              logger.log('Youtube Api instance', gapi)
              // Login
              this.$gapi.login().then((response) => {
                logger.log('Youtube Login ', gapi)
                this.load()
              }).catch(error => {
                manageYoutubeErrors(this,error).finally(() => {
                  this.overlay=false;
                })
              })
              //
            }).catch(error => {
              manageYoutubeErrors(this,error).finally(() => {
                this.overlay=false;
              })
            })
          }, 1000);
        } else {
          this.streamingInitiated = true;
          this.dialogEmision = true;
          setTimeout(() => {
            this.load()
          }, 1000);
        }
      })
    },
    getActualDate(){
      let d = new Date() ;
      //d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
      //d.setHours(d.getHours() - 1);
      return d
    },
  },
  mounted() {
    this.init()
  },
  created() {
  },
  computed: {
    itemDialogTitle () {
      return this.editedIndex === -1 ? this.$t('lContenidosAdd') : this.$t('lContenidosEdit')
    },
    disableResourceURLField(){
      if(this.editedItem.mediaResourcesCategories){
        return ((this.editedItem.mediaResourcesCategories.categoryName === this.livestreaming) || (this.editedItem.mediaResourcesCategories.categoryName === this.enableRoom));
      }
      return false;
    }
  },
};
</script>