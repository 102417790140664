import {
  SET_YOUTUBE_LIVESTREAM
} from '@/store/actions/mediaResources'

const state = {
  liveStream: {},
}

const getters = {
  getLiveStream: state => state.LiveStream,
}

const actions = {
}

const mutations = {
    [SET_YOUTUBE_LIVESTREAM]: (state, data) => {
        state.liveStream = data
    },
}

export default {
  namespaced: true,   
  state,
  getters,
  actions,
  mutations
}
